// React
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DateComponent from '../../ui/molecules/DateComponent';
import Switch from '@mui/material/Switch';

function FilterComponent(props) {
    const filterState = { serviceList: props.filterList?.length > 0 ? props.filterList : [{ sourcedropdownText: '', sourcedropdown: '', destinationdropdown: [], destinationdates: null, isday: false, isBoolean: false, destinationBoolean: null }] };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [serviceList, setServiceList] = React.useState(filterState.serviceList);
    const [badgeContentLength, setBadgeContentLength] = React.useState(props.filterList?.length);
    const [datesData, setDatesData] = React.useState([]);
    const useStyles = makeStyles({
        paper: {
            width: '50%',
        }
    });

    const classes = useStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, {
            sourcedropdownText: '', sourcedropdown: '', destinationdropdown: [], destinationdates: null, destinationBoolean: null
        }])
    }

    const handleSourceDropdownChange = (e, index, nameObject) => {
        const selectedOption = props.sourcedropdownText.filter(option => option.id === e.id);
        const IsDate = selectedOption?.some(a => a.IsDate);
        const IsBoolean = selectedOption?.some(a => a.IsBoolean);
        const { name } = nameObject;
        const updatedList = serviceList.map((item, i) => {
            if (i === index) {
                return { ...item, [name]: (e == null ? '' : e.Text), sourcedropdown: e.id, isday: IsDate, isBoolean: IsBoolean };
            }
            return item;
        });
        setServiceList(updatedList);
    };

    const handleDestinationDropdownChange = (e, index, nameObject) => {
        const { name } = nameObject;
        const list = [...serviceList];
        list[index][name] = (e == null ? '' : e);
        setServiceList(list);
        if (e.length != 0) {
            serviceList.map((data) => {
                if (data.sourcedropdownText == '' && data.destinationdropdown.length == 0) {
                    setBadgeContentLength(0);
                }
                else {
                    setBadgeContentLength(serviceList.length);
                }
            })
        }
    }
    const handleBooleanChange = (e, index, nameObject) => {
        const { name } = nameObject;
        const list = [...serviceList];
        list[index][name] = (e == null ? '' : e);
        setServiceList(list);

    }

    const handleRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
        setBadgeContentLength(index);
        props.setPage(0);
    }

    const handleRemoveAll = () => {
        setServiceList([{ sourcedropdownText: '', destinationdropdown: [], isday: false, isBoolean: false }]);
        setBadgeContentLength(0);
        props.setPage(0);
        props.setApply([]);
    }


    function onApplyClick() {
        props.setPage(0);
        serviceList.forEach((data) => {
            if (!data.isday) {
                data.destinationdates = null;
            }
            if (!data.isBoolean) {
                data.destinationBoolean = null;
            }
            if (data.sourcedropdownText !== '' && (data.destinationdropdown.length > 0 || data.destinationdates !== null)) {

                props.setApply(serviceList);
            }
            if (data.sourcedropdownText != '' && data.destinationdates != '') {
                setBadgeContentLength(serviceList.length);
            }
            if (data.isBoolean) {
                props.setApply(serviceList);
            }
        })
    }

    useEffect(() => {
        const updatedServiceList = serviceList.map(data => {
            if (data.isday) {
                return {
                    ...data,
                    destinationdates: datesData
                };
            }
            return data;
        });
        setServiceList(updatedServiceList);
    }, [datesData]);

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button onClick={handleClick} aria-label='filter categories' variant='outlined' color='inherit' data-filter='cat' data-filtered='false' data-testid='filter-button' >
                        <span>Filters</span>
                        <br />
                        <Badge badgeContent={badgeContentLength} color='primary' data-testid='filter-badge'>
                            <FilterListIcon style={{ fontSize: 25 }} />
                        </Badge>
                    </Button>
                </Grid>
            </Grid>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={classes}
            >

                <div style={{ marginRight: '20px', marginLeft: '20px', marginTop: '20px' }} className="container">
                    <div className="row">
                        {serviceList.map((singleservice, index) => (
                            <div key={index} className="services">
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginLeft: '10px' }}>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            name="sourcedropdown"
                                            id="sourcedropdown"
                                            sx={{ marginRight: '20px' }}
                                            value={singleservice.sourcedropdownText}
                                            options={props.sourcedropdownText.sort((a, b) => a.Text.localeCompare(b.Text))
                                                .filter((option) => {
                                                    return !serviceList.some((service) => service.sourcedropdownText === option.Text);
                                                })}

                                            onChange={(e, value) => {
                                                const nameObject = { name: 'sourcedropdownText' };
                                                setServiceList([
                                                    {
                                                        sourcedropdownText: value,
                                                        destinationdropdown: [],
                                                    }
                                                ]);
                                                const object = { name: 'destinationdropdown' };
                                                handleDestinationDropdownChange([], index, object);
                                                handleSourceDropdownChange(value, index, nameObject);
                                            }}
                                            renderInput={(params) => <TextField {...params} label="Search By" inputProps={{ ...params.inputProps, 'data-testid': 'search-by-input' }} />}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        {(singleservice.isday === false && singleservice.isBoolean === false) && (
                                            <Autocomplete
                                                name="destinationdropdown"
                                                id="destinationdropdown"
                                                limitTags={2}
                                                multiple
                                                disableCloseOnSelect
                                                options={props.sourcedropdownText
                                                    .filter(data => data.Text === singleservice.sourcedropdownText)
                                                    .map(data => data.dropdown)
                                                    .flat()
                                                    .sort((a, b) => a.Text.localeCompare(b.Text))}
                                                value={singleservice.destinationdropdown}
                                                getOptionLabel={(option) => option.Text ?? ''}
                                                isOptionEqualToValue={(option, value) => option.Value === value.Value}
                                                onChange={(event, value) => {
                                                    const nameObject = { name: 'destinationdropdown' };
                                                    handleDestinationDropdownChange(value, index, nameObject);
                                                }}
                                                fullWidth
                                                renderOption={(props, option, index) => {
                                                    const key = `listItem-${index}-${option.Value}`;
                                                    return (
                                                        <li {...props} key={key}>
                                                            <Checkbox icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                checked={index.selected}
                                                            />
                                                            {option.Text}
                                                        </li>
                                                    );
                                                }}
                                                renderInput={(params) => (<TextField {...params} name="destination" label="Value" inputProps={{ ...params.inputProps, 'data-testid': 'value-input' }} />)}
                                            />
                                        )}
                                        {singleservice.isBoolean === true && (
                                            <Switch checked={singleservice.destinationBoolean} onChange={(event) => {
                                                const nameObject = { name: 'destinationBoolean' };
                                                handleBooleanChange(event.target.checked, index, nameObject);
                                            }}></Switch>
                                        )}
                                        {(singleservice.isday === true && singleservice.isBoolean === false) && (
                                            <div>
                                                <DateComponent handleDateChange={setDatesData} filterList={filterState}></DateComponent>
                                            </div>
                                        )}
                                    </Grid>
                                    {serviceList.length > 1 && (
                                        <Grid item xs={1}>
                                            <Button onClick={() => handleRemove(index)}>
                                                <RemoveCircleOutlineIcon color='secondary' />
                                            </Button>
                                            <br />
                                        </Grid>
                                    )}
                                </div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {serviceList.length - 1 === index &&
                                            <Button data-testid='add-filter-button' onClick={handleServiceAdd}>
                                                <AddIcon />
                                                Add Filters
                                            </Button>
                                        }
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                        {serviceList.length - 1 === index &&
                                            <Stack spacing={2} direction="row">
                                                <Button data-testid='reset-filter-button' onClick={() => handleRemoveAll()}>
                                                    <RestartAltIcon />
                                                    &nbsp;RESET
                                                </Button>
                                                <Button data-testid='apply-filter-button' onClick={() => onApplyClick()}>
                                                    <TaskAltIcon />
                                                    &nbsp;Apply
                                                </Button>
                                            </Stack>
                                        }
                                    </Grid>


                                </Grid>
                            </div>
                        ))}
                    </div>
                </div>
            </Popover>
        </div>
    )
}
export default FilterComponent;

FilterComponent.propTypes = {
    sourcedropdownText: PropTypes.array,
    setApply: PropTypes.func,
    setPage: PropTypes.func,
    filterList: PropTypes.func,
};
