// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { makeStyles } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Tooltip } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Services

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';

// ExcelJs
const ExcelJS = require('exceljs');

const useStyles = makeStyles(() => ({
    btnToolbar: {
        float: 'right'
    }
}));


function ExportButton(props) {
    const { title, loadExportData, filename, worksheet, snackbarShowMessage } = props;
    const [exporting , setExporting] = React.useState(false);
    const classes = useStyles();

    function createExcel() {
        runExport();
    }
    async function runExport() {
        setExporting(true);
        const rowData = await loadExportData();
        CreateWorkbook(rowData, filename, worksheet, snackbarShowMessage);
        setExporting(false);
    }
    return (
        <Stack direction='row' spacing={0} margin={0} className={classes.btnToolbar}>
            <IconButton>
                <ButtonGroup color='inherit' >
                    <Tooltip title={title}>
                        <Button variant="contained" disabled={(exporting)} color="primary" onClick={createExcel} >
                            {(exporting === true) &&
                                <CircularProgress color='secondary' size={30} style={{color:'white'}} />
                            }
                            {(exporting === false) &&
                                <FileDownloadIcon />
                            }
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </IconButton>
        </Stack>
    );
}
function CreateWorkbook(rows, filename, worksheet, snackbarShowMessage) {
    try {
        let workbook = new ExcelJS.Workbook();
        let excelsheet = workbook.addWorksheet(worksheet);
        workbook = setProperties(workbook);
        if (!rows || rows.length < 1) {
            snackbarShowMessage('No data to export. ', 'warning', 4000);
            return;
        }
        const dkeys = Object.keys(rows[0]);
        let columns = [];
        for (let i = 0; i < dkeys.length; i++) {
            columns[i] = { header: dkeys[i].replace('-', ' '), key: dkeys[i], width: 30 };
        }
        excelsheet.columns = columns;
        const reversedRows = rows.reverse();
        excelsheet.addRows(reversedRows, 'i');

        const headers = {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheet.sheet',
            'Content-Disposition': `attachment; filename=${filename}`
        }
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: headers['Content-Type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        });

    } catch (err) {
        snackbarShowMessage('The export could not be performed. ', 'error', 4000);
    }
}

ExportButton.propTypes = {
    title: PropTypes.string,
    filename: PropTypes.string,
    worksheet: PropTypes.string,
    loadExportData: PropTypes.func,
    snackbarShowMessage: PropTypes.any
};

function setProperties(workbook) {
    workbook.creator = 'WCCTV';
    workbook.lastModifiedBy = 'WCCTV';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
}

export default withSnackbar(ExportButton);
