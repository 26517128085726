// Services
import Http from '../../services/http';

export default class TransmitterService {

    static async updateTransmitter(row) {
        let data = {
            siteId: row.siteId,
            webSocketAddress: row.webSocketAddress,
            transmitterName: row.transmitterName,
            transmitterId: row.id,
            sensorAssignment: row.sensorassignmentsSelected,
            rentalNumber: row.rentalNumber,
            gsmNumber: row.gsmNumber
        };
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/transmitters/update', data).catch(error => console.error(error));

    }

    static async insertTransmitter(row) {
        let data = {
            transmitterName: row.transmitterName,
            webSocketAddress: row.webSocketAddress,
            siteId: row.siteId,
            sensorAssignment: row.sensorassignmentsSelected,
            rentalNumber: row.rentalNumber,
            gsmNumber: row.gsmNumber
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/transmitters', data).catch(error => console.error(error));

    }

    static async getAllTransmittersWebSocket() {
        const webSockets = await Http.getData(process.env.REACT_APP_NVR_API + 'api/transmitters/getalltransmitterswebsocket');
        return webSockets;

    }

}