// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';


export default function AlertDialog(props) {
    const [isOpen, setIsOpen] = React.useState(false);
    const { title , message, validation, setValidation } = props;

    async function constructor() {
        if (props.isOpen) setIsOpen(true);
    }
    useEffect(async () => {
        await constructor();
    }, []);

    const handleClose = () => {
        setValidation({ valid: false, message: validation.message, messageSeen: true });
        setIsOpen(false);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                sx={{ minWidth: '400px' }}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>
                    <Typography sx={{ p: 1 }}><strong>{title}</strong></Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {message}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

AlertDialog.propTypes = {
    title: PropTypes.string,
    validation: PropTypes.object,
    setValidation: PropTypes.func,
    isOpen: PropTypes.bool,
    message: PropTypes.string
};