import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Slider from '@mui/material/Slider';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CameraIcon from '@mui/icons-material/Camera';
import CameraSwitchIcon from '@mui/icons-material/Cameraswitch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor';
import MultiViewsService from './multiviews.service';
import PropTypes from 'prop-types';
import { ControlledMenu, useHover, MenuItem, SubMenu } from '@szhsin/react-menu';
import CamerasDropdownComponent from './cameras.component';
import '@szhsin/react-menu/dist/index.css';
import './streamPlayer.css';

const useStyles = makeStyles(() => ({
    loadingWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    loadingSpinner: {
        position: 'absolute',
        top: '50%',
        color: 'error'
    },
    videoWrapper: {
        margin: '0',
        padding: '0',
        position: 'relative',
        height: '100%'
    },
    textOverlay: {
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#ffffff',
        padding: '5px',
        fontSize: '14px',
    },
    textOverlayFullScreen: {
        fontSize: '30px'
    },
    controlOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none',
        lineHeight: '1',
    },
    controlButton: {
        color: 'black !important',
        cursor: 'pointer !important',
        pointerEvents: 'all !important',
        backgroundColor: 'rgba(255, 255, 255, 0.25) !important',
        padding: '2px 6px !important',
        borderRadius: '10px !important'
    },
    controlButtonFullScreen: {
        fontSize: '3em !important',
        color: 'black !important',
        cursor: 'pointer !important',
        pointerEvents: 'all !important',
        backgroundColor: 'rgba(255, 255, 255, 0.25) !important',
        padding: '2px 6px !important',
        borderRadius: '10px !important'
    },
    menuButtonFullScreen: {
        fontSize: '2em !important',
        color: 'black !important',
        cursor: 'pointer !important',
        pointerEvents: 'all !important',
        backgroundColor: 'rgba(255, 255, 255, 0.25) !important',
        padding: '8px 12px !important',
        borderRadius: '10px !important'
    },
    leftControl: {
        position: 'absolute',
        left: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    rightControl: {
        position: 'absolute',
        right: '2px',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    topControl: {
        position: 'absolute',
        top: '2px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    bottomControl: {
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    stepControl: {
        position: 'absolute',
        bottom: '5px',
        left: '81%',
        transform: 'translateX(-75%)',
        width: '20%',
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        padding: '0px 10px 0px 10px',
        borderRadius: '10px',
        cursor: 'pointer',
        pointerEvents: 'all',
    },
    stepControlFullScreen: {
        padding: '0 20px 0px 20px'
    },
    bottomRightControls: {
        position: 'absolute',
        bottom: '0',
        right: '8px'
    },
    topRightControls: {
        position: 'absolute',
        top: '15px',
        right: '8px'
    },
    zoomControl: {
        color: 'black',
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        padding: '2px 6px',
        borderRadius: '10px',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'black',
        pointerEvents: 'all !important',
        zIndex: '1000',
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
        fontWeight: '400',
        fontSize: '1rem'
    },
    presetsMenu: {
        pointerEvents: 'all !important',
        maxHeight: '250px !important',
        overflowY: 'auto !important',
        zIndex: '999 !important'
    },
    subMenuIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        paddingLeft: '7px'
    },
    subMenuLink: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'black',
        pointerEvents: 'all !important',
        zIndex: '1000',
        fontFamily: 'Roboto, Helvetica,Arial,sans-serif',
        fontWeight: '400',
        fontSize: '1rem',
        marginLeft: '9px',
        lineHeight: '1.5',
        verticalAlign: 'top'
    },
    subMenuText: {
        padding: '5px'
    },
    camerasDropdownWrapper: {
        margin: 0,
        padding: 0,
        height: 'auto',
        overflow: 'hidden'
    },
    noAccessLabel: {
        cursor: 'pointer',
        top: '45%',
        position: 'absolute',
        textAlign: 'center',
        width: '100%'
    },
}));

const steps = [
    {
        value: 10
    },
    {
        value: 20
    },
    {
        value: 30
    },
    {
        value: 40
    },
    {
        value: 50
    },
    {
        value: 60
    },
    {
        value: 70
    },
    {
        value: 80
    },
    {
        value: 90
    },
    {
        value: 100
    },
];

const stepByDefault = 20;

StreamPlayerComponent.propTypes = {
    transmitterId: PropTypes.number,
    transmitterCameraId: PropTypes.number,
    cameraNumber: PropTypes.number,
    transmitterName: PropTypes.string,
    displayName: PropTypes.string,
    transmitterReverseProxy: PropTypes.string,
    multiviewId: PropTypes.number,
    multiviewsPage: PropTypes.number,
    multiviewsRowsPerPage: PropTypes.number,
    multiViewItems: PropTypes.array,
    hasNoCameraSelected: PropTypes.bool,
    row: PropTypes.number,
    column: PropTypes.number,
    updateMuliviewsCallback: PropTypes.func
}

StreamPlayerComponent.defaultProps = {
    hasNoCameraSelected: false
}

export function StreamPlayerComponent(props) {
    const classes = useStyles();
    const [showCamerasDropdown, setShowCamerasDropdown] = useState(false);
    const [cameraData, setCameraData] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [streaming, setStreaming] = useState(false);
    const [isPtzCamera, setIsPtzCamera] = useState(false);
    const [attemptingReconnect, setAttemptingReconnect] = useState(false);
    const [streamIdentifier, setStreamIdentifier] = useState('');
    const [stepBy, setStepBy] = useState(stepByDefault);
    const playingStream = useRef(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [open, setOpen] = useState(false);
    const menuContainerRef = useRef(null);
    const [presets, setPresets] = useState([]);
    const menuButtonRef = useRef(null);
    const notStreamingMenuButtonRef = useRef(null);
    const noAccessMenuButtonRef = useRef(null);
    const { anchorProps, hoverProps } = useHover(open, setOpen);
    const [noAccess, setNoAccess] = useState(false);
    const [changingCamera, setChangingCamera] = useState(false);

    let webRTCAdaptor = useRef(null);
    let reconnectionCounter = 100;

    async function changeCamera() {
        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), props.multiViewItems);

        setCameraData(cameraData);
        setShowCamerasDropdown(true);

    }

    async function getCameraData(camerasData, gridItems) {
        let CameraData = [];
        if (camerasData.items) {
            if (gridItems) {
                for (let j = 0; j < camerasData.items.length; j++) {
                    let found = false;
                    for (let i = 0; i < gridItems.length; i++) {
                        if (gridItems[i].TransmitterCameraId === camerasData.items[j].Id) {
                            found = true;
                        }
                    }
                    if (!found) {
                        CameraData[CameraData.length] = camerasData.items[j];
                    }
                }
            } else {
                CameraData = camerasData.items;
            }
        }

        let response = { items: CameraData };
        return response;
    }

    async function handleCameraSelection(newTransmitterCameraId) {
        const transmitterId = props.transmitterId ?? -1;
        const transmitterCameraId = props.transmitterCameraId ?? -1;

        setChangingCamera(true);
        setStreaming(false);
        try {
            await MultiViewsService.changeCamera(props.multiviewId, props.column, props.row, transmitterId, transmitterCameraId, newTransmitterCameraId);
            props.updateMuliviewsCallback(props.multiviewId);
        } catch (error) {

            setStreaming(false);
            setChangingCamera(false);
            throw (error);
        }

    }

    function handleCameraDropdownClose() {
        setShowCamerasDropdown(false);
    }

    const captureFrame = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const image = canvas.toDataURL('image/png');

        createTempDownloadLinkForImageCaptureDownload(image);
    };

    const createTempDownloadLinkForImageCaptureDownload = (image) => {
        const link = document.createElement('a');
        link.href = image;
        link.download = `${props.displayName} Capture.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handlePlay = (streamId) => {
        setStreamIdentifier(streamId);

        playingStream.current = streamId;
        webRTCAdaptor?.current.play(streamId);
    };

    const containerRef = useRef(null);
    const handleFullScreenChange = () => {
        setIsFullscreen(document.fullscreenElement !== null);
    };

    const handleFullScreen = () => {
        if (!isFullscreen) {
            if (containerRef.current) {
                if (containerRef.current.requestFullscreen) {
                    containerRef.current.requestFullscreen();
                } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
                    containerRef.current.mozRequestFullScreen();
                } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    containerRef.current.webkitRequestFullscreen();
                } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
                    containerRef.current.msRequestFullscreen();
                }
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    async function fetchStreamDetails() {
        if (attemptingReconnect) return;

        let streamDetails;
        try {
            if (props.transmitterId != undefined && props.cameraNumber != undefined) {
                streamDetails = await MultiViewsService.getStreamDetails(props.transmitterId, props.cameraNumber);
            }
        }
        catch (error) {
            if (error.status == 401 | error.status == 403) {
                setAttemptingReconnect(false);
                setNoAccess(true);
            }
        }

        if (streamDetails != undefined) {
            playStream(streamDetails.WssUrl, streamDetails.StreamId);
            setIsPtzCamera(streamDetails.IsPtzCamera);
            setAttemptingReconnect(false);
        }
    }

    function playStream(wssUrl, streamId) {
        if (!webRTCAdaptor.current) {
            webRTCAdaptor.current = new WebRTCAdaptor({
                websocket_url: wssUrl,
                mediaConstraints: {
                    video: false,
                    audio: false,
                },
                peerconnection_config: {
                    iceServers: [{ urls: 'stun:stun1.l.google.com:19302' }],
                },
                sdp_constraints: {
                    OfferToReceiveAudio: true,
                    OfferToReceiveVideo: true,
                },
                remoteVideoId: `video_${props.transmitterId}_${props.cameraNumber}`,
                callback: (info) => {
                    if (info === 'initialized') {
                        handlePlay(streamId);
                    }
                    if (info === 'play_started') {
                        setStreaming(true);
                        reconnectionCounter = 100;
                    }
                    if (info === 'reconnection_attempt_for_player') {
                        if (reconnectionCounter === 0) {
                            setAttemptingReconnect(true);
                            stopStream();
                            fetchStreamDetails();

                            reconnectionCounter = 100;
                        }

                        reconnectionCounter--;
                    }
                },
                callbackError: function (error) {
                    if (error === 'no_stream_exist') {
                        setStreaming(false);
                    }
                }
            });
        }
    }

    function stopStream() {
        if (webRTCAdaptor.current && streamIdentifier?.length > 0) {
            webRTCAdaptor.current.stop(streamIdentifier);
            webRTCAdaptor.current = null;
            webRTCAdaptor = null;
            setStreaming(false);
        }
    }

    const stepChange = (_event, newValue) => {
        setStepBy(newValue);
    };

    async function tiltDown(transmitterId, cameraId) {
        await MultiViewsService.tiltDown(transmitterId, cameraId, stepBy / 100);
    }

    async function tiltUp(transmitterId, cameraId) {
        await MultiViewsService.tiltUp(transmitterId, cameraId, stepBy / 100);
    }

    async function panLeft(transmitterId, cameraId) {
        await MultiViewsService.panLeft(transmitterId, cameraId, stepBy / 100);
    }

    async function panRight(transmitterId, cameraId) {
        await MultiViewsService.panRight(transmitterId, cameraId, stepBy / 100);
    }

    async function zoomIn(transmitterId, cameraId) {
        await MultiViewsService.zoomIn(transmitterId, cameraId, stepBy / 100);
    }

    async function zoomOut(transmitterId, cameraId) {
        await MultiViewsService.zoomOut(transmitterId, cameraId, stepBy / 100);
    }

    async function getPresets(transmitterId, cameraId) {
        const result = await MultiViewsService.getPresets(transmitterId, cameraId);
        return result;
    }

    async function gotoPreset(transmitterId, cameraId, presetToken) {
        const result = await MultiViewsService.gotoPreset(transmitterId, cameraId, presetToken);
        return result;
    }

    useEffect(() => {
        const fetchStream = async () => {
            await fetchStreamDetails();
        };

        fetchStream();

        return () => {
            stopStream();
        };
    }, [props.transmitterId, props.cameraNumber, props.transmitterName, props.displayName, streamIdentifier]);

    useEffect(() => {
        const fetchPresets = async () => {
            if (props.transmitterId != undefined && props.cameraNumber != undefined) {
                let presetData = await getPresets(props.transmitterId, props.cameraNumber);
                setPresets(presetData);
            }
        };

        fetchPresets();
    }, [props.transmitterId, props.cameraNumber]);



    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullScreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);
    return (
        <div>

            {changingCamera && (
                <div className={classes.loadingWrapper}>
                    <CircularProgress className={classes.loadingSpinner} color='secondary' />
                </div>
            )}

            {(!streaming && !noAccess && !props.hasNoCameraSelected) &&
                <div>
                    <div className={classes.loadingWrapper}>
                        <CircularProgress hidden={streaming | changingCamera} className={classes.loadingSpinner} color='secondary' />
                        <div className={`${classes.textOverlay} ${isFullscreen ? classes.textOverlayFullScreen : ''}`}>
                            {props.displayName}
                        </div>
                    </div>

                    <div className={classes.controlOverlay}>
                        <div className={classes.topRightControls}>
                            <div ref={menuContainerRef}>
                                <div
                                    ref={notStreamingMenuButtonRef}
                                    {...anchorProps}
                                >
                                    <MoreVertIcon className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.menuButtonFullScreen : ''}`} />
                                </div>

                                <ControlledMenu
                                    {...hoverProps}
                                    state={open ? 'open' : 'closed'}
                                    onClose={() => setOpen(false)}
                                    anchorRef={notStreamingMenuButtonRef}
                                    overflow={'auto'}
                                    setDownOverflow
                                >
                                    <MenuItem>
                                        <Link
                                            onClick={() => { changeCamera() }}
                                            className={classes.link}
                                        >
                                            <IconButton>
                                                <CameraSwitchIcon />
                                            </IconButton>
                                            <span className={classes.link}>Change Camera &nbsp;</span>
                                        </Link>
                                        <CamerasDropdownComponent
                                            visible={showCamerasDropdown}
                                            camerasData={cameraData}
                                            handleCameraSelection={handleCameraSelection}
                                            isOpen={showCamerasDropdown}
                                            handleClose={handleCameraDropdownClose}
                                        />
                                    </MenuItem>
                                </ControlledMenu>
                            </div>
                        </div>
                    </div>

                </div>
            }

            <div hidden={!streaming | noAccess} ref={containerRef} className={classes.videoWrapper}  >
                <video
                    ref={videoRef}
                    onClick={handleFullScreen}
                    id={`video_${props.transmitterId}_${props.cameraNumber}`}
                    autoPlay
                    muted='muted'
                    style={{
                        width: '100%',
                        aspectRatio: '16/9',
                        display: 'block'
                    }}>
                </video>
                <div hidden={!streaming} className={`${classes.textOverlay} ${isFullscreen ? classes.textOverlayFullScreen : ''}`}>
                    {props.displayName}
                </div>

                {isPtzCamera && (
                    <div className={classes.controlOverlay}>
                        <div className={classes.leftControl}>
                            <ArrowBackIcon onClick={() => panLeft(props.transmitterId, props.cameraNumber)}
                                className={`${classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`} />
                        </div>

                        <div className={classes.rightControl}>
                            <ArrowForwardIcon onClick={() => panRight(props.transmitterId, props.cameraNumber)}
                                className={`${classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`} />
                        </div>

                        <div className={classes.topControl}>
                            <ArrowUpwardIcon onClick={() => tiltUp(props.transmitterId, props.cameraNumber)}
                                className={`${classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`} />
                        </div>

                        <div className={classes.bottomControl}>
                            <ArrowDownwardIcon onClick={() => tiltDown(props.transmitterId, props.cameraNumber)}
                                className={`${classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`} />
                        </div>

                        <div className={`${classes.stepControl} ${isFullscreen ? classes.stepControlFullScreen : ''}`}>
                            <Slider
                                defaultValue={stepByDefault}
                                step={null}
                                marks={steps}
                                onChange={stepChange}
                                color='black'
                                size={isFullscreen ? 'medium' : 'small'}
                                min={25}
                            />
                        </div>

                        <div className={classes.bottomRightControls}>
                            <div>
                                <ZoomInIcon
                                    className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`}
                                    onClick={() => zoomIn(props.transmitterId, props.cameraNumber)} />
                            </div>
                            <div>
                                <ZoomOutIcon
                                    className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`}
                                    onClick={() => zoomOut(props.transmitterId, props.cameraNumber)} />
                            </div>
                        </div>
                    </div>
                )}

                <div className={classes.controlOverlay}>
                    <div className={classes.topRightControls}>
                        <div>
                            <CameraIcon
                                className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.controlButtonFullScreen : ''}`}
                                onClick={captureFrame} />

                            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                        </div>
                        <div ref={menuContainerRef}>

                            <div
                                ref={menuButtonRef}
                                {...anchorProps}
                            >
                                <MoreVertIcon className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.menuButtonFullScreen : ''}`} />
                            </div>

                            <ControlledMenu
                                {...hoverProps}
                                state={open ? 'open' : 'closed'}
                                onClose={() => setOpen(false)}
                                anchorRef={menuButtonRef}
                                overflow={'auto'}
                                setDownOverflow
                            >
                                <MenuItem>
                                    <Link
                                        className={classes.link}
                                        to={{ pathname: 'liveview', state: { backLabel: 'Multiview', backTo: '/multiview', transmitterName: props.transmitterName, transmitterReverseProxy: props.transmitterReverseProxy, returnState: { selectedMultiView: props.multiviewId, multiviewsPage: props.multiviewsPage, multiviewsRowsPerPage: props.multiviewsRowsPerPage } } }}
                                    >
                                        <IconButton>
                                            <PlayCircleOutlineIcon />
                                        </IconButton>
                                        <span className={classes.link}>Connect &nbsp;</span>
                                    </Link>
                                </MenuItem>

                                {isPtzCamera && (
                                    <SubMenu
                                        label={<span><LocalParkingIcon className={classes.subMenuIcon} /><label className={classes.subMenuLink}>Presets</label></span>}
                                        className={`${classes.presetsMenu} ${classes.link}`}
                                    >
                                        {presets && (
                                            presets.length > 0 ? (
                                                presets.map((item) => (
                                                    <MenuItem
                                                        key={item.token}
                                                        className={classes.link}
                                                        onClick={() => { gotoPreset(props.transmitterId, props.cameraNumber, item.token); }}
                                                    >
                                                        <span className={classes.subMenuText}>{item.Name}</span>
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem className={classes.link}>
                                                    None
                                                </MenuItem>
                                            )
                                        )}
                                    </SubMenu>
                                )}

                                {!isFullscreen && (
                                    <MenuItem>
                                        <Link
                                            onClick={() => { changeCamera() }}
                                            className={classes.link}
                                        >
                                            <IconButton>
                                                <CameraSwitchIcon />
                                            </IconButton>
                                            <span className={classes.link}>Change Camera &nbsp;</span>
                                        </Link>
                                        <CamerasDropdownComponent
                                            visible={showCamerasDropdown}
                                            camerasData={cameraData}
                                            handleCameraSelection={handleCameraSelection}
                                            isOpen={showCamerasDropdown}
                                            handleClose={handleCameraDropdownClose}
                                        />
                                    </MenuItem>
                                )}
                            </ControlledMenu>

                        </div>
                    </div>
                </div>
            </div>

            <div hidden={(!noAccess & !props.hasNoCameraSelected) | changingCamera}>
                <Typography className={classes.noAccessLabel} hidden={!noAccess}>
                    Camera Access Restricted
                </Typography>

                <Typography className={classes.noAccessLabel} hidden={!props.hasNoCameraSelected}>
                    No Camera Selected !
                </Typography>

                <div className={classes.controlOverlay}>
                    <div className={classes.topRightControls}>
                        <div ref={menuContainerRef}>
                            <div
                                ref={noAccessMenuButtonRef}
                                {...anchorProps}
                            >
                                <MoreVertIcon className={`${classes.zoomControl, classes.controlButton} ${isFullscreen ? classes.menuButtonFullScreen : ''}`} />
                            </div>

                            <ControlledMenu
                                {...hoverProps}
                                state={open ? 'open' : 'closed'}
                                onClose={() => setOpen(false)}
                                anchorRef={noAccessMenuButtonRef}
                                overflow={'auto'}
                                setDownOverflow
                            >
                                <MenuItem>
                                    <Link
                                        onClick={() => { changeCamera() }}
                                        className={classes.link}
                                    >
                                        <IconButton>
                                            <CameraSwitchIcon />
                                        </IconButton>
                                        <span className={classes.link}>Change Camera &nbsp;</span>
                                    </Link>
                                </MenuItem>
                            </ControlledMenu>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}
