// React
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// Mui
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getUtcStartOfDate, getUtcEndOfDate, getLocalDateFormat, getUtcStartOfDayByDayType, getUtcEndOfDayByDayType, getMinDate } from '../../utils/dateFormatter';
import { dates } from '../../utils/dates.js';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles(() => ({
    datePicker: {
        '& .MuiTextField-root': {
            width: 200,
        }
    }
}));
function DateComponent(props) {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [selectedData, setSelectedData] = React.useState({ day: '', startDay: null, endDay: null, selectedStartDate: dayjs(), selectedEndDate: dayjs() });

    const handleChange = (e) => {
        setSelectedOption(e.target.value);
        let startDate;
        let endDate;
        const selectedDate = Object.keys(dates).find(key => dates[key].value === e.target.value);
        if (e.target.value == dates.today.value) {
            startDate = getUtcStartOfDayByDayType(dates.today.displayName);
            endDate = getUtcEndOfDayByDayType(dates.today.displayName);
        }
        if (e.target.value == dates.yesterday.value) {
            startDate = getUtcStartOfDayByDayType(dates.yesterday.displayName);
            endDate = getUtcEndOfDayByDayType(dates.yesterday.displayName);
        }
        if (e.target.value == dates.last7days.value) {
            startDate = getUtcStartOfDayByDayType(dates.last7days.displayName);
            endDate = getUtcEndOfDayByDayType(dates.last7days.displayName);
        }
        if (e.target.value == dates.last14days.value) {
            startDate = getUtcStartOfDayByDayType(dates.last14days.displayName);
            endDate = getUtcEndOfDayByDayType(dates.last14days.displayName);
        }
        if (e.target.value == dates.isafter.value) {
            startDate = getUtcStartOfDayByDayType(dates.today.displayName);
            endDate = getUtcEndOfDayByDayType(dates.today.displayName)
        }
        if (e.target.value == dates.isbefore.value) {
            startDate = getMinDate();
            endDate = getUtcEndOfDayByDayType(dates.today.displayName);
        }
        if (e.target.value == dates.isbetween.value) {
            startDate = getUtcStartOfDayByDayType(dates.today.displayName);
            endDate = getUtcEndOfDayByDayType(dates.today.displayName);
        }

        setSelectedData({ day: selectedDate, startDay: startDate, endDay: endDate });
    };

    useEffect(() => {
        props.handleDateChange(selectedData);
    }, [selectedData])

    const filterData = props.filterList.serviceList.map(a => a.destinationdates);

    useEffect(() => {
        const filterData = props.filterList.serviceList.map(a => a.destinationdates);

        if (filterData.length > 0) {
            const validFilterData = filterData.filter(a => a !== null && typeof a === 'object');

            if (validFilterData.length > 0) {
                const day = filterData
                    .filter(item => item && item.day !== undefined && item.day !== null && item.day !== '')
                    .map(item => item.day || item.startDay || null);

                const startdate = filterData.map(a => a ? a.startDay : null);
                const nonNullStartDates = startdate.filter(date => date !== null);

                const enddate = filterData.map(a => a ? a.endDay : null);
                const nonNullEndDates = enddate.filter(date => date !== null);

                const selectedStartDate = filterData.map(a => a ? a.selectedStartDate : null);
                const nonNullselectedStartDate = selectedStartDate.filter(date => date !== null);

                const selectedEndDate = filterData.map(a => a ? a.selectedEndDate : null);
                const nonNullselectedEndDate = selectedEndDate.filter(date => date !== null);

                const selectedDay = day.find(value => value !== null);
                const selectedOption = dates[selectedDay];
                setSelectedOption(selectedOption?.value);

                setSelectedData({
                    day: selectedDay,
                    startDay: nonNullStartDates[0],
                    endDay: nonNullEndDates[0],
                    selectedStartDate: nonNullselectedStartDate[0],
                    selectedEndDate: nonNullselectedEndDate[0]
                });
            }
        }
    }, [filterData.length > 0])

    return (

        <div className={classes.datePicker}>
            <Stack direction="row" spacing={2} alignItems="baseline">
                <FormControl sx={{ width: '40%' }}>
                    <InputLabel id="date-label" >Date</InputLabel>
                    <Select
                        labelId="date-label"
                        label='Date'
                        value={selectedOption ? selectedOption : ''}
                        onChange={handleChange}
                    >
                        {Object.keys(dates).map((key) => (
                            <MenuItem key={key} value={dates[key].value}>
                                {dates[key].displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {(selectedOption !== null && (selectedOption == dates.isbefore.value || selectedOption == dates.isafter.value || selectedOption == dates.isbetween.value)) &&

                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker format={getLocalDateFormat()} label="From" value={selectedData.selectedStartDate ? dayjs(selectedData.selectedStartDate) : dayjs()} onChange={(date) => {
                            const startOfDate = getUtcStartOfDate(date.toDate());
                            const endOfDate = getUtcEndOfDate(date.toDate());
                            const selectedDay = Object.keys(dates).find(key => dates[key].value === selectedOption);

                            if (selectedOption === dates.isbefore.value) {
                                setSelectedData({ day: selectedDay, startDay: getMinDate(), endDay: endOfDate, selectedStartDate: date, selectedEndDate: '' });
                            }
                            if (selectedOption === dates.isafter.value) {
                                setSelectedData({ day: selectedDay, startDay: startOfDate, endDay: getUtcEndOfDayByDayType(dates.today.displayName), selectedStartDate: date, selectedEndDate: '' });
                            }
                            if (selectedOption === dates.isbetween.value) {
                                setStartDate(startOfDate);
                                setSelectedData({ day: selectedDay, startDay: startOfDate, endDay: endDate ?? selectedData.endDay, selectedStartDate: date, selectedEndDate: selectedData.selectedEndDate || '', });
                            }
                        }} />
                    </LocalizationProvider>
                }
                {selectedOption === dates.isbetween.value &&
                    (<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker format={getLocalDateFormat()} value={selectedData.selectedEndDate ? dayjs(selectedData.selectedEndDate) : dayjs()} label="To" onChange={(date) => {
                            const endOfDate = getUtcEndOfDate(date.toDate());
                            setEndDate(endOfDate);
                            const selectedDay = Object.keys(dates).find(key => dates[key].value === selectedOption);
                            setSelectedData({ day: selectedDay, startDay: startDate ?? selectedData.startDay, endDay: endOfDate, selectedStartDate: selectedData.selectedStartDate, selectedEndDate: date });
                        }} />
                    </LocalizationProvider>)
                }
            </Stack>
        </div>
    )
}
export default DateComponent;

DateComponent.propTypes = {
    handleDateChange: PropTypes.func,
    filterList: PropTypes.func,
};