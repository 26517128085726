// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';

// Services
import SensorsService from '../sensor/sensors.service';
import SensorService from '../sensor/sensor.service';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import SensorRecordComponent from './sensor.component';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import { stableData, getComparator } from '../../helpers/Sorting';
import AreYouSure from '../../ui/molecules/AreYouSure';

const headCells = [
    { id: 'sensorName', numeric: false, disablePadding: false, label: 'Sensor Name' },
    { id: 'sensorReference', numeric: false, disablePadding: false, label: 'Reference' },
    { id: 'transmitterName', numeric: false, disablePadding: false, label: 'Transmitter Name' },
    { id: 'sensorCount', numeric: false, disablePadding: false, label: 'Active Sensors' }
];

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black',
        boxShadow: 0
    },
    table: {
        minWidth: 750,
    }
}));

function PopupCell(props) {
    const { row, labelId } = props;
    const [sensorTypes, setSensorTypes] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const classes = makeStyles(() => ({ typography: { padding: '0px 10px' } }))();

    const handlePopoverClose = () => { setAnchorEl(null) };
    const handlePopoverOpen = async (event) => {
        setLoading(true);
        setAnchorEl(event.currentTarget);
        let sensors = await SensorService.getSensorTypes(row.Id);
        setSensorTypes(sensors.selectedSensorTypes);
        if (sensors.selectedSensorTypes.length <= 0)
            setAnchorEl(null);
        setLoading(false);
    };

    return (
        <TableCell component='th' id={labelId} scope='row' align='left' style={{ paddingLeft: '43px' }}>
            <Fab color='primary' aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} size='small' data-testid='popover-button'>
                {row.sensorCount}
            </Fab>
            <Popover id='mouse-over-popover' open={open} anchorEl={anchorEl}
                sx={{ pointerEvents: 'none' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {
                    loading ? <CircularProgress size='1.3rem' data-testid='sensor-loading-indicator'/> :
                        sensorTypes.map((row, index) => { return <Typography key={index} className={classes.typography}>{row.Text}</Typography>; })
                }
            </Popover>
        </TableCell>
    );
}

PopupCell.propTypes = {
    row: PropTypes.object,
    labelId: PropTypes.string
};


/**A table component with a toolbar and edit functions */
function SensorComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ sensors: [] });
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('sensorName');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = React.useState('');
    const buttonIcon = <Tooltip title={'Add a sensor'}><AddIcon data-testid="sensor-add-button"></AddIcon></Tooltip>
    const [currentRowToDelete, setCurrentRowToDelete] = React.useState();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isForceReload, setIsForceReload] = React.useState(false);

    useEffect(() => {
        populateSensorData();
    }, [isForceReload, page, rowsPerPage, order, orderBy]);



    async function populateSensorData() {
        setLoading(true);
        let rowData = {};
        /** an array of rows */
        try {
            rowData = await SensorsService.getSensors(page + 1, rowsPerPage, order, orderBy, search);

            rowData.items = rowData.items.map(obj => {
                const sensors = Object.entries(obj)
                    .filter(([key]) => key.startsWith('Collect'))
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        delete obj[key];
                        return acc;
                    }, {});

                const sensorCount = Object.values(sensors)
                    .filter(value => value === true).length;

                return { ...obj, sensorCount: sensorCount };
            });

        } catch (err) {
            props.snackbarShowMessage('The sensors could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ sensors: [] });
            setLoading(false);
            return;
        }
        if (rowData === null || rowData === undefined) {
            props.snackbarShowMessage('The sensors are not available. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ sensors: [] });
            setLoading(false);
            return;
        }

        setState({ sensors: rowData.items });
        setTotalPageCount((rowData.pageInfo === null || rowData.pageInfo === undefined) ? 1 : rowData.pageInfo.pageCount);
        setLoading(false);
    }

    /**
     * Handles the sort property
     * @param {any} event
     * @param {any} property
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    /**
     * Deletes the sensors
     * @param {any} row
     */
    async function handleDeleteSensor(row) {
        setLoading(true);
        let deleted = await SensorsService.deleteSensors((row === undefined) ? currentRowToDelete : row);
        if (deleted === 'ok') props.snackbarShowMessage('The sensor has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage('The sensor could not be deleted. ', 'error', 4000);
        populateSensorData();
        setLoading(false);
        setOpenDialog(false);
    }


    async function deleteSensor(event, row) {
        if (row.TransmitterName != null) {
            setCurrentRowToDelete(row);
            setOpenDialog(true);
        }
        else {
            await handleDeleteSensor(row)
        }
    }
    const handleClose = () => {
        setOpenDialog(false);
    };

    /**
     * Changes the page
     * @param {any} e
     * @param {any} newPage
     */
    async function handleChangePage(e, newPage) {
        setLoading(true);
        setPage(newPage);
    }

    /**
     * Sets the amount of rows per page
     * @param {any} event
     */
    async function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const modal = (
        <SensorRecordComponent btnFunction={populateSensorData} buttonIcon={buttonIcon} />
    );
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.sensors.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <EnhancedTableToolbar
                    hasAddButton={true}
                    modal={modal}
                    searchPlaceholder={'Search sensors...'}
                    setSearch={handleSearch}
                />
                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.sensors.length < 1 ? <TableRow><TableCell colSpan='2'>No sensors were found. </TableCell></TableRow> : stableData(state.sensors, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btnDelete = <Tooltip title={'Remove this sensor'}><div><IconButton onClick={(event) => deleteSensor(event, row)} variant='contained' color='primary' data-testid="sensor-delete-button"><DeleteIcon /></IconButton></div></Tooltip>
                                    const btnEdit = <SensorRecordComponent item={row} btnFunction={populateSensorData} buttonIcon={<Tooltip title={'Edit this sensor'} data-testid="sensor-edit-button"><div><EditIcon></EditIcon></div></Tooltip>} />

                                    return (

                                        <TableRow hover role='checkbox' tabIndex={-1} key={labelId}>

                                            <TableCell component='th' id={labelId} scope='row' align='left' data-testid='sensor-name'>  {row.SensorName}       </TableCell>
                                            <TableCell component='th' id={labelId} scope='row' align='left'>  {row.SensorReference}  </TableCell>
                                            <TableCell component='th' id={labelId} scope='row' align='left'>  {row.TransmitterName}  </TableCell>
                                            <PopupCell key={row.Id} row={row} labelId={`${labelId}-popup`} />
                                            <TableCell align='right'>
                                                <ButtonGroup>
                                                    {btnDelete}
                                                    {btnEdit}
                                                </ButtonGroup>
                                            </TableCell>

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0, border: 'none' }}>
                                    <TableCell style={{ border: 'none' }} colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={totalPageCount * rowsPerPage}
                    slotProps={{
                        actions: {
                            nextButton: {
                                'data-testid': 'sensor-next-button',
                            },
                        },
                    }}
                    disabled={false}
                    rowsPerPage={rowsPerPage}
                    page={(page)}
                    onPageChange={(event, params) => {
                        handleChangePage(event, params);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {openDialog ?
                <AreYouSure isOpenDialog={openDialog} closeDialog={handleClose} titleText='Delete Sensor' contentText={<span>Are you sure you want to delete sensor {currentRowToDelete.SensorName} assigned to transmitter {currentRowToDelete.TransmitterName}?</span>} onYesClick={handleDeleteSensor} > </AreYouSure>
                : null}
        </div>
    );
}
export default withSnackbar(SensorComponent);


SensorComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};