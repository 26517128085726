// React
import React, { useEffect } from 'react'
// Services
import { signinSilentCallback } from '../services/userService'

function SilentOidc() {
    useEffect(() => {
        async function silentAsync() {
            await signinSilentCallback()
        }
        silentAsync()
    }, [])

    return (
        <div>
            Redirecting...
        </div>
    )
}

export default SilentOidc