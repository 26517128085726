// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';

/**
 * A table header containing relevant buttons such as filters
 * @param {any} props
 */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells, isRowEditable } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            data-testid="name-label"
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {(isRowEditable) &&
                    <TableCell>

                    </TableCell>
                }
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
    headCells: PropTypes.array,
    isRowEditable: PropTypes.bool.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};