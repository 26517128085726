import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FilterComponent from '../../ui/molecules/FilterComponent';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import HeartbeatImageService from '../operations/HeartbeatImageService';
import InfiniteScroll from 'react-infinite-scroll-component';
import SitesComponent from '../operations/sites.component';
import FilteredSitesComponent from '../operations/filteredsites.component';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    pageContainer: {
        backgroundColor: 'rgb(250,250,250)',
        minHeight: '100vh',
    },
    tableGrid: {
        marginTop: '40px',
        overflow: 'hidden !important',
    },
    stickyGrid: {
        position: 'sticky',
        top: 50,
        zIndex: 1000,
        backgroundColor: 'rgb(250,250,250)',
    },
    red: {
        color: '#E63027',
    },
    orange: {
        color: '#ed6c02',
    },
    green: {
        color: '#00b359',
    },
    black: {
        color: '#000',
    },
    card: {
        borderWidth: '2px !important',
        margin: '5px !important',
        boxShadow:
            '-3529px 2px 1px -1px rgba(0,0,0,0.2),0px 3px 2px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)',
    },
    infiniteScroll: {
        overflow: 'hidden !important',
        marginBottom: '100px'
    }
}));

function OperationsComponent(props) {
    const [rows, setRows] = useState([]);
    const [showingDefaultDashboard, setShowingDefaultDashboard] = useState(true);
    const [transmitterErrors, setTransmitterErrors] = useState(0);
    const [cameraErrors, setCameraErrors] = useState(0);
    const [updateFailures, setUpdateFailures] = useState(0);
    const [open, setOpen] = useState({});
    const classes = useStyles();

    let reportState = { order: 'asc', orderBy: 'SiteName', page: 0, rowsPerPage: 10, applyData: [] };
    const [page, setPage] = React.useState(reportState.page);
    const [applyData, setApplyData] = React.useState(reportState.applyData);
    const [loading, setLoading] = React.useState(false);

    const handleToggleRow = (DisplayId) => {
        setOpen((prevOpen) => ({
            ...prevOpen,
            [DisplayId]: !prevOpen[DisplayId],
        }));
    };

    const filterOptions = [
        { id: 'TransmitterError', IsBoolean: true, label: 'Transmitter Error', Text: 'Transmitter Error', Value: 1 },
        { id: 'CameraError', Text: 'Camera Error', label: 'Camera Error', IsBoolean: true, Value: 2 },
        { id: 'UpdateFailure', IsBoolean: true, label: 'Update Failure', Text: 'Update Failure', Value: 3 },
        { id: 'Date', IsDate: true, Value: 4, Text: 'Date', filterBy: true, label: 'Date' },
        { id: 'TransmitterName', numeric: false, disablePadding: false, IsDate: false, Value: 5, filterBy: false, Text: 'TransmitterName', label: 'Full Transmitter Name' },
        { id: 'Site', numeric: false, disablePadding: false, Value: 6, IsDate: false, Text: 'Site', filterBy: false, label: 'Site' },
    ];
    const [filterOpts, setFilterOpts] = React.useState(filterOptions);

    const countOfTransmittersWithErrors = (site) => {
        if (!site || !Array.isArray(site.Transmitters)) {
            return 0;
        }

        return site.Transmitters.reduce((count, transmitter) => {
            if (transmitter.Cameras && Array.isArray(transmitter.Cameras)) {
                const hasError = transmitter.Cameras.some(camera => camera.ErrorType == 1);
                return hasError ? count + 1 : count;
            }
            return count;
        }, 0);
    };

    const countOfCamerasWithErrors = (site) => {
        let counter = 0;
        site.Transmitters.forEach(transmitter => {
            transmitter.Cameras.forEach(camera => {
                if (camera.ErrorType === 2) {
                    counter++;
                }
            });
        });

        return counter;
    }

    const countOfCameras = (site) => {
        let counter = 0;
        site.Transmitters.forEach(transmitter => {
            transmitter.Cameras.forEach(() => {
                counter++;
            });
        });

        return counter;
    }

    async function fetchFilters() {
        let filterData = await HeartbeatImageService.getFilterData();

        const dropdownData = filterOpts.map(data => {
            if (data.id === 'Site') {
                data.dropdown = filterData.Sites
            }
            if (data.id === 'TransmitterName') {
                data.dropdown = filterData.Transmitters
            }

            return data;
        });

        setFilterOpts(dropdownData);
    }

    async function fetchData() {
        setLoading(true);

        const isFiltered = applyData.some(filter => filter.sourcedropdown === 'Date');

        let rowData = {};
        let transmitterErrorCount = 0;
        let cameraErrorCount = 0;
        let updateFailureCount = 0;

        const rowsPerPage = 10;

        try {
            if (isFiltered) {
                if (showingDefaultDashboard) {
                    setRows([]);
                }
                let data = await HeartbeatImageService.getFilteredImageHeartbeatsAsync(applyData, page + 1, rowsPerPage);
                rowData = data.ImageReports;
                transmitterErrorCount = data.TransmitterErrors;
                cameraErrorCount = data.CameraErrors;
                updateFailureCount = data.ExecutionFailures;
                setShowingDefaultDashboard(false);
            } else {
                if (!showingDefaultDashboard) {
                    setRows([]);
                }
                let data = await HeartbeatImageService.getlastExecutionImageHeartbeatsAsync(applyData, page + 1, rowsPerPage);
                rowData = data.Sites;
                transmitterErrorCount = data.TransmitterErrors;
                cameraErrorCount = data.CameraErrors;
                updateFailureCount = data.ExecutionFailures;
                setShowingDefaultDashboard(true);
            }
        } catch (err) {
            props.snackbarShowMessage('The data could not be loaded. ', 'error', 4000);
            setRows([]);
            setLoading(false);
            return;
        }

        if (page === 0) {
            setRows([...rowData]);
        } else {
            setRows([...rows, ...rowData]);
        }

        setTransmitterErrors(transmitterErrorCount);
        setCameraErrors(cameraErrorCount);
        setUpdateFailures(updateFailureCount);

        setLoading(false);
    }

    async function getMoreData() {
        setPage(page + 1);
    }

    useEffect(() => {
        fetchFilters();
    }, []);

    useEffect(() => {
        fetchData();
    }, [page, applyData]);

    return (
        <div className={classes.pageContainer}>
            <div className={classes.stickyGrid}>
                <div style={{ paddingTop: '25px', marginBottom: '20px' }} className={classes.stickyGrid}>
                    <FilterComponent
                        sourcedropdownText={filterOpts}
                        setApply={setApplyData}
                        filterList={applyData}
                        setPage={setPage}
                    />
                </div>

                <Grid container spacing={3}>
                    <Grid item lg={3} sm={3} xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                    spacing={3}
                                >
                                    <Stack spacing={1}>
                                        <Typography color="text.secondary" variant="overline">
                                            Update Failures
                                        </Typography>
                                        <Typography variant="h4" className={updateFailures > 0 ? classes.red : classes.green}>
                                            {updateFailures}
                                        </Typography>
                                    </Stack>
                                    <Avatar
                                        sx={{
                                            height: '55px',
                                            width: '55px',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {updateFailures > 0 ? (
                                            <WarningIcon className={classes.red} sx={{ height: '100%', width: '100%' }} />
                                        ) : (
                                            <CheckCircleIcon className={classes.green} sx={{ height: '100%', width: '100%' }} />
                                        )}
                                    </Avatar>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={3} sm={3} xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                    spacing={3}
                                >
                                    <Stack spacing={1}>
                                        <Typography color="text.secondary" variant="overline">
                                            Transmitter Errors
                                        </Typography>
                                        <Typography variant="h4" className={transmitterErrors > 0 ? classes.red : classes.green}>
                                            {transmitterErrors}
                                        </Typography>
                                    </Stack>
                                    <Avatar
                                        sx={{
                                            height: '55px',
                                            width: '55px',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {transmitterErrors > 0 ? (
                                            <ErrorIcon className={classes.red} sx={{ height: '100%', width: '100%' }} />
                                        ) : (
                                            <CheckCircleIcon className={transmitterErrors > 0 ? classes.red : classes.green} sx={{ height: '100%', width: '100%' }} />
                                        )}
                                    </Avatar>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={3} sm={3} xs={12}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                    }}
                                    spacing={3}
                                >
                                    <Stack spacing={1}>
                                        <Typography color="text.secondary" variant="overline">
                                            Camera Errors
                                        </Typography>
                                        <Typography variant="h4" className={cameraErrors > 0 ? classes.orange : classes.green}>
                                            {cameraErrors}
                                        </Typography>
                                    </Stack>
                                    <Avatar
                                        sx={{
                                            height: '55px',
                                            width: '55px',
                                            backgroundColor: 'white',
                                        }}
                                    >
                                        {cameraErrors > 0 ? (
                                            <WarningIcon className={classes.orange} sx={{ height: '100%', width: '100%' }} />
                                        ) : (
                                            <CheckCircleIcon className={cameraErrors > 0 ? classes.orange : classes.green} sx={{ height: '100%', width: '100%' }} />
                                        )}
                                    </Avatar>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Grid xs={12} className={classes.tableGrid}>
                <InfiniteScroll
                    className={classes.infiniteScroll}
                    dataLength={rows.length}
                    next={getMoreData}
                    hasMore={true}
                    loader={loading ? (
                        <>
                            <div style={{ marginLeft: '47%' }}>
                                <CircularProgress size={24} />
                                <span>Loading</span>
                            </div>
                        </>
                    ) : (
                        <span></span>
                    )}
                    scrollableTarget="tableContainer"
                >
                    {showingDefaultDashboard ? (
                        <SitesComponent
                            rows={rows}
                            open={open}
                            handleToggleRow={handleToggleRow}
                            countOfTransmittersWithErrors={countOfTransmittersWithErrors}
                            countOfCamerasWithErrors={countOfCamerasWithErrors}
                            countOfCameras={countOfCameras}
                        />
                    ) : (
                        <FilteredSitesComponent
                            rows={rows}
                            open={open}
                            handleToggleRow={handleToggleRow}
                            countOfTransmittersWithErrors={countOfTransmittersWithErrors}
                            countOfCamerasWithErrors={countOfCamerasWithErrors}
                            countOfCameras={countOfCameras}
                        />
                    )}
                </InfiniteScroll>
            </Grid>
        </div>
    );
}

OperationsComponent.propTypes = {
    snackbarShowMessage: PropTypes.func.isRequired,
};

export default withSnackbar(OperationsComponent);
