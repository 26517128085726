// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { Tooltip } from '@mui/material';

// Services
import SitesService from '../../components/sites/sites.service';
import { stableData, getComparator } from '../../helpers/Sorting';

// Components
import SiteRecordComponent from '../../components/sites/site.component';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import TenantsService from '../tenants/tenants.service';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';

const headCells = [
    { id: 'siteName', numeric: false, disablePadding: false, label: 'Full Site Name' },
    { id: 'tenant', numeric: false, disablePadding: false, label: 'Customer' },
    { id: 'category', numeric: false, disablePadding: false, label: 'Category' }
];

const dashboardHeadCells = [
    { id: 'siteName', numeric: false, disablePadding: false, label: 'Full Site Name' }
];

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: props => ({
        width: props.isDashboard ? '97.5%' : '100%',
        marginBottom: '10px',
        marginTop: props.isDashboard ? '10px' : '0',
        marginLeft: props.isDashboard ? '10px' : '0',
        border: '1px solid black'
    }),
    table: {
        minWidth: 750,
    },
}));

/**A table component with a toolbar and edit functions */
function SitesComponent(props) {
    /** The page data */
    const { isDashboard, snackbarShowMessage } = props;
    const classes = useStyles({ isDashboard });
    const [state, setState] = React.useState({ sites: [], tenants: [] });
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('siteName');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState('');
    const [isForceReload, setIsForceReload] = React.useState(false);

    useEffect(() => {
        async function loadData() {
            await populateSiteData();
        }
        loadData();
    }, [isForceReload, page, rowsPerPage, order, orderBy]);

    async function populateSiteData() {
        setLoading(true);
        let rowData = {};
        let tenantrowData = [];

        try {
            if (isDashboard) {
                rowData = await SitesService.getSitesForUserAsync(page + 1, rowsPerPage, order, orderBy, search);
            } else {
                rowData = await SitesService.getSitesAsync(page + 1, rowsPerPage, order, orderBy, search);
            }
        } catch (err) {
            props.snackbarShowMessage('The sites could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ sites: [] });
            setLoading(false);
            return;
        }
        try {
            tenantrowData = await TenantsService.getAllTenantsDropdown('asc', null, null);
        } catch (err) {
            props.snackbarShowMessage('The customers could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ sites: state.sites, tenants: [] });
            setLoading(false);
            return;
        }
        setState({ sites: rowData.items, tenants: tenantrowData });
        setTotalPageCount(rowData.pageInfo.pageCount);
        setLoading(false);
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc';

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    async function deleteSite(event, row) {
        setLoading(true);
        const deleted = await SitesService.deleteSite(row);
        if (deleted === 'ok') props.snackbarShowMessage('The site has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The site could not be deleted. ', 'error', 4000);
        populateSiteData();
        setLoading(false);
    }

    const assignSite = async (event, selectedTransmitter, row) => {
        try {
            await SitesService.assignSiteToTransmitter(selectedTransmitter.Id, row.Id);
            props.snackbarShowMessage(`Site assigned: ${row.SiteName}`, 'success');
            setTimeout(() => {
                props.onClose();
            }, 1000);
        } catch (error) {
            props.snackbarShowMessage('Failed to assign site.', 'error');
        }
    };

    async function handleChangePage(e, newPage) {
        setPage(newPage);
    }

    async function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const buttonIcon = <Tooltip title={'Add a site'}><AddIcon data-testid='add-site-button'></AddIcon></Tooltip>;

    const modal = (
        <SiteRecordComponent
            tenants={state.tenants}
            btnFunction={populateSiteData}
            buttonIcon={buttonIcon}
            isDashboard={isDashboard}
        />
    );

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.sites.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    hasAddButton={true}
                    modal={modal}
                    searchPlaceholder={'Search sites...'}
                    setSearch={handleSearch}
                />
                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={isDashboard ? dashboardHeadCells : headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.sites.length < 1 ? <TableRow><TableCell colSpan='3'>No sites were found.</TableCell></TableRow> : stableData(state.sites, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btndelete = <Tooltip title={'Remove this site'}><div> <IconButton onClick={(event) => deleteSite(event, row)} variant='contained' color='primary' data-testid='delete-site-button'><DeleteIcon /></IconButton></div></Tooltip>
                                    const buttonIcon = <Tooltip title={'Edit this site'} data-testid='edit-site-button'><div><EditIcon></EditIcon></div></Tooltip>
                                    const btnAssign = <Tooltip title={'Assign this site'}><div> <IconButton onClick={(event) => assignSite(event, props.selectedTransmitter, row)} variant='contained' color='primary' aria-label='assign' data-testid='assign-site-button'> <AssignmentReturnedIcon /></IconButton></div></Tooltip>

                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component='th' id={labelId} scope='row' align='left'>
                                                {row.SiteName}
                                            </TableCell>
                                            {!isDashboard && <TableCell align='left'>{row.Tenant}</TableCell>}
                                            {!isDashboard && <TableCell align='left'>{row.CategoryName}</TableCell>}
                                            <TableCell align='right' >
                                                <ButtonGroup >
                                                    {btndelete}
                                                    <SiteRecordComponent
                                                        item={row}
                                                        snackbarShowMessage={snackbarShowMessage}
                                                        tenants={state.tenants}
                                                        btnFunction={populateSiteData}
                                                        buttonIcon={buttonIcon}
                                                        isDashboard={isDashboard}
                                                    />
                                                    {isDashboard && btnAssign}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell style={{ border: 'none' }} colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={totalPageCount * rowsPerPage}
                    slotProps={{
                        actions: {
                            nextButton: {
                                'data-testid': 'site-next-button',
                            },
                        },
                    }}
                    disabled={false}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, params) => {
                        handleChangePage(event, params);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

SitesComponent.propTypes = {
    isDashboard: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    selectedTransmitter: PropTypes.object,
    snackbarShowMessage: PropTypes.func
};

export default withSnackbar(SitesComponent);
