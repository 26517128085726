// Services
import Http from '../../services/http';

export default class CategoriesService {
    static async getCategories(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        const categories = await Http.getData(process.env.REACT_APP_NVR_API + 'api/categories?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text)))
        return categories;
    }

    static async getCategoriesDropdown(order, orderBy, text) {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/categories/dropdown?order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text)))
    }

    static async deleteCategory(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/categories/delete', data);
    }
}
