// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Services
import CategoryService from '../../components/categories/category.service';

// Components
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function CategoryRecordComponent(props) {
    const classes = useAppStyles();
    const [state, setState] = useState({ item: {} });
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setState({ item: props.item });
    }, []);

    const handleClose = () => {
        setOpen(false);
    };
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    return (

        <div>
            <IconButton onClick={() => handleOpen()} variant='contained' color='primary'>
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    initialValues={{
                        id: state.item?.Id,
                        categoryId: state.item?.CategoryId,
                        categoryName: state.item?.CategoryName
                    }}
                    validationSchema={Yup.object({
                        categoryName: Yup.string()
                            .min(3, 'Must be at least 5 characters')
                            .max(35, 'Must be 35 characters or less')
                            .required('Required'),
                    })}
                    onSubmit={async (values) => {
                        let reload = false;
                        setState({ item: state.item });
                        if (values.id === undefined) {
                            try {
                                let added = await CategoryService.insertCategory(values);
                                if (added === true) {
                                    props.snackbarShowMessage('The category has been added. ', 'success', 4000);
                                    reload = true;
                                } else {
                                    props.snackbarShowMessage('The category could not be added. ', 'error', 4000);
                                }
                            } catch (err) {
                                props.snackbarShowMessage('The category could not be added. ', 'error', 4000);
                            }
                        }
                        else {
                            try {
                                let updated = await CategoryService.updateCategory(values);
                                if (updated === true) {
                                    props.snackbarShowMessage('The category has been updated. ', 'success', 4000);
                                    reload = true;
                                } else {
                                    props.snackbarShowMessage('The category could not be updated. ', 'error', 4000);
                                }
                            } catch (err) {
                                props.snackbarShowMessage('The category could not be updated. ', 'error', 4000);
                            }
                        }
                        setState({ item: state.item });
                        await sleep(500);
                        if (reload === true) props.btnFunction();
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form id='category_form' onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span>Add Category</span> : <span >Edit Category: {state.item.CategoryName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <input type='hidden' id='id' name='id' />
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Category Name'
                                    name='categoryName'
                                    value={values.categoryName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.categoryName && Boolean(errors.categoryName)}
                                    fullWidth
                                />

                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                    data-testid='save-button'
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}

export default withSnackbar(CategoryRecordComponent);

CategoryRecordComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    item: PropTypes.object,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func
};