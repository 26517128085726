// Services
import Http from '../../services/http';

export default class UserService {

    static async getCustomersBySubject(subject) {
        const customers = await Http.getData(process.env.REACT_APP_NVR_API + 'api/user/customersdropdown?subject=' + subject).catch(error => console.error(error));
        return customers;
    }

    static async getAllSites() {
        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/user/siteDropdown').catch(error => console.error(error));
        return sites;
    }

    static async getCheckedSitesByTenantIds(subject) {
        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/user/checkedSiteDropdown?subject=' + subject).catch(error => console.error(error));
        return sites;
    }

    static async updateUser(row, selectedSitesWithReference) {
        let data = {
            ClientId: process.env.REACT_APP_IDP_CLIENT_ID,
            Subject: row.subject,
            Role: row.role,
            TenantId: row.tenantId,
            TwoFactorEnabled: row.twoFactorEnabled,
            SiteId: row.siteId,
            TenantAssignments: selectedSitesWithReference
        };
        await Http.postData(process.env.REACT_APP_IDP_API + 'api/account/update', data).catch(error => console.error(error));
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/users/update', data).catch(error => console.error(error));
    }

    static async insertUserIdentity(row) {
        let data = {
            ClientId: process.env.REACT_APP_IDP_CLIENT_ID,
            Subject: row.subject,
            Role: row.role
        };
        return await Http.postData(process.env.REACT_APP_IDP_API + 'api/account/activate', data).catch(error => console.error(error));
    }

    static async SendUserInvite(row, selectSiteWithReference) {
        let data = {
            ClientId: process.env.REACT_APP_IDP_CLIENT_ID,
            TenantId: row.tenantId,
            SubjectId: row.Subject,
            Email: row.email,
            TwoFactorEnabled: row.twoFactorEnabled,
            Role: row.role,
            TenantAssignments: selectSiteWithReference
        };
        return await Http.postData(process.env.REACT_APP_IDP_API + 'api/account/invite', data).catch(error => console.error(error));

    }

    static async insertUser(row) {
        let data = {
            Subject: row.subject,
            Role: row.role,
            Email: row.email,
            TenantId: row.tenantId,
            TwoFactorEnabled: row.twoFactorEnabled
        };

        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/users', data);

    }

    static async activateUser(row) {
        return await Http.postData(process.env.REACT_APP_IDP_API + 'api/account/' + row.subject + '/activate', {}).catch(error => console.error(error));

    }

}