// Services
import Http from '../../services/http';

export default class CategorysService {

    static async updateCategory(row) {
        let data = {
            Id: row.id,
            CategoryName: row.categoryName
        };

        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/categories/update', data);
    }

    static async insertCategory(row) {
        let data = {
            categoryName: row.categoryName
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/categories', data);
    }
}