// MUI
import { makeStyles, createStyles } from '@mui/styles';

const useAppStyles = makeStyles((theme) => createStyles({
    title: {
        color: 'white !important',//theme.palette.white
        fontSize: '1.1em !important',
        right: theme.spacing(1),
        paddingTop: '5px'
    },
    closeButton: {
        marginTop: '-5px !important',
        position: 'absolute',
        float: 'right',
        color: 'white !important'
    },
    paper: {
        position: 'absolute',
        width: 450,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    root: {
        margin: 0,
        padding: '20px',
        paddingBottom: '0px',
        minHeight: '35px',
        color: 'white',//theme.palette.white,
        width: 552,
        backgroundColor: theme.palette.secondary.main,
        zIndex: '888'
    },
    table: {
        minWidth: 750,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));
export default useAppStyles;