// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useToolbarStyles = makeStyles((theme) => ({
    container: {
        width: 'calc(100% - 20px)',
        spacing: 0,
        padding: 2,
        margin: '16px 0 16px 0 !important'
    },
    searchItem: {
        display: 'flex',
        alignItems: 'center',
        border: '2px solid',
        paddingLeft: theme.spacing(1),
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderRadius: '15px',
        width: '100px',
    },
    searchArea:
    {
        width: '100%',
        paddingLeft: 16
    },
    searchInput:
    {
        width: '99%'
    }
}));

/**
 * The button toolbar to be used by the page's data table
 * @param {any} props
 */
function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();
    const { setSearch, search, modal, additionalmodal, searchPlaceholder, hasAddButton } = props;
    let addCol = 0;
    let searchCol = 12;
    let showAdditional = false;
    let timeout = 0;
    if (hasAddButton) {
        if (additionalmodal) {
            showAdditional = true;
            addCol = .5;
            searchCol = 11.0;
        } else {
            addCol = .5;
            searchCol = 11.5;
        }
    }
    else if (additionalmodal) {
        showAdditional = true;
        addCol = .5;
        searchCol = 11.5;
    }

    function doSearch(event){
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
            setSearch(event.target.value);
        }, 500);
    }

    return (
        <Grid container className={classes.container } >
            {(additionalmodal) &&
                <Grid item  xs={addCol} sx={{
                    display: 'block ruby',
                    textAlign: 'center'
                }}
                >    
                    <div hidden={(showAdditional !== true)}>
                        {additionalmodal}
                    </div>
                </Grid>
            }
            {(hasAddButton) &&
                <Grid item xs={addCol}
                    sx={{
                        display: 'block ruby',
                        textAlign: 'center'
                    }}
                >
                    <div hidden={(hasAddButton !== true)}>
                        {modal}
                    </div>
                </Grid>
            }
            <Grid item xs={searchCol} className={classes.searchItem} >
                <div className={classes.searchArea}>
                    <InputBase
                        className={classes.searchInput}
                        placeholder={searchPlaceholder}
                        defaultValue={search}
                        onKeyUp={(e) => doSearch(e)}
                        onChange={(e) => doSearch(e)}
                        inputProps={{ 'aria-label': 'search', 'data-testid': 'search-testid' }}
                        endAdornment={<SearchIcon color='primary' />}
                    />
                </div>
            </Grid>
        </Grid>
    );
}
export default EnhancedTableToolbar;

EnhancedTableToolbar.propTypes = {
    setSearch: PropTypes.func,
    modal: PropTypes.object,
    additionalmodal: PropTypes.object,
    searchPlaceholder: PropTypes.string,
    search: PropTypes.string,
    hasAddButton: PropTypes.bool,
};