// MUI
import { withStyles } from '@mui/styles';
import MuiDialogActions from '@mui/material/DialogActions';

const AppDialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    }
}))(MuiDialogActions);
export default AppDialogActions;