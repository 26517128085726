// React
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';

// Mui
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { Grid, InputLabel, Container, Box, Typography, Tooltip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import { BasicBreadcrumbs } from '../../ui/molecules/BasicBreadcrumbs';
import FootageVideoPlayer from '../../ui/atoms/FootageVideoPlayer';
import FilterComponent from '../../ui/molecules/FilterComponent';
import { MapComponent } from '../map/map.component';
import ExportButton from '../../ui/atoms/ExcelExport';

// Services
import AuditsService from '../audits/audits.service';
import FootageService from './footage.service';
import { getLocalDateTime } from '../../utils/dateFormatter';
import AreYouSure from '../../ui/molecules/AreYouSure';
import PrivateComponent from '../../components/rbac/PrivateComponent';
import { roles } from '../../utils/roles.js';
import { auditNames } from '../../utils/auditNames';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        paddingLeft: '20px'
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '0',
        boxShadow: '0 !important'
    },
    gridContainer: {
        marginTop: '0px !important'
    },
    table: {
        minWidth: 750,
    },
    sortBy: {
        padding: '13px !important'
    },
    infoRow: {
        height: '25px'
    },
    stretch: {
        height: 'calc(100% - 75px)'
    },
    formLabelContainer: {
        position: 'relative'
    },
    settingsIconsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        right: '10px',
    },
    videoPlayerContainer: {
        marginBottom: '20px',
    }
}));

function FormLabel(props) {
    return (
        <div>
            <Typography variant='subtitle1' fontWeight='bold' fontSize='18px' component='span'>{props.text}:</Typography>
            <br />
            <Typography variant='subtitle1' fontSize='16px' component='span'>{props.value ?? 'n.a'}</Typography>
        </div>
    );
}

function SettingsIcon(props) {
    let { type, tooltip, item, onClick } = props;
    const [clicked, setClicked] = useState(type == 'Important' ? item.Important : false);
    const [hovered, setHovered] = useState(false);

    const classes = makeStyles((theme) => ({
        container: {
            display: 'flex',
            height: 'fit-content',
            alignItems: 'center',
            flexDirection: 'column',
            paddingLeft: '15px',
            position: 'relative'
        },
        icon: {
            fontSize: '35px !important',
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
        iconActive: {
            fontSize: '35px !important',
            cursor: 'pointer',
            color: theme.palette.secondary.main
        },
        label: {
            width: 'fit-content',
            display: 'inline-block',
            fontSize: '12px !important'
        }
    }))();

    return (
        <div className={classes.container} onClick={() => onClick(setClicked)} onKeyDown={() => onClick(setClicked)}>
            <Tooltip title={tooltip}>
                {
                    type == 'Important' ?
                        clicked ?
                            <BookmarkAddedIcon className={classes.iconActive} /> :
                            <TurnedInNotIcon className={classes.icon} /> :
                        type == 'Download' ?
                            clicked ?
                                <>
                                    <FileDownloadIcon className={classes.icon} />
                                    <CircularProgress thickness='2' size='2.8rem' sx={{ position: 'absolute', top: '-5px' }} />
                                </> :
                                <FileDownloadIcon className={classes.icon} /> :
                            type == 'Delete' ?
                                <DeleteIcon
                                    className={hovered ? classes.iconActive : classes.icon}
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                /> : ''
                }
            </Tooltip>
            <InputLabel className={classes.label} >{type}</InputLabel>
        </div>
    );
}

SettingsIcon.propTypes = {
    type: PropTypes.oneOf(['Important', 'Download', 'Delete']),
    tooltip: PropTypes.string.isRequired,
    item: PropTypes.object,
    onClick: PropTypes.func,
};

FormLabel.propTypes = {
    text: PropTypes.string,
    value: PropTypes.string
};

function ClippedDrawer(props) {
    const drawerWidth = 600;
    const classes = useStyles();
    const { item, setItem, isOpen, toggleDrawer, videoPath, footageType } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const playerRef = useRef(null);

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const response = await FootageService.getSource('video/stream/' + videoPath);
                setVideoUrl(response);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImageUrl();
    }, [videoPath]);

    useEffect(() => {
        if (isOpen) {
            populateAuditData();
        }
    }, [isOpen]);

    async function populateAuditData() {

        if (footageType === 'NvrFootage') {
            await AuditsService.insertAuditData(auditNames.ViewFootage, 'View Footage Info - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
        } else {
            await AuditsService.insertAuditData(auditNames.ViewFootage, 'View Footage Info - for footage ' + item.TransmitterName + ' on ' + getLocalDateTime(item.CaptureDate));
        }
    }

    async function onImportanceClick(setClicked) {
        const newState = !item.Important;
        if (footageType === 'NvrFootage') {

            await FootageService.updateImportance(item.Id, newState);
            item.Important = newState;
            if (item.Important) await AuditsService.insertAuditData(auditNames.ViewFootage, 'Mark Footage as Important - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));

        } else {
            await FootageService.updateAnprImportance(item.Id, newState);
            item.Important = newState;
            if (item.Important) await AuditsService.insertAuditData(auditNames.ViewFootage, 'Mark Footage as Important - for footage ' + item.TransmitterName + ' on ' + getLocalDateTime(item.CaptureDate));
        }

        setClicked(newState);
        setItem(item);
    }

    async function onDownloadClick(setClicked) {

        if (footageType === 'NvrFootage') {
            await AuditsService.insertAuditData(auditNames.ViewFootage, 'Download Footage - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
            const response = await FootageService.getSource('video/stream/' + item.Video);
            window.location = response;
        }
        else {
            await AuditsService.insertAuditData(auditNames.ViewFootage, 'Download Footage - for footage ' + item.TransmitterName + ' on ' + getLocalDateTime(item.CaptureDate));
            const response = await FootageService.getSource('anpr/' + createCompatibleBlobRefPath(item.BlobRef));
            window.location = response;
        }
        setClicked(true);
        setTimeout(() => setClicked(false), 1200)
    }

    function createCompatibleBlobRefPath(blobRef) {
        return blobRef.replace(/\//g, '_');
    }

    async function onDeleteClick() {
        setOpenDialog(true);
    }

    async function onDeleteIconClick() {

        if (footageType === 'NvrFootage') {
            let deleted = await FootageService.deleteFootage(item.Id);
            if (deleted === 'ok') {
                props.snackbarShowMessage('The footage has been deleted. ', 'success', 4000);
                await AuditsService.insertAuditData(auditNames.ViewFootage, 'Delete Footage - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
            } else {
                props.snackbarShowMessage(deleted + 'The footage could not be deleted. ', 'error', 4000);
            }
        }
        else {
            let deleted = await FootageService.deleteAnprFootage(item.Id);
            if (deleted === 'ok') {
                props.snackbarShowMessage('The footage has been deleted. ', 'success', 4000);
                await AuditsService.insertAuditData(auditNames.ViewFootage, 'Delete Footage - for footage ' + item.TransmitterName + ' on ' + getLocalDateTime(item.CaptureDate));
            } else {
                props.snackbarShowMessage(deleted + 'The footage could not be deleted. ', 'error', 4000);
            }
        }

        setOpenDialog(false);
        toggleDrawer(false);
        props.setIsForceReload((prev) => !prev);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                anchor={'right'}
                open={isOpen}
                onClose={() => toggleDrawer(false)}
                sx={{
                    marginTop: 60,
                    marginBottom: 60,
                    width: drawerWidth,
                    flexShrink: 0,
                    ['& .MuiDrawer-paper']: { width: drawerWidth, boxSizing: 'border-box', marginTop: 8 },
                }}
            >
                <Box sx={{ paddingTop: 5, paddingBottom: 10, overflow: 'auto' }}>
                    <Typography paragraph>
                        <Container maxWidth='sm'>
                            <div className={classes.videoPlayerContainer}>

                                {footageType === 'NvrFootage' && (
                                    <FootageVideoPlayer
                                        videoUrl={videoUrl}
                                        playerRef={playerRef}
                                        item={item}
                                    />
                                )}

                                {footageType === 'AnprFootage' && (
                                    <LazyImage thumbnail={`anpr/${createCompatibleBlobRefPath(item.BlobRef)}`}></LazyImage>
                                )}
                            </div>

                            <div className={classes.formLabelContainer}>
                                {footageType === 'NvrFootage' && (
                                    <>
                                        <FormLabel text='Name' value={item.Name + ' - ' + item.Device} />
                                        <FormLabel text='Date' value={getLocalDateTime(item.FootageStart)} />
                                        <FormLabel text='Length' value={item.LengthLabel} />
                                        <FormLabel text='Additional Information' value={'User: ' + item.User + ' uploaded on ' + getLocalDateTime(item.CreatedAt)} />
                                    </>
                                )}
                                {footageType === 'AnprFootage' && (
                                    <>
                                        <FormLabel text='Transmitter Name' value={item.TransmitterName} />
                                        <FormLabel text='Camera Name' value={item.CameraName} />
                                        <FormLabel text='Date' value={getLocalDateTime(item.CaptureDate)} />
                                        <FormLabel text='Plate' value={item.Plate} />
                                        <FormLabel text='Country' value={item.PlateCountry ? item.PlateCountry : 'Unknown'} />
                                        <FormLabel text='Confidence' value={(item.PlateConfidence * 100).toFixed(2) + '%'} />
                                        {item.VehicleType && (<FormLabel text='Vehicle Type' value={item.VehicleType} />)}
                                        {item.VehicleColor && (<FormLabel text='Colour' value={item.VehicleColor} />)}

                                        {item.Lat && item.Lng && item.Lat != 0 && item.Lng != 0 ? (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={7}>
                                                    <MapComponent
                                                        lat={item.Lat}
                                                        lng={item.Lng}
                                                        containerStyle={{ width: '100%', height: '320px' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={5}>
                                                    <FormLabel text='Longitude' value={item.Lng.toString()} />
                                                    <FormLabel text='Latitude' value={item.Lat.toString()} />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <FormLabel text='Location' value='Not Available' />
                                        )}
                                    </>
                                )}
                                <div className={classes.settingsIconsContainer}>
                                    <SettingsIcon type='Download' tooltip='Download Footage' item={item} onClick={onDownloadClick} />
                                    <SettingsIcon type='Important' tooltip='Unlimited Retention' item={item} onClick={onImportanceClick} />
                                    <PrivateComponent component={
                                        <SettingsIcon type='Delete' tooltip='Delete Footage' item={item} onClick={onDeleteClick} />
                                    } roles={roles.Administrator + ' ' + roles.WCCTV} />
                                </div>
                            </div>
                        </Container>
                    </Typography>
                </Box>
            </Drawer>
            {openDialog && footageType === 'AnprFootage' && (
                <AreYouSure
                    isOpenDialog={openDialog}
                    closeDialog={() => setOpenDialog(false)}
                    titleText='Delete Footage'
                    contentText={`Are you sure you want to delete footage ${item.TransmitterName} - ${item.CameraName} (${item.Site} - ${getLocalDateTime(item.CaptureDate)})`}
                    onYesClick={onDeleteIconClick}
                />
            )}

            {openDialog && footageType === 'NvrFootage' && (
                <AreYouSure
                    isOpenDialog={openDialog}
                    closeDialog={() => setOpenDialog(false)}
                    titleText='Delete Footage'
                    contentText={`Are you sure you want to delete footage ${item.Name} - ${item.Device} (${item.Site} - ${getLocalDateTime(item.FootageStart)})`}
                    onYesClick={onDeleteIconClick}
                />
            )}

        </Box>
    );
}

ClippedDrawer.propTypes = {
    item: PropTypes.object,
    setItem: PropTypes.func,
    isOpen: PropTypes.bool,
    toggleDrawer: PropTypes.func,
    snackbarShowMessage: PropTypes.func,
    setIsForceReload: PropTypes.func,
    videoPath: PropTypes.string,
    footageType: PropTypes.string
};


ClippedDrawer.propTypes = {
    item: PropTypes.object,
    setItem: PropTypes.func,
    isOpen: PropTypes.bool,
    toggleDrawer: PropTypes.func,
    snackbarShowMessage: PropTypes.func,
    setIsForceReload: PropTypes.func,
    videoPath: PropTypes.string
};

function LazyImage(props) {
    const [loaded, setLoaded] = React.useState(false);
    const { thumbnail } = props;
    const [imageUrl, setImageUrl] = useState('');
    function confirmLoad() {
        setTimeout(function () {

            setLoaded(true);
        }, 100)
    }

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const response = await FootageService.getSource(thumbnail);
                setImageUrl(response);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImageUrl();
    }, [thumbnail]);

    return (

        <div style={{ borderRadius: '8px', cursor: 'pointer', width: loaded ? '300px' : 'auto' }}>

            <img
                alt='Thumbnail'
                src={imageUrl}
                style={{ width: '300px', height: 'auto', borderRadius: '8px', cursor: 'pointer', display: (loaded ? 'inline' : 'none') }}
                onLoad={confirmLoad}
            />
            {
                (loaded === false) &&
                <Skeleton variant='rectangular' width={300} height={200} />
            }
        </div>
    );
}

LazyImage.propTypes = {
    thumbnail: PropTypes.string
};

function AppNvrFootageRow(props) {
    const { row, setData } = props;
    const localDate = getLocalDateTime(row.FootageStart);
    const classes = useStyles();

    async function loadVideo(e, item) {
        await setData(item, 'NvrFootage'); // Pass the footage type
    }

    return (
        <Grid
            container
            direction='row'
            style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', paddingBottom: '10px' }}
            spacing={0}
            padding={0}>
            <Grid rowSpan={4} item sx={{ width: 300 }}>
                <a onClick={event => loadVideo(event, row)} onKeyDown={event => loadVideo(event, row)}>
                    <LazyImage thumbnail={`image/stream/${row.Thumbnail}`}></LazyImage>
                    <Badge color='primary' badgeContent={row.Length} style={{ top: -24, right: -30, float: 'left' }}></Badge>
                </a>
            </Grid>
            <Grid container direction='column' item xs={4} style={{ paddingLeft: '20px' }}>
                <Typography style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }} className={classes.infoRow}>
                    <strong>{row.Name} - {row.Device}</strong>
                    {row.Important && (
                        <Tooltip title='Important'>
                            <BookmarkAddedIcon color='secondary' style={{ marginLeft: '4px' }} />
                        </Tooltip>
                    )}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    {row.Site}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    {localDate}
                </Typography>
                <Typography sx={{ display: 'flex', alignItems: 'end' }} style={{ fontSize: '14px', paddingBottom: '16px' }} className={classes.stretch}>
                    {'User: ' + row.User + ' uploaded on ' + getLocalDateTime(row.CreatedAt)}
                </Typography>
            </Grid>
        </Grid>
    );
}

AppNvrFootageRow.propTypes = {
    row: PropTypes.object,
    setData: PropTypes.func
};


AppNvrFootageRow.propTypes = {
    row: PropTypes.object,
    setData: PropTypes.func
};

function AppAnprFootageRow(props) {
    const { row, setData } = props;
    const localDate = getLocalDateTime(row.CaptureDate);
    const classes = useStyles();

    async function loadAnpr(e, item) {
        await setData(item, 'AnprFootage');
    }

    return (
        <Grid
            container
            direction='row'
            style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif', paddingBottom: '10px' }}
            spacing={0}
            padding={0}>
            <Grid rowSpan={4} item sx={{ width: 300 }}>
                <a onClick={event => loadAnpr(event, row)} onKeyDown={event => loadAnpr(event, row)}>
                    <LazyImage thumbnail={`anpr/${row.BlobRef.replace(/\//g, '_')}`}></LazyImage>
                </a>
            </Grid>
            <Grid container direction='column' item xs={4} style={{ paddingLeft: '20px' }}>
                <Typography style={{ fontSize: '18px', display: 'flex', alignItems: 'center' }} className={classes.infoRow}>
                    <strong>{row.TransmitterName} - {row.CameraName}</strong>
                    {row.Important && (
                        <Tooltip title='Important'>
                            <BookmarkAddedIcon color='secondary' style={{ marginLeft: '4px' }} />
                        </Tooltip>
                    )}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    {row.Site}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    {localDate}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    Plate: {row.Plate}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    Country: {row.PlateCountry ? row.PlateCountry : 'Unknown'}
                </Typography>
                <Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                    Confidence: {(row.PlateConfidence * 100).toFixed(2)}%
                </Typography>
                {row.VehicleColor &&
                    (<Typography style={{ fontSize: '14px' }} className={classes.infoRow}>
                        Colour: {row.VehicleColor}
                    </Typography>)}
            </Grid>
        </Grid>
    );
}

AppAnprFootageRow.propTypes = {
    row: PropTypes.object,
    setData: PropTypes.func
};


AppAnprFootageRow.propTypes = {
    row: PropTypes.object,
    setData: PropTypes.func
};


/**A table component with a toolbar and edit functions */
function FootageComponent(props) {
    const classes = useStyles();
    let transmitterId = 0;
    let notificationId = 0;
    let transmitterName = '';
    const location = useLocation();
    let dashboardState;
    if (location.state) {
        notificationId = location.state.notificationId;
        transmitterId = location.state.transmitterId;
        transmitterName = location.state.transmitterName;
        dashboardState = location.state.dashboardState;
    }
    const [footage, setFootage] = React.useState([]);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('createdDate');
    const [page, setPage] = React.useState(0);
    const [videoUrl, setVideoUrl] = React.useState('');
    const [videoOpen, setVideoOpen] = React.useState(false);
    const [rowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState('');
    const [item, setItem] = React.useState({});
    const [footageType, setFootageType] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isForceReload, setIsForceReload] = React.useState(false);
    const headRow = [
        { id: 'Created', numeric: false, disablePadding: false, Value: 2, IsDate: true, Text: 'Created', filterBy: true, IsBoolean: false, label: 'Created', IsFootage: true },
        { id: 'Important', numeric: false, disablePadding: false, IsDate: false, Value: 5, Text: 'Important', IsBoolean: true, filterBy: true, label: 'Important' },
        { id: 'Site', numeric: false, disablePadding: false, IsDate: false, Value: 5, Text: 'Site', filterBy: (transmitterId == 0), label: 'Site' }
    ];
    const [sourceDropdownData, setsourceDropdownData] = React.useState(headRow.filter(data => data.filterBy));
    const [applyData, setApplyData] = React.useState([]);

    useEffect(() => {
        async function loadData() {
            await populateFootageData();
        }
        loadData();
    }, [search, order, orderBy, page, isForceReload, location.state, applyData]);

    useEffect(() => {
        populateAuditData();
    }, [notificationId]);

    useEffect(() => {
        populateSites();
    }, []);

    async function populateAuditData() {
        let auditDescription = transmitterName ? 'Footage for transmitter ' + transmitterName : null;
        if (notificationId > 0) { auditDescription += ` (Notification ID: ${notificationId})`; }
        await AuditsService.insertAuditData(auditNames.ViewFootage, auditDescription);
    }
    async function populateSites() {
        if (transmitterId == 0) {
            let sites = await FootageService.getSites();
            const dropdownData = sourceDropdownData.map(data => {
                if (data.id === 'Site') {
                    data.dropdown = sites.items
                }
                return data;
            });

            setsourceDropdownData(dropdownData);
        }
    }
    async function populateFootageData() {
        setLoading(true);
        let rowData = {};
        try {
            rowData = await FootageService.getFilteredFootage(page + 1, rowsPerPage, order, orderBy, search, transmitterId, notificationId, applyData);
        } catch (err) {
            props.snackbarShowMessage('The footage could not be loaded. ', 'error', 4000);
            setFootage([]);
            setLoading(false);
            return;
        }
        if (page === 0) {
            setFootage([...rowData.items]);
        } else {
            setFootage([...footage.concat(rowData.items)]);
        }

        setLoading(false);
    }

    async function loadExportData() {
        try {
            return await FootageService.getExportFootages(order, orderBy, search, transmitterId, notificationId, applyData);
        } catch (err) {
            props.snackbarShowMessage('The footage could not be loaded. ', 'error', 4000);
            setLoading(false);
            return;
        }
    }
    const toggleDrawer = (open) => {
        setVideoOpen(open);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = (property.indexOf('_desc') === -1);
        const orderDescAsc = isAsc ? 'asc' : 'desc';
        property = property.replace('_desc', '');
        setIsForceReload(!isForceReload);
        setPage(0);
        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    let timeout = 0;
    async function searchFootage(txt) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(function () {
            setSearch(txt);
            setPage(0);
        }, 500);
    }

    async function getMoreData() {
        setPage(page + 1);
    }

    async function setNvrData(item, type) {
        setVideoUrl(item.Video);
        setVideoOpen(true);
        setItem(item);
        setFootageType(type);
    }

    async function setAnprData(item, type) {
        setVideoUrl(item.Video);
        setVideoOpen(true);
        setItem(item);
        setFootageType(type);
    }

    return (
        <div className={classes.root}>
            {(transmitterId > 0 || notificationId > 0) && (
                <BasicBreadcrumbs page={'Footage Data for ' + transmitterName} backLabel={'Dashboard'} backTo={'/dashboard'} returnState={dashboardState} />
            )}
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={3} padding={1} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <div className={classes.root}>
                                <Paper className={classes.paper} elevation={0}>
                                    {!(notificationId > 0) && (
                                        <div>
                                            <div style={{ paddingRight: '10px' }}>
                                                <FilterComponent sourcedropdownText={sourceDropdownData} setApply={setApplyData} setPage={setPage} filterList={applyData} ></FilterComponent>
                                            </div>
                                            <Grid container>
                                                <Grid item xs={9} padding={0}>
                                                    <EnhancedTableToolbar
                                                        searchPlaceholder={'Search footage...'}
                                                        setSearch={searchFootage}
                                                    />
                                                </Grid>
                                                <Grid item xs={3} padding={0}>
                                                    <div style={{ display: 'flex', alignItems: 'flex-end', columnGap: 8 }}>
                                                        <TextField
                                                            id='sortBy'
                                                            name='sortBy'
                                                            size='small'
                                                            fullWidth
                                                            select
                                                            className={classes.sortBy}
                                                            defaultValue={'createdDate_desc'}
                                                            onChange={(e) => {
                                                                let sortOption = e.target.value;
                                                                handleRequestSort(e, sortOption);
                                                            }}
                                                        >
                                                            <MenuItem value={'createdDate_desc'}>Date Newest to Oldest</MenuItem>
                                                            <MenuItem value={'createdDate'}>Date Oldest to Newest</MenuItem>
                                                            {!(transmitterId > 0) && (
                                                                <MenuItem value={'device'}>Transmitter A to Z</MenuItem>
                                                            )}
                                                            {!(transmitterId > 0) && (
                                                                <MenuItem value={'device_desc'}>Transmitter Z to A</MenuItem>
                                                            )}
                                                        </TextField>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={6}>
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <ExportButton
                                                        loadExportData={loadExportData}
                                                        title={'Export Footages'}
                                                        filename={'footage' + JSON.stringify(getLocalDateTime(new Date())) + '.xlsx'}
                                                        worksheet={'Footage Data'}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    <Table
                                        className={classes.table}
                                        aria-labelledby='tableTitle'
                                        size={'medium'}
                                        aria-label='enhanced table'
                                    >
                                        <InfiniteScroll
                                            style={{ overflow: 'hidden' }}
                                            dataLength={footage.length}
                                            next={getMoreData}
                                            hasMore={true}
                                            loader={loading ? <HourglassEmptyIcon /> : 'No Footage'}
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                        >
                                            {footage.map((i, index) => (
                                                <React.Fragment key={index}>
                                                    {i.NvrFootage && <AppNvrFootageRow row={i.NvrFootage} setData={setNvrData} />}
                                                    {i.AnprFootage && <AppAnprFootageRow row={i.AnprFootage} setData={setAnprData} />}
                                                </React.Fragment>
                                            ))}
                                        </InfiniteScroll>
                                    </Table>
                                </Paper>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <ClippedDrawer
                    videoPath={videoUrl}
                    item={item}
                    setItem={setItem}
                    anchor={'right'}
                    isOpen={videoOpen}
                    toggleDrawer={toggleDrawer}
                    snackbarShowMessage={props.snackbarShowMessage}
                    setIsForceReload={setIsForceReload}
                    footageType={footageType}
                />
            </Box>
        </div>
    );
}

export default withSnackbar(FootageComponent);

FootageComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};


FootageComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};



