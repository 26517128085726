// React
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Mui
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import { Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControlLabel } from '@mui/material';

// Formik
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

//Components
import UserService from '../../components/users/user.service';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import TenantsService from '../tenants/tenants.service';
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import { roles } from '../../utils/roles.js';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function UserInviteComponent(props) {
    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ loading: false });
    const [sitesData, setSitesData] = useState([]);
    const [sites, setSites] = useState([]);
    const [tenantsSelected, setTenantsSelected] = useState([]);
    const [checkedSiteBySubject, setCheckedSiteBySubject] = useState([]);
    const [checkedSiteIds, setCheckedSiteIds] = useState([]);
    const [checkedSiteOptions, setCheckedSiteOptions] = useState([]);
    const [checkedTenantIds, setCheckedTenantIds] = useState([]);
    const [tenantIdsString, setTenantIdsString] = useState([]);
    const [checkedTenantOptions, setCheckedTenantOptions] = useState([]);
    const [selectSiteWithReference, setSelectSiteWithReference] = useState([]);
    const [originalCheckedSites, setOriginalCheckedSites] = useState([]);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [emailValue, setEmailValue] = useState([]);


    const user = useSelector(state => state.auth.user)

    const handleOpen = () => {
        getSitesData();
        getCheckedSitesBySubject(user.profile.sub)
        setOpen(true);
    };

    async function getSitesData() {
        let sitesData = await UserService.getAllSites();
        setSitesData(sitesData.items);
    }

    async function getSiteByTenantId(tenantIds) {
        const tenantIdsStr = tenantIds.map(id => id.toString());
        let filteredSites = sitesData.filter(site => tenantIdsStr.includes(site.Reference));
        setSites(filteredSites);
    }
    async function getCheckedSitesBySubject(subject) {
        let selectedCheckedCustomers = await UserService.getCheckedSitesByTenantIds(subject);
        setCheckedSiteBySubject(selectedCheckedCustomers.items);
        setCheckedSiteOptions(selectedCheckedCustomers.items);
        setOriginalCheckedSites(selectedCheckedCustomers.items);
        setCheckedSiteIds(selectedCheckedCustomers.items.map(a => a.Value));
    }
    async function getCheckedSitesByTenantIds(tenantIds) {
        let sites = checkedSiteBySubject.filter(a => tenantIds.includes(a.Reference));
        setCheckedSiteOptions(sites);
        setCheckedSiteIds(sites.map(a => a.Value));
        let filteredSites = sites.filter(item => tenantIds.includes(item.Reference));
        tenantIds.forEach(id => {
            if (!filteredSites.some(site => site.Reference === id)) {
                filteredSites.push({ Text: '', Value: '', Reference: id });
            }
        });
        let sitesData = filteredSites;
        let siteIds = filteredSites.map(site => site.Value);
        getSiteChangeData(siteIds, sitesData, tenantIds)
    }
    async function getTenantAssignmemts(tenantIds) {
        let filteredSites = checkedSiteOptions.filter(a => tenantIds.includes(a.Reference));
        tenantIds.forEach(id => {
            if (!checkedSiteOptions.some(site => site.Reference === id)) {
                filteredSites.push({ Text: '', Value: '', Reference: id });
            }
        });
        let sites = filteredSites;
        let siteIds = filteredSites.map(site => site.Value);
        getSiteChangeData(siteIds, sites, tenantIds)
    }

    const handleSiteChange = (siteIds, sites) => {
        setCheckedSiteOptions(sites);
        getSiteChangeData(siteIds, sites, null);
    };

    const getSiteChangeData = async (siteIds, sites, tenantIds) => {
        const selectedSites = sites.filter(site => siteIds.includes(site.Value));
        const tenants = tenantIds ?? checkedTenantIds.map(id => id.toString());

        const tenantAssignments = [];
        selectedSites.forEach(site => {
            const tenantId = site.Reference;
            const siteId = site.Value;
            let tenantAssignment = tenantAssignments.find(ta => ta.TenantId === tenantId);
            if (!tenantAssignment) {
                tenantAssignment = {
                    TenantId: tenantId,
                    TenantSiteAssignments: []
                };
                tenantAssignments.push(tenantAssignment);
            }
            if (siteId != '') {

                tenantAssignment.TenantSiteAssignments.push({
                    SiteId: siteId,
                    TenantId: tenantId
                });
            }
        });

        tenants.forEach(tenantId => {
            let tenantAssignment = tenantAssignments.find(ta => ta.TenantId === tenantId);
            if (!tenantAssignment) {
                tenantAssignment = {
                    TenantId: tenantId,
                    TenantSiteAssignments: []
                };
                tenantAssignments.push(tenantAssignment);
            }
        });
        setSelectSiteWithReference(tenantAssignments);
    };

    useEffect(() => {
        async function constructor() {
            await setData();
        }
        constructor();
    }, []);

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function setData() {
        let tenants = await TenantsService.getAllTenantsDropdown('asc', '', '');
        setState({ tenants: tenants });
    }

    const handleClose = () => {
        setOpen(false);
        setTenantsSelected([]);
        setSites([]);
        setSelectSiteWithReference([]);
        setTenantIdsString([]);
        setCheckedTenantOptions([]);
        setTwoFactorEnabled(false);
        setEmailValue([]);

    };
    const onTwoFactorEnabledChange = (e) => {
        setTwoFactorEnabled(e.target.checked),
            getSiteChangeData(checkedSiteIds, sites)
    }

    return (
        <div>
            <IconButton onClick={() => handleOpen()} variant='contained' color='primary' data-testid='user-invite-button'>
                <Tooltip title={'Invite a user'}>
                    <ContactMailIcon />
                </Tooltip>
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            email: emailValue,
                            tenantId: tenantIdsString,
                            tenantsSelected: checkedTenantOptions,
                            twoFactorEnabled: twoFactorEnabled,
                            role: roles.User,
                            sitesSelected: checkedSiteOptions,
                            siteIds: checkedSiteIds 
                        }}
                        validationSchema={Yup.object().shape({
                            tenantId: Yup.string()
                                .required('Select Customers'),                    
                            email: Yup.string().test(
                                function (emailValue) { 
                                    if (emailValue != undefined) {
                                        const currentUserEmail = user?.profile.email;
                                        const currentUserAtIndex = currentUserEmail.indexOf('@');
                                        const currentUserDotIndex = currentUserEmail.length;
                                        const currentUserEmailDomain = currentUserEmail.substring(currentUserAtIndex, currentUserDotIndex);
                                        if (user?.profile.nvrclient_access === roles.Administrator) {
                                            return emailValue.includes(currentUserEmailDomain);
                                        }
                                        else {
                                            return true;
                                        }
                                    }

                                }
                            ).required('Email is required'),
                            sitesSelected: Yup.array()
                                .test(
                                    function () {
                                        if (user?.profile.nvrclient_access !== roles.Administrator) {
                                            return true;
                                        }
                                        if (selectSiteWithReference.length > 0 && user?.profile.nvrclient_access === roles.Administrator) {
                                            const tenantWithoutSites = selectSiteWithReference.some(tenant => {
                                                const correspondingSites = originalCheckedSites.filter(site => site.Reference === tenant.TenantId);
                                                return correspondingSites.length > 0 && tenant.TenantSiteAssignments.length === 0;
                                            });
                                            if (tenantWithoutSites) {
                                                return false;
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                    }
                                ).required('Each tenant must have at least one site.'),                         
                        })}
                        onSubmit={async (values) => {
                            setState({ users: state.users, tenants: state.tenants, userRole: user?.profile.nvrclient_access });
                            if (values.id === null || values.id === undefined) {
                                values.Subject = user.profile.sub;
                                let sentResponse = await UserService.SendUserInvite(values, selectSiteWithReference);
                                if (sentResponse === 'ok')
                                    props.snackbarShowMessage('The invite has been sent. ', 'success', 4000);
                                else
                                    props.snackbarShowMessage(sentResponse, 'warning', 4000);

                                await sleep(2000);
                                handleClose();
                            }
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            handleBlur,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <Form id='user_invite_form'>
                                <AppDialogTitle classes={classes} onClose={handleClose}><span className={'float-left'}>Invite</span></AppDialogTitle>
                                <AppDialogContent dividers>
                                    <div>
                                        <TextField
                                            autoFocus
                                            variant='outlined'
                                            label='Email'
                                            name='email'
                                            id='email'
                                            data-testid='user-invite-email'
                                            onChange={(e) => {
                                                setFieldValue('email', e.target.value);
                                                setEmailValue(e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            error={touched.email && Boolean(errors.email)}
                                            fullWidth
                                        />
                                    </div>
                                    <p ></p>
                                    <FormControl variant='outlined' className={classes.formControl} fullWidth>
                                        <Autocomplete
                                            multiple
                                            data-testid='user-customer-dropdown'
                                            options={state.tenants || []}
                                            getOptionLabel={(option) => option.Text}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.DisplayText}
                                                </li>
                                            )}
                                            value={values.tenantsSelected || []}
                                            onChange={async (e, value) => {
                                                setFieldValue('tenantsSelected', value || []);
                                                let ids = value.map(item => item.Value).join(',');
                                                setFieldValue('tenantId', ids);
                                                setTenantIdsString(ids);
                                                const selectedTenantIds = value.map(v => v.Value);
                                                await getSiteByTenantId(selectedTenantIds);
                                                setCheckedTenantOptions(value);
                                                setCheckedTenantIds(selectedTenantIds);
                                                setTenantsSelected(value || []);
                                                getTenantAssignmemts(selectedTenantIds);
                                                getCheckedSitesByTenantIds(selectedTenantIds);
                                            }}

                                            disableCloseOnSelect
                                            isOptionEqualToValue={(option, value) => option.Value === value.Value}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={touched.tenantId && Boolean(errors.tenantId)}
                                                    helperText={touched.tenantId && errors.tenantId}
                                                    label="Customers"
                                                    placeholder="Customers"
                                                />
                                            )}
                                        />
                                        {tenantsSelected.length > 0 && (
                                            <Autocomplete
                                                name="siteOptions"
                                                multiple
                                                options={sites || []}
                                                disableCloseOnSelect
                                                value={values.sitesSelected}
                                                getOptionLabel={(option) => option.Text || ''}
                                                isOptionEqualToValue={(option, value) => option.Value === value.Value}
                                                onChange={(event, value) => {
                                                    let selectedSitesIds = value.map(site => site.Value);
                                                    setFieldValue('sitesSelected', value || []);
                                                    setFieldValue('siteIds', selectedSitesIds);
                                                    setFieldValue('tenantsSelected', checkedTenantOptions);
                                                    setCheckedSiteOptions(value);
                                                    handleSiteChange(selectedSitesIds, value);
                                                }}
                                                fullWidth
                                                style={{ marginTop: '1rem' }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            checked={selected} />
                                                        {option.Text}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={touched.sitesSelected && Boolean(errors.sitesSelected)}
                                                        name="Sites"
                                                        label="Sites"
                                                        variant='outlined'
                                                        placeholder="Sites"
                                                        data-testid='user-edit-site-dropdown'
                                                    />
                                                )}
                                            />
                                        )}
                                    </FormControl>
                                    {
                                        (user?.profile.nvrclient_access === roles.WCCTV) ?
                                            (
                                                <div>
                                                    <p></p>
                                                    <FormControl variant='outlined' className={classes.formControl} fullWidth>
                                                        <InputLabel id='role-select-label'>Role</InputLabel>
                                                        <Select
                                                            id='role'
                                                            {...props}
                                                            name='role'
                                                            fullWidth
                                                            labelId='role-select-label'
                                                            data-testid='userinvite-role-dropdown'
                                                            error={touched.role && Boolean(errors.role)}
                                                            value={values.role ? values.role : ''}
                                                            onChange={(e) => {
                                                                let role = e.target.value;
                                                                setFieldValue('role', role);
                                                            }}
                                                            label='Role'
                                                        >
                                                            <MenuItem value={roles.WCCTV}>{roles.WCCTV}</MenuItem>
                                                            <MenuItem value={roles.Administrator}>{roles.Administrator}</MenuItem>
                                                            <MenuItem value={roles.User}>{roles.User}</MenuItem>
                                                            {(user?.profile.nvrclient_access === roles.WCCTV) && (
                                                                <MenuItem value={roles.Production}>{roles.Production}</MenuItem>
                                                            )}
                                                        </Select>

                                                    </FormControl>
                                                </div>
                                            ) : null
                                    }
                                    <FormControlLabel control={<Checkbox checked={values.twoFactorEnabled} name="twoFactorEnabled" onChange={(e) => (setFieldValue('twoFactorEnabled', e.target.checked), onTwoFactorEnabledChange(e))} />} label="Two Factor Enabled" />
                                </AppDialogContent>
                                <AppDialogActions>
                                    <Button
                                        type='submit'
                                        color='primary'
                                        variant='contained'
                                        data-testid='user-send-invite-button'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Send Invite'}
                                    </Button>
                                </AppDialogActions>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Dialog>
        </div>
    );
}
export default withSnackbar(UserInviteComponent);

UserInviteComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    btnFunction: PropTypes.func,
};