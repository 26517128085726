// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Mui
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import ArchiveIcon from '@mui/icons-material/Archive';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Services
import SitesService from '../sites/sites.service';
import { stableData, getComparator } from '../../helpers/Sorting';

// Components
import TransmitterRecordComponent from '../../components/transmitters/transmitter.component';
import TransmittersService from '../../components/transmitters/transmitters.service';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import AreYouSure from '../../ui/molecules/AreYouSure';
import FilterComponent from '../../ui/molecules/FilterComponent';
import TransmitterFirmwareComponent from '../../components/transmitterfirmware/transmitterfirmware.component';

const headCells = [
    { id: 'transmitterName', numeric: false, disablePadding: false, label: 'Full Transmitter Name', filterBy: false, Text: 'TransmitterName' },
    { id: 'webSocketAddress', numeric: false, disablePadding: false, label: 'Web Socket Address', filterBy: false, Text: 'WebSocketAddress' },
    { id: 'site', numeric: false, disablePadding: false, label: 'Site', filterBy: true, Text: 'Site' },
    { id: 'customer', numeric: false, disablePadding: false, label: 'Customer', filterBy: true, Text: 'Customer' },
    { id: 'category', numeric: false, disablePadding: false, label: 'Category', filterBy: true, Text: 'Category' },
    { id: 'deviceName', numeric: false, disablePadding: false, label: 'Device Name', filterBy: false, Text: 'DeviceName' },
    { id: 'Firmware', numeric: false, disablePadding: false, label: 'Firmware', filterBy: false, Text: 'Firmware' },
    { id: 'GSMNumber', numeric: false, disablePadding: false, label: 'GSM', filterBy: false, Text: 'GSMNumber' },
    { id: 'RentalNumber', numeric: false, disablePadding: false, label: 'Rental', filterBy: false, Text: 'RentalNumber' },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black',
    },
    table: {
        minWidth: 750,
    },
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

/**A table component with a toolbar and edit functions */
function TransmittersComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ transmitters: [], sites: [] });
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('transmitterName');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState('');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentRowToDelete, setCurrentRowToDelete] = React.useState();
    const [isForceReload, setIsForceReload] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentRow, setCurrentRow] = React.useState(null);
    const [applyData, setApplyData] = React.useState([]);
    const [sourceDropdownData, setsourceDropdownData] = React.useState(headCells.filter(data => data.filterBy));
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
        setOpen(false);
    };
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    useEffect(() => {
        populateDropdownData();
    }, []);

    useEffect(() => {
        async function loadData() {
            await populateTransmitterData();
        }
        loadData();
    }, [isForceReload, page, rowsPerPage, order, orderBy, applyData]);

    async function populateTransmitterData() {
        setLoading(true);
        let rowData = {};
        let siteData = [];

        try {
            rowData = await TransmittersService.getTransmitters(applyData, page + 1, rowsPerPage, order, orderBy, search);
        } catch (err) {
            props.snackbarShowMessage('The transmitters could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ transmitters: [] });
            setLoading(false);
            return;
        }
        try {
            let siterowData = await SitesService.getSitesDropdown('asc');
            siteData = siterowData ? siterowData.items : [];
        } catch (err) {
            props.snackbarShowMessage('The sites could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ transmitters: rowData.items, sites: [] });
            setLoading(false);
            return;
        }

        setState({ transmitters: rowData.items, sites: siteData });
        setTotalPageCount(rowData.pageInfo.pageCount);
        setLoading(false);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'
        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setOpen(false);
    };
    async function handledeleteTransmitter() {
        setLoading(true);
        let deleted = await TransmittersService.deleleTransmitter(currentRowToDelete);
        if (deleted === 'ok') props.snackbarShowMessage('The transmitter has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The transmitter could not be deleted. ', 'error', 4000);
        populateTransmitterData();
        setLoading(false);
        setOpenDialog(false);
    }
    async function deleteTransmitter(event, row) {
        setOpenDialog(true);
        setCurrentRowToDelete(row);
    }

    async function handleChangePage(e, newPage) {
        setPage(newPage);
    }

    async function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    const buttonIcon = <Tooltip title={'Add a transmitter'}><AddIcon></AddIcon></Tooltip>

    /** searches from the available transmitters in the database */
    async function searchTransmitters(e) {
        const txt = e.target.value;
        const isBlank = (txt === null || txt === undefined || txt === '')

        if (isBlank || txt.length > 2) {
            setSearch(txt);
        }
    }

    const modal = (
        <TransmitterRecordComponent
            sites={state.sites}
            btnFunction={populateTransmitterData}
            buttonIcon={buttonIcon}
        />
    );

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }
    async function populateDropdownData() {
        let rowData = await TransmittersService.getTransmitterInfoData();
        const dropdownData = sourceDropdownData.map(data => {
            if (data.id === 'site') {
                data.dropdown = rowData.SiteDropdown
            }
            if (data.id == 'customer') {
                data.dropdown = rowData.CustomerDropdown
            }
            if (data.id == 'category') {
                data.dropdown = rowData.CategoryDropdown
            }
            return data;
        });

        setsourceDropdownData(dropdownData);
    }
    async function publishGSMMessage(transmitterId) {
        await TransmittersService.postGSMMessage(transmitterId);
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.transmitters.length - page * rowsPerPage);
    return (
        <div>
            <div className={classes.root}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <FilterComponent sourcedropdownText={sourceDropdownData} setApply={setApplyData} filterList={applyData} setPage={setPage}>
                    </FilterComponent>
                </div>
            </div>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <EnhancedTableToolbar
                        hasAddButton={true}
                        modal={modal}
                        searchPlaceholder={'Search transmitters'}
                        setSearch={handleSearch}
                        searchData={searchTransmitters}
                    />
                    <hr />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size={'small'}
                            aria-label='enhanced table'
                        >
                            <EnhancedTableHead
                                isRowEditable={true}
                                headCells={headCells}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.transmitters.length < 1 ? <TableRow><TableCell colSpan='11'>No transmitters were found. </TableCell></TableRow> : stableData(state.transmitters, getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const btndelete = <Tooltip title={'Remove this transmitter'}><IconButton onClick={(event) => deleteTransmitter(event, row)} variant='contained' color='primary'><DeleteIcon /></IconButton></Tooltip>
                                        const buttonIcon = <Tooltip title={'Edit this transmitter'}><EditIcon></EditIcon></Tooltip>
                                        const buttonFirmware = <span className={classes.link}><IconButton><ArchiveIcon color='primary' /></IconButton><span className={classes.link}>Send Firmware</span></span>
                                        return (
                                            <TableRow
                                                hover
                                                role='checkbox'
                                                tabIndex={-1}
                                                key={row.Id}
                                            >
                                                <TableCell component='th' id={labelId} scope='row' align='left'>
                                                    {row.TransmitterName}
                                                </TableCell>
                                                <TableCell align='left'>{row.WebSocketAddress}</TableCell>
                                                <TableCell align='left'>{row.Site}</TableCell>
                                                <TableCell align='left'>{row.Customer}</TableCell>
                                                <TableCell align='left'>{row.Category}</TableCell>
                                                <TableCell align='left'>{row.DeviceName}</TableCell>
                                                <TableCell align='left'>{row.FirmwareVersion}</TableCell>
                                                <TableCell align='left'>{row.GSMNumber}</TableCell>
                                                <TableCell align='left'>{row.RentalNumber}</TableCell>
                                                <TableCell align='right' id={labelId} scope='row'>
                                                    <ButtonGroup >
                                                        {btndelete}
                                                        <TransmitterRecordComponent
                                                            item={row}
                                                            snackbarShowMessage={props.snackbarShowMessage}
                                                            sites={state.sites}
                                                            btnFunction={populateTransmitterData}
                                                            buttonIcon={buttonIcon}
                                                        />

                                                        <IconButton
                                                            keepMounted
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={open ? 'long-menu' : undefined}
                                                            aria-expanded={open ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleClick(e, row)}
                                                        >
                                                            <MoreVertIcon color='primary' />
                                                        </IconButton>
                                                        <Menu
                                                            keepMounted
                                                            anchorEl={anchorEl}
                                                            onClose={() => handleClose()}
                                                            open={currentRow === row}
                                                        >
                                                            <MenuItem onClick={() => { publishGSMMessage(row.Id); handleClose(); }}>
                                                                <IconButton aria-label='gsm reboot' color='primary' >
                                                                    <MessageIcon />
                                                                </IconButton>
                                                                <span className={classes.link}>GSM Reboot &nbsp;</span>
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <Link className={classes.link} >
                                                                    <IconButton aria-label='send settings' color='primary' >
                                                                        <DisplaySettingsIcon />
                                                                    </IconButton>
                                                                    <span className={classes.link}>Send Settings &nbsp;</span>
                                                                </Link>
                                                            </MenuItem>
                                                            <MenuItem onClick={() => { handleClose(); }}>
                                                                <TransmitterFirmwareComponent
                                                                    item={row}
                                                                    buttonIcon={buttonFirmware}
                                                                    snackbarShowMessage={props.snackbarShowMessage}
                                                                    btnFunction={populateTransmitterData}
                                                                />
                                                            </MenuItem>
                                                        </Menu>
                                                    </ButtonGroup>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 0 }}>
                                        <TableCell style={{ border: 'none' }} colSpan={11} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={totalPageCount * rowsPerPage}
                        disabled={false}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, params) => {
                            handleChangePage(event, params);
                        }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                {openDialog ?
                    <AreYouSure isOpenDialog={openDialog} closeDialog={handleCloseDialog} titleText='Delete Transmitter' contentText={<span>Are you sure you want to delete transmitter {currentRowToDelete.TransmitterName}?</span>} onYesClick={handledeleteTransmitter} > </AreYouSure>
                    : null}
            </div>
        </div >
    );
}
export default withSnackbar(TransmittersComponent);

TransmittersComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};