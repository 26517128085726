// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Components
import useAppStyles from '../atoms/AppDialogStyles';

function AppDialogTitle(props) {
    const { children, onClose, ...other } = props;
    const classes = useAppStyles();
    return (
        <MuiDialogTitle className={classes.root} {...other} style={{ width: 'auto', minWidth: '552px' }} data-testid="dialog-title">
            <Grid container spacing={0} padding={0} >
                <Grid item xs={onClose ? 10 : 12} data-testid="grid-item-1">
                    <div >
                        {children}
                    </div>
                </Grid>
                {onClose && (
                    <Grid item xs={2} data-testid="grid-item-2">
                        <div style={{ padding: '5px' }}>
                            <IconButton
                                edge='end'
                                aria-label='close'
                                className={classes.closeButton}
                                onClick={onClose}
                                data-testid="close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </Grid>
                )}
            </Grid>
        </MuiDialogTitle>
    );
}

export default AppDialogTitle;

AppDialogTitle.propTypes = {
    children: PropTypes.object,
    onClose: PropTypes.func
};