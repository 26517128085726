// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Mui
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

// Formik
import { Formik } from 'formik';
let yup = require('yup');

// Services
import SiteService from '../../components/sites/site.service';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function SiteRecordComponent(props) {
    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ item: {}, tenants: [] });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));


    useEffect(() => {
        setState({ item: props.item, tenants: props.tenants });
    }, [props.item, props.tenants]);

    let addSiteSchema = yup.object().shape({
        siteName: yup.string()
            .min(3, 'Must be at least 5 characters')
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
        tenantId: yup.string()
            .required('Select Customer')
    });

    const tenants = props.tenants;
    const hasOnlyOneTenant = tenants && tenants.length === 1;
    const isFirstTenantId = hasOnlyOneTenant ? tenants[0].Value : 0;
    const initialTenant = hasOnlyOneTenant ? {
        Text: tenants[0].Text,
        Value: tenants[0].Value,
        DisplayText: tenants[0].DisplayText
    } : {};

    return (
        <div>
            <IconButton onClick={() => handleClickOpen()} variant='contained' color='primary'>
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    initialValues={{
                        id: state.item?.Id,
                        siteName: state.item?.SiteName,
                        tenantId: state.item?.TenantId || (hasOnlyOneTenant ? isFirstTenantId : []),
                        tenant: state.item?.Tenant ? { Text: state.item?.Tenant, Value: state.item?.TenantId, DisplayText: state.item?.Tenant ? ((state.item?.Tenant) + ', ' + (state.item?.CategoryName)) : '' } : initialTenant
                    }}
                    validationSchema={addSiteSchema}
                    onSubmit={async (values) => {
                        let reload = false;
                        setState({ item: props.item, tenants: props.tenants });
                        if (values.id === undefined) {
                            try {
                                let added = await SiteService.insertSite(values);
                                if (added === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The site has been added. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue adding the site. ', 'warning', 4000);
                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the site. ', 'danger', 4000);
                            }
                        }
                        else {
                            try {
                                let updated = await SiteService.updateSite(values);
                                if (updated === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The site has been updated. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue updating the site. ', 'warning', 4000);

                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the site. ', 'danger', 4000);
                            }
                        }
                        await sleep(2000);
                        setState({ item: props.item, tenants: props.tenants });
                        if (reload === true) props.btnFunction();
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span className={'float-left'}>Add Site</span> : <span className={'float-left'}>Edit Site: {state.item.SiteName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Site Name'
                                    name='siteName'
                                    value={values.siteName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.siteName && Boolean(errors.siteName)}
                                    fullWidth
                                />

                                    <Autocomplete
                                        name='tenants'
                                        options={tenants}
                                        getOptionLabel={group => group.DisplayText ?? ''}
                                        getOptionSelected={(option, value) => option.Value === value.Value}
                                        value={values.tenant}
                                        onChange={(e, value) => {
                                            setFieldValue('tenantId', value !== null ? value.Value : '');
                                            setFieldValue('tenant', value !== null ? { Text: value.Text, Value: value.Value, DisplayText: value.DisplayText } : {})
                                        }}
                                        fullWidth
                                        disabled={tenants.length === 1}
                                        style={{ marginTop: '1rem' }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label='Customer'
                                                name='tenantId'
                                                variant='outlined'
                                                value={values.tenantId}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.tenantId && Boolean(errors.tenantId)}
                                            />
                                        )}
                                    />
                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                    data-testid='save-site-button'
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
}
export default withSnackbar(SiteRecordComponent);

SiteRecordComponent.propTypes = {
    item: PropTypes.object,
    tenants: PropTypes.array,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func,
    snackbarShowMessage: PropTypes.func
};