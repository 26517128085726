// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

// Formik

// Services
import TenantsService from '../../components/tenants/tenants.service';
import { stableData, getComparator } from '../../helpers/Sorting';

// Components
import TenantRecordComponent from '../../components/tenants/tenant.component';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import CategoriesService from '../categories/categories.service';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';

const headCells = [
    { id: 'tenantName', numeric: false, disablePadding: false, label: 'Full Customer Name' },
    { id: 'category', numeric: false, disablePadding: false, label: 'Category' }
];



const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black'
    },
    table: {
        minWidth: 750,
    },
}));


/**A table component with a toolbar and edit functions */
function TenantsComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ tenants: [], categories: [] });
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('tenantName');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState('');
    const [isForceReload, setIsForceReload] = React.useState(false);

    useEffect(() => {
        async function loadData() {
            await populateTenantData();
        }
        loadData();
    }, [isForceReload, page, rowsPerPage, order, orderBy]);

    async function populateTenantData() {
        setLoading(true);
        let rowData = {};
        let categoryrowData = [];

        try {
            rowData = await TenantsService.getTenants(page + 1, rowsPerPage, order, orderBy, search);
        } catch (err) {
            props.snackbarShowMessage('The customers could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ tenants: [] });
            setLoading(false);
            return;
        }
        try {
            categoryrowData = await CategoriesService.getCategoriesDropdown('asc', null, null);
        } catch (err) {
            props.snackbarShowMessage('The categories could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ tenants: state.tenants, categories: [] });
            setLoading(false);
            return;
        }
        setState({ tenants: rowData.items, categories: categoryrowData.items });
        setTotalPageCount(rowData.pageInfo.pageCount);
        setLoading(false);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    async function deleteTenant(event, row) {
        setLoading(true);

        let deleted = await TenantsService.deleteTenant(row);
        if (deleted === 'ok') props.snackbarShowMessage('The customer has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The customer could not be deleted. ', 'error', 4000);
        populateTenantData();
        setLoading(false);
    }

    async function handleChangePage(e, newPage) {
        setPage(newPage);
    }

    async function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }
    const buttonIcon = <Tooltip title={'Add a customer'}><AddIcon data-testid="tenant-add-button"></AddIcon></Tooltip>

    const modal = (
        <TenantRecordComponent
            categories={state.categories}
            btnFunction={populateTenantData}
            buttonIcon={buttonIcon}
        />
    );

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.tenants.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    hasAddButton={true}
                    modal={modal}
                    searchPlaceholder={'Search customers...'}
                    setSearch={handleSearch}
                />
                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.tenants.length < 1 ? <TableRow><TableCell colSpan='4'>No customers were found. </TableCell></TableRow> : stableData(state.tenants, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btndelete = <Tooltip title={'Remove this customer'}><IconButton onClick={(event) => deleteTenant(event, row)} variant='contained' color='primary' data-testid="tenant-delete-button"><DeleteIcon /></IconButton></Tooltip>
                                    const buttonIcon = <Tooltip title='Edit this customer' data-testid='tenant-edit-button'><EditIcon></EditIcon></Tooltip>

                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component='th' id={labelId} scope='row' align='left' data-testid='tenant-name'>
                                                {row.TenantName}
                                            </TableCell>
                                            <TableCell align='left'>{row.Category}</TableCell>
                                            <TableCell align='right' >
                                                <ButtonGroup >
                                                    {btndelete}
                                                    <TenantRecordComponent
                                                        item={row}
                                                        snackbarShowMessage={props.snackbarShowMessage}
                                                        categories={state.categories}
                                                        btnFunction={populateTenantData}
                                                        buttonIcon={buttonIcon}
                                                    />
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell style={{ border: 'none' }} colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={totalPageCount * rowsPerPage}
                    slotProps={{
                        actions: {
                            nextButton: {
                                'data-testid': 'tenant-next-button',
                            },
                        },
                    }}
                    disabled={false}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, params) => {
                        handleChangePage(event, params);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

        </div>
    );
}
export default withSnackbar(TenantsComponent);

TenantsComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};