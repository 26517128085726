// Services
import Http from '../../services/http';


export default class TenantsService {
    static async getTenants(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        const tenants = await Http.getData(process.env.REACT_APP_NVR_API + 'api/tenants?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text))).catch(error => console.error(error))
        return tenants;
    }

    static async getAllTenantsDropdown(order, orderBy, text) {
        let data = [];
        let dropdown = await Http.getData(process.env.REACT_APP_NVR_API + 'api/tenants/full-dropdown?order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text))).catch(error => console.error(error));
        if (dropdown) return dropdown.items;
        else return data;
    }
    
    static async deleteTenant(row) {
        let data = {
            id: row.Id
        };
        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/tenants/delete', data).catch(error => console.error(error));
    }
}
