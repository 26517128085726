// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';

// Services

// Components
import MultiViewsService from './multiviews.service';
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AlertDialog from '../../ui/atoms/AlertDialog';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import CamerasDropdownComponent from './cameras.component';
import MultiViewGrid from './multiviewgrid.component';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * Constructs the multiview component
 * @param {any} props
 */
function MultiViewCustomComponent(props) {
    const classes = useAppStyles();
    const defaultColumns = 3;
    const defaultRows = 3;
    const { defaultMultiview, populateMultiViewData, setMultiViewDesignerOpen, setChangesMade } = props;
    const defaultCols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };
    const [state, setState] = useState({
        cameraData: { items: [] },
        loading: false,
        multiviewName: null,
        columns: defaultMultiview ? defaultMultiview.Columns : defaultColumns,
        rows: defaultMultiview ? defaultMultiview.Rows : defaultRows,
        cols: {},
        multiViewItems: defaultMultiview ? defaultMultiview.Items : []
    });
    const [validation, setValidation] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(0);
    const [selectedColumn, setSelectedColumn] = useState(0);

    useEffect(async () => {
        await constructor();
    }, []);

    async function constructor() {
        const items = defaultMultiview ? defaultMultiview.Items : [];

        setState(prevState => ({
            ...prevState,
            isSubmitting: false,
            loading: false,
            multiViewId: defaultMultiview ? defaultMultiview.Id : null,
            multiviewName: defaultMultiview ? defaultMultiview.Name : null,
            cols: defaultCols,
            multiViewItems: items,
        }));

        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), items);
        setState(prevState => ({
            ...prevState,
            cameraData: {
                items: cameraData
            },
        }));
    }

    /**
     * Process the available cameras against the list of multiview items 
     * @param {any} camerasData
     * @param {any} gridItems
     */
    async function getCameraData(camerasData, gridItems) {
        let CameraData = [];
        if (camerasData.items) {
            if (gridItems) {
                for (let j = 0; j < camerasData.items.length; j++) {
                    let found = false;
                    for (let i = 0; i < gridItems.length; i++) {
                        if (gridItems[i].TransmitterCameraId === camerasData.items[j].Id) {
                            found = true;
                        }
                    }
                    if (!found) {
                        CameraData[CameraData.length] = camerasData.items[j];
                    }
                }
            } else {
                CameraData = camerasData.items;
            }
        }

        return CameraData;
    }
    function handleClose() {
        setIsOpen(false);
    }

    function handleChange(e) {
        setState({
            multiviewName: e.target.value,
            multiViewId: state.multiViewId,
            cameraData: state.cameraData,
            isSubmitting: false,
            cols: defaultCols,
            columns: state.columns,
            rows: state.rows,
            multiViewItems: state.multiViewItems,
        });
        setChangesMade(true);
    }

    function getCamera(value) {
        let cam;
        for (let i = 0; i < state.cameraData.items.length; i++) {
            if (state.cameraData.items[i].Id === value) {
                cam = state.cameraData.items[i];
            }
        }
        return cam;
    }

    function removeExistingItem(gridCellsList, col, row) {
        return gridCellsList.filter(item => item.Row !== row || item.Column !== col);
    }

    async function handleCameraSelection(value) {
        const row = selectedRow;
        const Column = selectedColumn;
        const cam = getCamera(value);
        let gridCellsList = state.multiViewItems;

        gridCellsList = removeExistingItem(gridCellsList, Column, row);

        gridCellsList[gridCellsList.length] = {
            CameraNumber: cam.CameraNumber,
            TransmitterId: cam.TransmitterId,
            TransmitterName: cam.TransmitterName,
            TransmitterCameraId: cam.Id,
            CameraName: cam ? cam.CameraName : 'N/A',
            Column: Column,
            Row: row
        }

        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), gridCellsList);
        setState({
            multiViewId: state.multiViewId,
            multiviewName: state.multiviewName,
            cameraData: {
                items: cameraData
            },
            cols: defaultCols,
            multiViewItems: gridCellsList,
            isSubmitting: false,
            loading: false,
            layout: state.layout,
            columns: state.columns,
            rows: state.rows,
        });
        setChangesMade(true);
    }

    async function handleSave() {
        setState({
            multiViewId: state.multiViewId,
            multiviewName: state.multiviewName,
            cameraData: state.cameraData,
            cols: state.cols,
            multiViewItems: state.multiViewItems,
            loading: false,
            isSubmitting: true,
            layout: state.layout,
            columns: state.columns,
            rows: state.rows,
        });
        if (!state.multiviewName) {
            setValidation({ valid: false, message: 'Please enter a name. ', messageSeen: false });
            return;
        }
        if (!state.multiViewItems || state.multiViewItems.length < 1) {
            setValidation({ valid: false, message: 'Please select at least one camera. ', messageSeen: false });
            return;
        }
        if (await MultiViewsService.maintainMultiView(state.multiViewId, state.multiviewName, state.columns, state.rows, state.multiViewItems)) {
            props.snackbarShowMessage('The view has been updated. ', 'success', 4000);
        } else {
            props.snackbarShowMessage('The view could not be updated. ', 'warning', 4000);
        }
        await sleep(500);

        await populateMultiViewData();
        setMultiViewDesignerOpen(false);

        handleClose();
    }

    function handleClickOpen(e) {
        const id = parseInt(e.target.dataset.index) + 1;
        if (!id) return;
        const row = parseInt(e.target.dataset.row) + 1;
        if (!row) return;
        const column = parseInt(e.target.dataset.column) + 1;
        if (!column) return;
        setSelectedColumn(column);
        setSelectedRow(row);
        setIsOpen(true);
    }

    async function handleRemoveItem(value) {
        let multiViewItems = [];
        for (let i = 0; i < state.multiViewItems.length; i++) {
            if (state.multiViewItems[i].TransmitterCameraId !== value) {
                multiViewItems[multiViewItems.length] = state.multiViewItems[i];
            }
        }

        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), multiViewItems);
        setState({
            multiViewId: state.multiViewId,
            multiviewName: state.multiviewName,
            cameraData: {
                items: cameraData
            },
            cols: defaultCols,
            multiViewItems: multiViewItems,
            loading: false,
            layout: state.layout,
            columns: state.columns,
            rows: state.rows,
        });
        setChangesMade(true);
    }

    async function handleGridRowsChange(value) {
        let selected = parseInt(value);
        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), []);

        let newState = {
            cameraData: {
                items: cameraData
            },
            loading: false,
            multiViewId: state.multiViewId,
            multiviewName: state.multiviewName,
            cols: selected,
            columns: state.columns,
            rows: selected,
            multiViewItems: state.multiViewItems
                ? state.multiViewItems.filter(item =>
                    item.Column <= state.columns && item.Row <= selected
                ) : []
        };

        setState(newState);
        setChangesMade(true);
    }

    async function handleGridColumnsChange(value) {
        let selected = parseInt(value);
        const cameraData = await getCameraData(await MultiViewsService.getTransmitterCameras(), []);
        let newState = {
            cameraData: {
                items: cameraData
            },
            loading: false,
            multiViewId: state.multiViewId,
            multiviewName: state.multiviewName,
            cols: defaultCols,
            columns: selected,
            rows: state.rows,
            multiViewItems: state.multiViewItems
                ? state.multiViewItems.filter(item =>
                    item.Column <= selected && item.Row <= state.rows
                ) : []
        };

        setState(newState);
        setChangesMade(true);
    }

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    return (
        <div style={{ border: '1px solid black', paddingBottom: '30px' }}>
            <Grid container>
                <Grid item xs={9}>
                    <Grid container>
                        <Grid item xs={12} padding={2}>
                            <FormControl variant='outlined' className={classes.formControl} fullWidth >
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    placeholder={'Name of the multiview'}
                                    name='multiviewName'
                                    value={state.multiviewName}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2} padding={2}>
                            <FormControl variant='outlined' className={classes.formControl} fullWidth >
                                <InputLabel id='gridSize-select-label'>Columns</InputLabel>
                                <Select
                                    id='gridSizeColumns'
                                    {...props}
                                    fullWidth
                                    name='layout'
                                    labelId='gridSize-select-label'
                                    value={state.columns}
                                    onChange={(e) => {
                                        let data = e.target.value;
                                        handleGridColumnsChange(data);
                                    }}
                                    label='Grid Size'
                                >
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                    <MenuItem value='6'>6</MenuItem>
                                    <MenuItem value='7'>7</MenuItem>
                                    <MenuItem value='8'>8</MenuItem>
                                    <MenuItem value='9'>9</MenuItem>
                                </Select>
                            </FormControl>
                            {(validation.valid === false && !validation.messageSeen) &&
                                <AlertDialog
                                    title={'Multi View Validation'}
                                    isOpen={true}
                                    validation={validation}
                                    setValidation={setValidation}
                                    message={validation.message}
                                />
                            }
                        </Grid>

                        <Grid item xs={12} md={2} padding={2}>
                            <FormControl variant='outlined' className={classes.formControl} fullWidth >
                                <InputLabel id='gridSize-select-label'>Rows</InputLabel>
                                <Select
                                    id='gridSizeRows'
                                    {...props}
                                    fullWidth
                                    name='layout'
                                    labelId='gridSize-select-label'
                                    value={state.rows}
                                    onChange={(e) => {
                                        let data = e.target.value;
                                        handleGridRowsChange(data);
                                    }}
                                    label='Grid Size'
                                >
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                    <MenuItem value='6'>6</MenuItem>
                                    <MenuItem value='7'>7</MenuItem>
                                    <MenuItem value='8'>8</MenuItem>
                                    <MenuItem value='9'>9</MenuItem>
                                </Select>
                            </FormControl>
                            {(validation.valid === false && !validation.messageSeen) &&
                                <AlertDialog
                                    title={'Multi View Validation'}
                                    isOpen={true}
                                    validation={validation}
                                    setValidation={setValidation}
                                    message={validation.message}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} padding={2}>
                    <Button
                        fullWidth
                        type='submit'
                        onClick={handleSave}
                        color='primary'
                        variant='contained'
                        disabled={state.isSubmitting}>
                        {state.isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}</Button>
                </Grid>
            </Grid>

            <CamerasDropdownComponent
                camerasData={state.cameraData}
                handleCameraSelection={handleCameraSelection}
                isOpen={isOpen}
                handleClose={handleClose}
            />
            <MultiViewGrid
                isDesignerMode={true}
                rows={state.rows}
                cols={state.columns}
                multiViewItems={state.multiViewItems}
                handleClickOpen={handleClickOpen}
                handleRemoveItem={handleRemoveItem}
            ></MultiViewGrid>
        </div>
    );
}

export default withSnackbar(MultiViewCustomComponent);

MultiViewCustomComponent.propTypes = {
    defaultMultiview: PropTypes.object,
    populateMultiViewData: PropTypes.func,
    setMultiViewDesignerOpen: PropTypes.func,
    snackbarShowMessage: PropTypes.func,
    setChangesMade: PropTypes.func
};
