// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Mui
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Popover from '@mui/material/Popover';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ArchiveIcon from '@mui/icons-material/Archive';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
} from '@mui/material';


const useStyles = makeStyles((theme) => {
    const whiteColor = 'white';

    return {
        notificationsButton: {
            marginLeft: 'auto',
            paddingBottom: '40px'
        },
        notificationsList: {
            width: '500px',
            padding: theme.spacing(2)
        },
        viewMoreButton: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(2)
        },
        link: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: 'none'
        },
        markAllAsReadGrid: {
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '15px'
        },
        clearAllGrid: {
            paddingTop: '15px'
        },
        closeIconButton: {
            position: 'absolute',
            top: '2px',
            right: '15px'
        },
        dot: {
            height: '40px',
            width: '40px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        notificationsIconColor: {
            color: whiteColor,
        },
        notificationBackground: {
            background: '#FFF1F0',
        },
        cameraIconColor: {
            color: whiteColor,
        }
    };
});


const initialVisibleNotifications = 5;
const step = 5;

function NotificationTray(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [currentVisibleNotifications, setCurrentVisibleNotifications] = useState(initialVisibleNotifications);
    const [visibleNotifications, setVisibleNotifications] = useState([]);
    const { messages } = props;

    useEffect(() => {
        const reversedMessages = [...messages].reverse();
        setVisibleNotifications(reversedMessages.slice(0, currentVisibleNotifications));
    }, [messages, currentVisibleNotifications]);

    const handleClick = (event) => {
        setCurrentVisibleNotifications(initialVisibleNotifications);
        setAnchorEl(event.currentTarget);
        if (visibleNotifications.length > 0) {
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(false);
    };

    const handleNotificationClick = (clickedNotification) => {
        if (!clickedNotification.isRead) {
            clickedNotification.isRead = true;
        }
        const updatedNotifications = messages.map(notification => {
            if (notification === clickedNotification) {
                return {
                    ...notification,
                    isRead: true
                };
            }
            return notification;
        });
        updatedNotifications.sort((a, b) => b.timestamp - a.timestamp);
        setVisibleNotifications(updatedNotifications.slice(0, currentVisibleNotifications));
    };

    const handleMarkAllAsRead = () => {
        messages?.forEach((notification) => (notification.isRead = true));
        handleClose();
    };

    const handleViewMore = () => {
        const newVisibleNotifications = messages?.slice(
            0,
            visibleNotifications.length + step
        );
        setVisibleNotifications([...newVisibleNotifications.reverse()]);
        setCurrentVisibleNotifications(newVisibleNotifications.length);
    };

    const checkIconClick = (clickedNotification) => {
        const updatedNotifications = messages.map(notification => {
            if (notification === clickedNotification) {
                return {
                    ...notification,
                    isRead: true
                };
            }
            return notification;
        });

        setVisibleNotifications(updatedNotifications)
    };
    const unreadNotificationsCount = messages?.filter((notification) => !notification.isRead).length;

    const handleRemoveAll = () => {
        setIsOpen(false);
        setVisibleNotifications([]);
        props.onClearAllNotifications(true);
    }

    return (
        <div>
            <IconButton className={classes.notificationsButton} onClick={handleClick}>
                <Badge
                    badgeContent={unreadNotificationsCount}
                    color='primary'
                    max={99}
                >
                    <NotificationsIcon className={classes.notificationsIconColor} />
                </Badge>
            </IconButton>
            {visibleNotifications.length > 0 && (
                <Popover
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <Grid container>
                        <Grid item xs={6} className={classes.clearAllGrid}>
                            <IconButton onClick={() => handleRemoveAll()}>
                                <Tooltip title={'Clear all'}>
                                    <ClearAllIcon />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} className={classes.markAllAsReadGrid}>
                            <Button onClick={handleMarkAllAsRead}>Mark All as Read</Button>
                        </Grid>
                    </Grid>
                    <List className={classes.notificationsList}>
                        {visibleNotifications?.map((notification, index) => (
                            <ListItem
                                key={index}
                                onClick={() => handleNotificationClick(notification)}
                                className={notification.isRead ? '' : classes.notificationBackground}
                            >
                                {notification.type == 'Footage' && (
                                    <Link className={classes.link} to={{ pathname: 'footage', state: { notificationId: notification.id, transmitterName: notification.transmitterName } }} >
                                        <ListItemAvatar>
                                            <div>
                                                <span className={classes.dot}>
                                                    <Tooltip title={'View Footage'}>
                                                        <VideoCameraBackIcon className={classes.cameraIconColor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                        </ListItemAvatar>
                                    </Link>
                                )}
                                {notification.type == 'GSMSent' && (
                                    <ListItemAvatar>
                                        <div>
                                            <span className={classes.dot}>
                                                <TextsmsIcon className={classes.cameraIconColor} />
                                            </span>
                                        </div>
                                    </ListItemAvatar>
                                )}
                                {notification.type == 'FirmwareUpdate' && (
                                    <ListItemAvatar>
                                        <div>
                                            <span className={classes.dot}>
                                                <ArchiveIcon className={classes.cameraIconColor} />
                                            </span>
                                        </div>
                                    </ListItemAvatar>
                                )}

                                <ListItemText
                                    primary={notification.text}
                                    secondary={moment(notification.timestamp).fromNow()}
                                />

                                {!notification.isRead && (
                                    <div className={classes.closeIconButton}>
                                        <CloseIcon onClick={() => checkIconClick(notification)} style={{ width: 15 }} />
                                    </div>
                                )}

                            </ListItem>
                        ))}
                    </List>
                    {visibleNotifications.length < messages.length && (
                        <div className={classes.viewMoreButton}>
                            <Button onClick={handleViewMore}>View More</Button>
                        </div>
                    )}
                </Popover>
            )}
        </div>
    )
}
export default NotificationTray;

NotificationTray.propTypes = {
    messages: PropTypes.array,
    onClearAllNotifications: PropTypes.func,
};

