// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

//Services
import AuditService from '../audits/audits.service.js'
import { auditNames } from '../../utils/auditNames';

// MUI
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import { Tooltip } from '@mui/material';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import MultiViewsService from '../../components/multiview/multiviews.service';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import { stableData, getComparator } from '../../helpers/Sorting';
import MultiViewCustomComponent from './multiview.component';
import MultiViewGrid from './multiviewgrid.component';
import AreYouSure from '../../ui/molecules/AreYouSure';

const headCells = [
    { id: 'live', numeric: false, disablePadding: false, label: 'LIVE' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Multiview Name' },
    { id: 'layout', numeric: false, disablePadding: false, label: 'Layout Size' },
    { id: 'items', numeric: false, disablePadding: false, label: 'Items' }
];

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        overflow: 'hidden'
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black',
        boxShadow: 0,
        boxSizing: 'border-box'
    },
    table: {
        minWidth: 750,
    },

}));


function MultiViewModal(props) {
    const [multiViewDesignerOpen, setMultiViewDesignerOpen] = React.useState(false);
    const [changesMade, setChangesMade] = React.useState(false);
    const classes = useStyles();
    const { gridView, populateMultiViewData } = props;

    const handleOpen = () => {

        if (gridView) {
            AuditService.insertAuditData(auditNames.ViewMultiview, 'View Multiview for record [' + gridView.Name + ']')
        }
        setMultiViewDesignerOpen(true)
    };

    const handleClose = () => {
        if (changesMade) {
            populateMultiViewData();
            props.snackbarShowMessage('The view has not been saved. ', 'warning', 4000);
        }
        setMultiViewDesignerOpen(false);
    }
    return (
        <>
            <Tooltip title={(!gridView) ? 'Add multiview' : 'Edit this multiview'}>
                <IconButton onClick={handleOpen} variant='contained' color='primary'>
                    {(gridView) &&
                        <EditIcon />
                    }
                    {(!gridView) &&
                        <AddIcon />
                    }
                </IconButton>
            </Tooltip>

            <Dialog
                fullScreen
                open={multiViewDesignerOpen}
            >
                <AppDialogTitle classes={classes} style={{ padding: '15px 0px' }} onClose={handleClose}>{(!gridView) ? <span style={{ marginLeft: '15px' }}>Add Multiview</span> : <span style={{ marginLeft: '15px' }}>Edit Multiview: {gridView.Name}</span>}</AppDialogTitle>
                <AppDialogContent dividers>
                    <MultiViewCustomComponent
                        setChangesMade={setChangesMade}
                        setMultiViewDesignerOpen={setMultiViewDesignerOpen}
                        populateMultiViewData={populateMultiViewData}
                        defaultMultiview={gridView}
                    />
                </AppDialogContent>

            </Dialog>

        </>
    );
}

MultiViewModal.propTypes = {
    gridView: PropTypes.object,
    populateMultiViewData: PropTypes.func,
    snackbarShowMessage: PropTypes.func
};

/**A table component with a toolbar and edit functions */
function MultiViewComponent(props) {
    const classes = useStyles();
    const location = useLocation();

    let pageNo = 0;
    let rowsPerPageCount = 5;

    if (location.state?.returnState?.multiviewsPage) {
        pageNo = location.state?.returnState?.multiviewsPage;
    }

    if (location.state?.returnState?.multiviewsRowsPerPage) {
        rowsPerPageCount = location.state?.returnState?.multiviewsRowsPerPage;
    }

    /** The page data */
    const [state, setState] = React.useState({ multiViews: [] });
    const [selectedMultiView, setSelectedMultiView] = React.useState(null);
    const [multiViewerOpen, setMultiViewerOpen] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(pageNo);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageCount);
    const [search, setSearch] = React.useState('');
    const [currentRowToDelete, setCurrentRowToDelete] = React.useState();
    const [isForceReload, setIsForceReload] = React.useState(false);
    const [multiviewIdToSelect, setMultiviewIdToSelect] = useState(null);

    function handleCloseConfirmation() {
        setOpenDialog(false);

    }
    function handleClose() {
        setSelectedMultiView(null);
        setMultiviewIdToSelect(-1);
        if (selectedMultiView && location.state) {
            location.state.returnState = null;
        }
        setMultiViewerOpen(false);
        setOpenDialog(false);

    }

    function cameraChanged(multiviewId) {
        setMultiviewIdToSelect(multiviewId);
        populateMultiViewData();
    }

    useEffect(() => {
        if (multiviewIdToSelect !== null) {
    
            handleViewSelect(multiviewIdToSelect);
            setMultiviewIdToSelect(null);
        }
    }, [state.multiViews]);

    useEffect(() => {
        populateMultiViewData();
    }, [isForceReload, page, rowsPerPage, order, orderBy]);

    useEffect(() => {
        if (location.state?.returnState?.selectedMultiView) {
            handleViewSelect(location.state?.returnState?.selectedMultiView);

            if (selectedMultiView) {
                location.state.returnState = null;
            }
        }
    }, [state]);

    /**
     * Handles the selection of a multiview
     * @param {any} value
     */
    function handleViewSelect(value) {
        if (state.multiViews.length == 0) {
            return;
        }

        let grid = state.multiViews.find(x => x.Id === value);
        setSelectedMultiView(grid);

        if (grid) {
            AuditService.insertAuditData(auditNames.ViewMultiview, 'View Multiview LIVE for record [' + grid.Name + ']')
        }
        setMultiViewerOpen(true);
    }

    async function populateMultiViewData() {
        setLoading(true);
        let rowData = {};
        /** an array of rows */
        try {
            rowData = await MultiViewsService.getMultiViews(page + 1, rowsPerPage, order, orderBy, search);
        } catch (err) {

            props.snackbarShowMessage('The multi view could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ multiViews: [] });
            setLoading(false);
            return;
        }
        if (rowData === null || rowData === undefined) {
            props.snackbarShowMessage('The multi view are not available. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ multiViews: [] });
            setLoading(false);
            return;
        }

        setState({ multiViews: rowData.items });
        setTotalPageCount((rowData.pageInfo === null || rowData.pageInfo === undefined) ? 1 : rowData.pageInfo.pageCount);
        setLoading(false);
    }

    /**
     * Handles the sort property
     * @param {any} event
     * @param {any} property
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    /**
     * Deletes the multiview
     */
    async function handleDeleteMultiView() {
        setLoading(true);
        let deleted = await MultiViewsService.deleteMultiView(currentRowToDelete);
        if (deleted === 'ok') props.snackbarShowMessage('The multiview has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The multiview could not be deleted. ', 'error', 4000);
        populateMultiViewData();
        setLoading(false);
        setOpenDialog(false);

    }

    async function deleteMultiView(event, row) {
        setOpenDialog(true);
        setCurrentRowToDelete(row);
    }


    /**
     * Changes the page
     * @param {any} e
     * @param {any} newPage
     */
    async function handleChangePage(e, newPage) {
        setLoading(true);
        setPage(newPage);

    }

    /**
     * Sets the amount of rows per page
     * @param {any} event
     */
    async function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);

    }
    function handleClickOpen() {
        // Check this -> Line 284
    }
    const modal = (
        <MultiViewModal
            snackbarShowMessage={props.snackbarShowMessage}
            populateMultiViewData={populateMultiViewData}
        />
    );

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.multiViews.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <EnhancedTableToolbar
                    hasAddButton={true}
                    modal={modal}
                    searchPlaceholder={'Search multiview...'}
                    setSearch={handleSearch}
                />
                {selectedMultiView &&
                    <Dialog
                        fullScreen
                        open={multiViewerOpen}
                    >
                        <AppDialogTitle classes={classes} style={{ boxSizing: 'border-box' }} onClose={handleClose}><span >{selectedMultiView.Name}</span></AppDialogTitle>
                        <AppDialogContent dividers
                            className={classes.dialogContent}
                        >
                            <MultiViewGrid
                                isDesignerMode={false}
                                rows={selectedMultiView.Rows}
                                cols={selectedMultiView.Columns}
                                multiViewItems={selectedMultiView.Items}
                                gridArray={selectedMultiView.gridArray}
                                handleClickOpen={handleClickOpen}
                                multiviewId={selectedMultiView.Id}
                                multiviewsPage={page}
                                multiviewsRowsPerPage={rowsPerPage}
                                updateMuliviewsCallback={cameraChanged}

                            ></MultiViewGrid>
                        </AppDialogContent>

                    </Dialog>


                }

                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.multiViews.length < 1 ? <TableRow><TableCell colSpan='5'>No multiview were found. </TableCell></TableRow> : stableData(state.multiViews, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btndelete = <Tooltip title={'Remove this multiview'}><IconButton onClick={(event) => deleteMultiView(event, row)} variant='contained' color='primary'><DeleteIcon /></IconButton></Tooltip>

                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component='th' id={labelId} scope='row' align='left'>
                                                <Tooltip title={'Show live multiview'}>
                                                    <IconButton variant='contained' color='primary' onClick={() => handleViewSelect(row.Id)}>
                                                        <BorderAllIcon color='primary' />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell component='th' id={labelId} scope='row' align='left'>{row.Name}</TableCell>
                                            <TableCell component='th' id={labelId} scope='row' align='left'>{row.LayoutName}</TableCell>
                                            <TableCell component='th' id={labelId} scope='row' align='left'>{row.Items.length}</TableCell>
                                            <TableCell align='right' >
                                                <ButtonGroup >
                                                    {btndelete}
                                                    <MultiViewModal
                                                        snackbarShowMessage={props.snackbarShowMessage}
                                                        populateMultiViewData={populateMultiViewData}
                                                        gridView={row}
                                                    />

                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell style={{ border: 'none' }} colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={totalPageCount * rowsPerPage}
                    disabled={false}
                    rowsPerPage={rowsPerPage}
                    page={(page)}
                    onPageChange={(event, params) => {
                        handleChangePage(event, params);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {openDialog ?
                <AreYouSure isOpenDialog={openDialog} closeDialog={handleCloseConfirmation} titleText='Delete Multiview' contentText={<span>Are you sure you want to delete the multiview {currentRowToDelete.Name}?</span>} onYesClick={handleDeleteMultiView} > </AreYouSure>
                : null}
        </div>
    );
}
export default withSnackbar(MultiViewComponent);

MultiViewComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    populateMultiViewData: PropTypes.func
};
