// React
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

// Services
import AuditsService from '../audits/audits.service';
import { getLocalDateUnix, getLocalDateTime, getUtcStartOfDate, getUtcEndOfDate, getLocalDateFormat } from '../../utils/dateFormatter';
import { auditNames } from '../../utils/auditNames';

// Components
import SensorHistoryService from '../../components/sensors/sensorhistory.service';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import { BasicBreadcrumbs } from '../../ui/molecules/BasicBreadcrumbs';
import ExportButton from '../../ui/atoms/ExcelExport';

// Giraffe
import { Plot, newTable, timeFormatter } from '@influxdata/giraffe'


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        height: 'calc(100vh - 210px)',
        width: '100%',
        marginBottom: 10
    },
    paperExport: {
        height: 'calc(100vh - 330px)',
        width: '100%',
        marginBottom: 10
    },
    table: {
        minWidth: 750,
    },
    breadcrumb: {
        paddingBottom: 10
    },
    div: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    datePicker: {
        '& .MuiTextField-root': {
            width: 200,
        }
    }
}));

/** A table component with breadcrumb navigation */
function SensorHistoryComponent(props) {
    const location = useLocation();
    let dashboardState;
    if (location.state) {
        dashboardState = location.state.dashboardState;
    }
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ history: [] });
    const { transmitterId, transmitterName } = location.state;
    const [sensorTypeOptions, setSensorTypeOptions] = React.useState([]);
    const [selectedSensorType, setSelectedSensorType] = React.useState([]);
    const [maxValue, setMaxValue] = React.useState(null);
    const [minValue, setMinValue] = React.useState(null);
    const [fetched, setFetched] = React.useState(false);
    const [sensorDataLoaded, setsensorDataLoaded] = React.useState(false);
    const [switchData, setSwitchData] = React.useState(true);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] = React.useState(null);
    const [applyClick, setApplyClick] = React.useState(false);
    const [averageOverTextFieldValue, setAverageOverTextFieldValue] = React.useState(1);
    let plotgraph = <tr><td><HourglassEmptyIcon /></td></tr>
    const averageOverMax = 24;
    const averageOverMin = 1;

    useEffect(() => {
        async function loadData() {
            if (selectedSensorType?.length > 0) {
                await populateHistoryData();
            }
        }
        loadData();
        setApplyClick(false);
    }, [selectedSensorType, applyClick, switchData]);

    useEffect(() => {
        async function loadSensorData() {
            await populateSensorData();
        }
        loadSensorData();
    }, []);

    useEffect(() => {
        populateAuditData();
    }, []);

    async function populateAuditData() {
        await AuditsService.insertAuditData(auditNames.ViewSensors, transmitterName ? 'Sensors for transmitter ' + transmitterName : null);
    }

    function getMinMaxForLoop(arr) {
        const sortedArr = arr.sort((a, b) => a - b);
        const min = sortedArr[0];
        const max = sortedArr[sortedArr.length - 1];
        let result = ([max, min]);
        return result;
    }

    async function populateSensorData() {
        let sensorTypeData = {};
        try {
            sensorTypeData = await SensorHistoryService.getSensorType(transmitterId);
        } catch (err) {
            props.snackbarShowMessage('The sensor data could not be loaded. ', 'error', 4000);
            return;
        }
        setSensorTypeOptions(sensorTypeData.items);
        setSelectedSensorType(sensorTypeData.minmumValueSensor)
        setsensorDataLoaded(true);
    }
    async function populateHistoryData() {
        let rowData = {};
        try {

            rowData = await SensorHistoryService.getSensorHistory(transmitterId, selectedSensorType, fromDate, toDate, averageOverTextFieldValue);
            const valuesForMinandMax = getMinMaxForLoop(rowData.items.map((r) => { return r.Value.toFixed(2) }));
            setMaxValue(valuesForMinandMax[0]);
            setMinValue(valuesForMinandMax[1]);
        } catch (err) {
            props.snackbarShowMessage('The history could not be loaded. ', 'error', 4000);
            return;
        }
        setState({ history: rowData.items });
        setFetched(true)
    }
    async function loadExportData() {
        setApplyClick(true);
        return await SensorHistoryService.getSensorHistoryExport(transmitterId, selectedSensorType, fromDate, toDate, averageOverTextFieldValue);
    }
    const lineLayer = {
        type: 'line',
        x: 'Date',
        y: 'Value',
    }
    const table = newTable(state.history == undefined ? 0 : state.history.length)
        .addColumn('Date', 'dateTime:RFC3339', 'time', state.history.map((r) => { return getLocalDateUnix(r.CapturedAt) }))
        .addColumn('Value', 'double', 'number', state.history.map((r) => { return r.Value.toFixed(2) }));

    const config = {
        table: table,
        valueFormatters: {
            'Date': timeFormatter({ format: 'DD/MM/YYYY HH:mm:ss' })
        },
        layers: [lineLayer],
        yDomain: [minValue, maxValue],
        includeYDomainZoom: true
    }

    if (sensorDataLoaded === true) {
        if (fetched === true && table.length > 0 && sensorTypeOptions.length > 0) {
            plotgraph = < Plot config={config} />
        }
        else if (fetched === false && sensorTypeOptions.length === 0) {
            plotgraph = <InputLabel>No Sensors Found</InputLabel>
        }
        else if (fetched === false && sensorTypeOptions.length > 0) {
            plotgraph = <HourglassEmptyIcon />
        }
        else if (fetched === true && table.length === 0 && sensorTypeOptions.length > 0) {
            plotgraph = <InputLabel>No Data Found</InputLabel>
        }
    }
    const handleChange = (event) => {
        setSelectedSensorType(event.target.value);
    };

    const handleSwitchClick = (isChecked) => {
        setSwitchData(isChecked);
        if (isChecked === true) {
            setFromDate('');
            setToDate('');
        }
    };
    const onApplyClick = () => {
        setApplyClick(true);
    };
    const onAverageOverClick = (e) => {
        const newValue =
            e.target.value > averageOverMax ? averageOverMax :
            e.target.value < averageOverMin ? averageOverMin :
            e.target.value;
        setAverageOverTextFieldValue(newValue);
    };

    return (
        <div className={classes.root}>
            <div className={classes.div}>
                <BasicBreadcrumbs page={'Sensor Data for ' + transmitterName} backLabel={'Dashboard'} backTo={'/dashboard'} returnState={dashboardState} ></BasicBreadcrumbs>
                <div>
                    <Grid container>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <FormControlLabel control={<Switch defaultChecked onChange={(event) => handleSwitchClick(event.target.checked)} />} label="Raw Data" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="sensortype">Sensor Type</InputLabel>
                                <Select
                                    {...props}
                                    labelId="sensortype"
                                    label="Sensor Type"
                                    options={sensorTypeOptions}
                                    value={selectedSensorType}
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    style={{ marginBottom: '16px' }}
                                >
                                    {sensorTypeOptions.map((row, index) => {
                                        return (
                                            <MenuItem value={row.Value} key={index}>{row.Text}</MenuItem>
                                        );
                                    })}
                                </Select>

                            </FormControl>
                        </Grid>
                    </Grid>
                    {switchData === false &&
                        (
                            <div>
                                <div className={classes.datePicker}>
                                    <Stack direction="row" spacing={2} alignItems="baseline">
                                        <LocalizationProvider dateAdapter={AdapterMoment} >
                                            <DatePicker format={getLocalDateFormat()} label="From" onChange={(date) => {
                                                const startOfDate = getUtcStartOfDate(date);
                                                setFromDate(startOfDate);
                                            }} />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker format={getLocalDateFormat()} label="To" onChange={(date) => {
                                                const endOfDate = getUtcEndOfDate(date);
                                                setToDate(endOfDate);
                                            }} />
                                        </LocalizationProvider>
                                        <TextField
                                            id="outlined-password-input"
                                            label="Average over (Hours)"
                                            type="number"
                                            value={averageOverTextFieldValue}
                                            onChange={(e) => onAverageOverClick(e)}
                                            autoComplete="Average over"
                                            InputProps={{
                                                inputProps: {
                                                    max: averageOverMax, min: averageOverMin
                                                }
                                            }}
                                        />
                                    </Stack>
                                </div>
                                <Stack direction="row" spacing={2} alignItems="baseline" justifyContent="space-between">
                                    <Button variant="outlined" size="large" color='inherit' onClick={() => onApplyClick()}>Apply</Button>
                                    <ExportButton
                                        loadExportData={loadExportData}
                                        title={'Export Sensor Information'}
                                        filename={'Sensor Data for ' + transmitterName + JSON.stringify(getLocalDateTime(fromDate)) + '.xlsx'}
                                        worksheet={'Sensor Data'}
                                    />
                                </Stack>

                            </div>
                        )}
                </div>
            </div>
            <Paper className={switchData ? classes.paper : classes.paperExport} >
                {plotgraph}
            </Paper>

        </div>
    );
}
export default withSnackbar(SensorHistoryComponent);

SensorHistoryComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};