// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Formik
import { Formik } from 'formik';
import * as Yup from 'yup';

// Services
import SensorService from '../../components/sensor/sensor.service';

// Components
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function SensorRecordComponent(props) {
    const classes = useAppStyles();
    const [state, setState] = useState({ item: {} });
    const [open, setOpen] = useState(false);
    const [existingSensors, setExistingSensors] = useState([]);

    const fetchExistingSensors = async (remove) => {
        let sensors = await SensorService.getAllSensorsName();
        setExistingSensors(sensors.items.filter(r => r !== remove));
    };

    const [sensorTypes, setSensorTypes] = useState([]);
    const [checkedSensorTypes, setcheckedSensorTypes] = useState([]);

    const handleOpen = () => {
        fetchExistingSensors(props.item == null ? '' : props.item.SensorName)
        populateSensorTypes()
        setOpen(true);
    };
    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    useEffect(() => {
        setState({ item: props.item });
    }, []);

    async function populateSensorTypes() {
        let sensorTypesData = await SensorService.getSensorTypes(props.item == null ? 0 : props.item?.Id);
        setSensorTypes(sensorTypesData ? sensorTypesData.items : []);
        setcheckedSensorTypes(sensorTypesData.selectedSensorTypes)
    }

    const handleClose = () => {
        setOpen(false);
    };
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    return (

        <div>
            <IconButton onClick={() => handleOpen()} variant='contained' color='primary'>
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        id: state.item?.Id,
                        sensorName: state.item?.SensorName,
                        sensorReference: state.item?.SensorReference,
                        collectTempC: (state.item === null || state.item === undefined) ? false : state.item?.CollectTempC,
                        collectWind: (state.item === null || state.item === undefined) ? false : state.item?.CollectWind,
                        collectRain: (state.item === null || state.item === undefined) ? false : state.item?.CollectRain,
                        sensorTypeSelected: checkedSensorTypes
                    }}
                    validationSchema={Yup.object().shape({
                        sensorName: Yup.string()
                            .min(3, 'Must be at least 5 characters')
                            .max(35, 'Must be 35 characters or less')
                            .test('unique', 'Sensor name already in use', value => { return !existingSensors.includes(value); })
                            .required('Required'),
                        sensorReference: Yup.string()
                            .min(3, 'Must be at least 5 characters')
                            .max(35, 'Must be 35 characters or less')
                            .required('Required'),
                        sensorTypeSelected: Yup.array()
                            .of(
                                Yup.object().shape({
                                    Text: Yup.string(),
                                    Value: Yup.string(),
                                })
                            )
                            .min(1, 'Select Atlest 1 sensorType')
                            .required('Required'),
                    })}
                    onSubmit={async (values) => {
                        let reload = false;
                        setState({ item: state.item });
                        if (values.id === undefined) {
                            try {
                                let added = await SensorService.insertSensor(values);
                                if (added === true) {
                                    props.snackbarShowMessage('The sensor has been added. ', 'success', 4000);
                                    reload = true;
                                } else {
                                    props.snackbarShowMessage('The sensor could not be added. ', 'error', 4000);
                                }
                            } catch (err) {
                                props.snackbarShowMessage('The sensor could not be added. ', 'error', 4000);
                            }
                        }
                        else {
                            try {
                                let updated = await SensorService.updateSensor(values);
                                if (updated === true) {
                                    props.snackbarShowMessage('The sensor has been updated. ', 'success', 4000);
                                    reload = true;
                                } else {
                                    props.snackbarShowMessage('The sensor could not be updated. ', 'error', 4000);
                                }
                            } catch (err) {
                                props.snackbarShowMessage('The sensor could not be updated. ', 'error', 4000);
                            }
                        }
                        setState({ item: state.item });
                        await sleep(500);
                        if (reload === true) props.btnFunction();
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form id='sensor_form' onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span>Add sensor</span> : <span >Edit sensor: {state.item.SensorName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <input type='hidden' id='id' name='id' />
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Sensor Name'
                                    name='sensorName'
                                    data-testid='sensor-name-test-id'
                                    value={values.sensorName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sensorName && Boolean(errors.sensorName)}
                                    fullWidth
                                />
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Sensor Reference'
                                    name='sensorReference'
                                    data-testid='sensor-reference-test-id'
                                    value={values.sensorReference}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.sensorReference && Boolean(errors.sensorReference)}
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                />
                                <Autocomplete
                                    name="sensorTypes"
                                    data-testid='sensor-types-test-id'
                                    multiple
                                    disableCloseOnSelect
                                    options={sensorTypes ? sensorTypes : []}
                                    value={values.sensorTypeSelected}
                                    getOptionLabel={(option) => option.Text ?? ''}
                                    isOptionEqualToValue={(option, value) => option.Text === value.Text}
                                    onChange={(event, value) => {
                                        setFieldValue('sensorTypeSelected', value ? value : []);
                                    }}
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected} />
                                            {option.Text}
                                        </li>
                                    )}
                                    renderInput={(params) => (<TextField {...params} error={touched.sensorTypeSelected && Boolean(errors.sensorTypeSelected)} name="sensorTypes" label="Sensor Types" variant='outlined' onBlur={handleBlur} placeholder="Sensor Types" />)}
                                />
                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                    data-testid='sensor-save-button'
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}

export default withSnackbar(SensorRecordComponent);

SensorRecordComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    item: PropTypes.object,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func
};