// Services
import Http from '../../services/http';

export default class TenantService {

    static async updateTenant(row) {
        let data = {
            Id: row.id,
            categoryId: row.categoryId,
            TenantName: row.tenantName,
            WebSocketAddress: row.webSocketAddress,
            AnprRetention: row.anprRetention
        };
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/tenants/update', data).catch(error => console.error(error));
    }

    static async insertTenant(row) {
        let data = {
            tenantName: row.tenantName,
            categoryId: row.categoryId,
            webSocketAddress: row.webSocketAddress,
            AnprRetention: row.anprRetention
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/tenants', data).catch(error => console.error(error));
    }
}