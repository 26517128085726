// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

// Services
import CategoriesService from '../../components/categories/categories.service';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import CategoryRecordComponent from '../../components/categories/category.component';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import { stableData, getComparator } from '../../helpers/Sorting';

const headCells = [
    { id: 'categoryName', numeric: false, disablePadding: false, label: 'Full Category Name' },
];

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black',
        boxShadow: 0
    },
    table: {
        minWidth: 750,
    },
}));

/**A table component with a toolbar and edit functions */
function CategoryComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ categories: [] });
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('categoryName');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = React.useState('');
    const [isForceReload, setIsForceReload] = React.useState(false);
    const buttonIcon = <Tooltip title={'Add a category'}><AddIcon data-testid="add-button"></AddIcon></Tooltip>;


    useEffect(() => {
        populateCategoryData();
    }, [isForceReload, page, rowsPerPage, order, orderBy]);

    async function populateCategoryData() {
        setLoading(true);
        let rowData = {};
        /** an array of rows */
        try {
            rowData = await CategoriesService.getCategories(page + 1, rowsPerPage, order, orderBy, search);
        } catch (err) {

            props.snackbarShowMessage('The categories could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ categories: [] });
            setLoading(false);
            return;
        }
        if (rowData === null || rowData === undefined) {
            props.snackbarShowMessage('The categories are not available. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ categories: [] });
            setLoading(false);
            return;
        }

        setState({ categories: rowData.items });
        setTotalPageCount((rowData.pageInfo === null || rowData.pageInfo === undefined) ? 1 : rowData.pageInfo.pageCount);
        setLoading(false);
    }

    /**
     * Handles the sort property
     * @param {any} event
     * @param {any} property
     */
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    /**
     * Deletes the category
     * @param {any} event
     * @param {any} row
     */
    async function deleteCategory(event, row) {
        setLoading(true);
        let deleted = await CategoriesService.deleteCategory(row);
        if (deleted === 'ok') props.snackbarShowMessage('The category has been deleted. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The category could not be deleted. ', 'error', 4000);
        populateCategoryData();
        setLoading(false);
    }

    /**
     * Changes the page
     * @param {any} e
     * @param {any} newPage
     */
    async function handleChangePage(e, newPage) {
        setLoading(true);
        setPage(newPage);
    }

    /**
     * Sets the amount of rows per page
     * @param {any} event
     */
    async function handleChangeRowsPerPage(event) {
        setPage(0);
        setRowsPerPage(event.target.value);
    }

    /**
     * Handles search input changes and updates the search state.
     * @param {string} searchValue - The new search value to be set.
     */
    async function handleSearch (searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const modal = (
        <CategoryRecordComponent btnFunction={populateCategoryData} buttonIcon={buttonIcon} />
    );
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.categories.length - page * rowsPerPage);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <EnhancedTableToolbar
                    hasAddButton={true}
                    modal={modal}
                    searchPlaceholder={'Search categories...'}
                    setSearch={handleSearch}
                />
                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.categories.length < 1 ? <TableRow><TableCell colSpan='2'>No categories were found. </TableCell></TableRow> : stableData(state.categories, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btndelete = <Tooltip title={'Remove this category'}><IconButton onClick={(event) => deleteCategory(event, row)} variant='contained' color='primary' data-testid="delete-button"><DeleteIcon /></IconButton></Tooltip>
                                    const buttonIcon = <Tooltip title={'Edit this category'}><IconButton variant='contained' color='primary' data-testid="edit-button"><EditIcon /></IconButton></Tooltip>

                                    return (
                                        <TableRow
                                            hover
                                            role='checkbox'
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component='th' id={labelId} scope='row' align='left'>
                                                {row.CategoryName}
                                            </TableCell>
                                            <TableCell align='right' >
                                                <ButtonGroup >
                                                    {btndelete}
                                                    <CategoryRecordComponent
                                                        item={row}
                                                        btnFunction={populateCategoryData}
                                                        buttonIcon={buttonIcon}
                                                    />
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 0 }}>
                                    <TableCell style={{ border: 'none' }} colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={totalPageCount * rowsPerPage}
                    disabled={false}
                    rowsPerPage={rowsPerPage}
                    page={(page)}
                    onPageChange={(event, params) => {
                        handleChangePage(event, params);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    slotProps={{
                        actions: {
                            nextButton: {
                                'data-testid': 'next-button',
                            },
                        },
                    }}
                />
            </Paper>
        </div>
    );
}
export default withSnackbar(CategoryComponent);


CategoryComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};