import React from 'react';

// Formik
import PropTypes from 'prop-types';

// MUI
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { StreamPlayerComponent } from './streamplayer.component';

const Item = styled(Paper)(() => ({
    textAlign: 'center',
    aspectRatio: '16/9',
    maxWidth: '100%',
    border: 'black',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0px',
    boxShadow: 'none',
    lineHeight: '0.69',
    height: '100%',
    position: 'relative'
}));

const useStyles = makeStyles(() => ({
    vid: {
        width: '100%'
    },
    wrapper: {
        margin: '0 auto 0 auto'
    },
    camOptionLabel: {
        cursor: 'pointer',
        top: '45%',
        position: 'relative',
        textAlign: 'center',
        width: '100%'
    },
    removeCamButton: {
        display: 'contents !important',
        cursor: 'pointer',
        float: 'left',
        position: 'absolute !important'
    },
    camButton: {
        cursor: 'pointer',
        position: 'relative !important',
        left: 0
    },
    gridviewItemContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    }
}));

export default function MultiViewGrid(props) {
    const { rows, cols, multiViewItems, handleRemoveItem, handleClickOpen, isDesignerMode } = props;
    const classes = useStyles();

    const gridArray = Array.from({ length: rows * cols }, (_, index) => ({
        rowIndex: Math.floor(index / cols),
        colIndex: index % cols
    }));

    let wrapperStyle = { width: '80%'};
    if (cols > rows) {
        wrapperStyle = {}
    }

    return (
        <div className={classes.wrapper} style={wrapperStyle}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container columns={{ xs: cols, md: cols, lg: cols }}>
                    {
                        Array.from(gridArray).map(({ rowIndex, colIndex }, index) => {
                            const entry = multiViewItems?.find(item =>
                                parseInt(item.Row) === (rowIndex + 1) && parseInt(item.Column) === (colIndex + 1)
                            );
                            return (
                                <Grid item xs={1} key={index}>
                                    <Item className={classes.vid}>

                                        {isDesignerMode === false && entry && (
                                            <StreamPlayerComponent
                                                className={classes.vid}
                                                transmitterId={entry.TransmitterId}
                                                transmitterCameraId={entry.TransmitterCameraId}
                                                cameraNumber={entry.CameraNumber}
                                                transmitterName={entry.TransmitterName}
                                                displayName={entry.DisplayName}
                                                transmitterReverseProxy={entry.ProxyUrl}
                                                multiviewId={props.multiviewId}
                                                multiviewsPage={props.multiviewsPage}
                                                multiviewsRowsPerPage={props.multiviewsRowsPerPage}
                                                updateMuliviewsCallback={props.updateMuliviewsCallback}
                                                multiViewItems={props.multiViewItems}
                                                row={rowIndex + 1}
                                                column={colIndex + 1}
                                            />
                                        )}
                                        {entry && isDesignerMode && (
                                            <div className={classes.gridviewItemContainer}>
                                                <Typography>
                                                    <a
                                                        className={classes.camButton}
                                                        onClick={handleClickOpen}
                                                        onKeyDown={handleClickOpen}
                                                        data-index={index}
                                                        data-row={rowIndex}
                                                        data-column={colIndex}
                                                    >
                                                        {entry.TransmitterName} - {entry.CameraName}
                                                    </a>
                                                </Typography>
                                                <div>
                                                    <IconButton
                                                        className={classes.removeCamButton}
                                                        onClick={() => handleRemoveItem(entry.TransmitterCameraId)}
                                                        data-index={index}
                                                        data-row={rowIndex}
                                                        data-column={colIndex}
                                                        variant='contained'
                                                        color='primary'
                                                    >
                                                        <Tooltip title={'Remove camera'}>
                                                            <CloseIcon data-index={index} data-row={rowIndex} data-column={colIndex} />
                                                        </Tooltip>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                        {!entry && isDesignerMode && (
                                            <Typography className={classes.camOptionLabel}>
                                                <a
                                                    onClick={handleClickOpen}
                                                    onKeyDown={handleClickOpen}
                                                    data-index={index}
                                                    data-row={rowIndex}
                                                    data-column={colIndex}
                                                >
                                                    No camera selected
                                                </a>
                                            </Typography>
                                        )}
                                        {!entry && !isDesignerMode && (

                                            <StreamPlayerComponent 
                                                multiviewId={props.multiviewId}
                                                multiviewsPage={props.multiviewsPage}
                                                multiviewsRowsPerPage={props.multiviewsRowsPerPage}
                                                updateMuliviewsCallback={props.updateMuliviewsCallback}
                                                multiViewItems={props.multiViewItems}
                                                hasNoCameraSelected={true}
                                                row={rowIndex + 1}
                                                column={colIndex + 1}
                                            />
                                            
                                        )}
                                    </Item>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </div>
    );
}

MultiViewGrid.propTypes = {
    multiViewItems: PropTypes.array,
    handleRemoveItem: PropTypes.func,
    handleClickOpen: PropTypes.func,
    isDesignerMode: PropTypes.bool,
    rows: PropTypes.number.isRequired,
    cols: PropTypes.number.isRequired,
    multiviewId: PropTypes.number.isRequired,
    multiviewsPage: PropTypes.number,
    multiviewsRowsPerPage: PropTypes.number,
    updateMuliviewsCallback: PropTypes.func
};
