// React
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Services
import { roles } from '../../utils/roles.js';
import { signoutRedirect, getIdToken } from '../../services/userService'

// MUI
import { alpha } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CategoryIcon from '@mui/icons-material/Category';
import SensorsIcon from '@mui/icons-material/Sensors';
import PlaceIcon from '@mui/icons-material/Place';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import ArticleIcon from '@mui/icons-material/Article';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import LanguageIcon from '@mui/icons-material/Language';

// Components
import '../../../src/components/NavMenu.css';
import StellifiiLogo from '../../../src/Stellifii-White-Logo.png';
import { NavMenu } from '../../components/NavMenu';
import PrivateComponent from '../../components/rbac/PrivateComponent';
import AccountPopover from '../molecules/AccountPopover';
import NotificationTray from '../molecules/NotificationTrayComponent';

const useStyles = makeStyles((theme) => createStyles({
    link: {
        cursor: 'pointer'
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}));

export default function NavigationBar(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const user = useSelector(state => state.auth.user);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    async function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
    }

    async function handleSignOut() {
        let token = await getIdToken();
        setAnchorEl(null);
        handleMobileMenuClose();
        await signoutRedirect(token);
    }

    const menuId = 'primary-search-account-menu';

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            {!user && (

                <MenuItem onClick={handleSignOut}>Sign In</MenuItem>
            )}
            {user && (

                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            )}
        </Menu>
    );

    const catmenu = (
        <MenuItem component={Link} to='/categories' onClick={() => handleMenuClose()} >
            <IconButton aria-label='show categories' color='inherit'>
                <CategoryIcon />
            </IconButton>
            <span className={classes.link}>Categories &nbsp;</span>
        </MenuItem>
    );
    const sensormenu = (
        <MenuItem component={Link} to='/sensor' onClick={() => handleMenuClose()} >
            <IconButton aria-label='show sensors' color='inherit'>
                <SensorsIcon />
            </IconButton>
            <span className={classes.link}>Sensors &nbsp;</span>
        </MenuItem>
    );

    const logmenu = (
        <MenuItem component={Link} to='/logs' onClick={() => handleMenuClose()}>
            <IconButton aria-label='show logs' color='inherit'>
                <ArticleIcon />
            </IconButton>
            <span className={classes.link}>Logs &nbsp;</span>
        </MenuItem>
    );

    const sitemenu = (
        <MenuItem component={Link} to='/sites' onClick={() => handleMenuClose()}>
            <IconButton aria-label='show sites' color='inherit'>
                <PlaceIcon />
            </IconButton>
            <span className={classes.link}>Sites &nbsp;</span>
        </MenuItem>
    );

    const tenantmenu = (
        <MenuItem component={Link} to='/customers' onClick={() => handleMenuClose()}>
            <IconButton aria-label='show customers' color='inherit'>
                <SupervisedUserCircleIcon />
            </IconButton>
            <span className={classes.link}>Customers &nbsp;</span>
        </MenuItem>
    );

    const transmittermenu = (
        <MenuItem component={Link} to='/transmitters' onClick={() => handleMenuClose()} >
            <IconButton aria-label='show transmitters' color='inherit'>
                <CameraAltIcon />
            </IconButton>
            <span className={classes.link}>Transmitters &nbsp;</span>
        </MenuItem>
    );

    const footagemenu = (
        <MenuItem component={Link} to='/footage' onClick={() => handleMenuClose()}>
            <IconButton aria-label='show footage' color='inherit'>
                <PlaylistPlayIcon />
            </IconButton>
            <span className={classes.link}>Footage &nbsp;</span>
        </MenuItem>
    );

    const usermenu = (
        <MenuItem component={Link} to='/users'>
            <IconButton aria-label='show users' color='inherit'>
                <AccountCircle />
            </IconButton>
            <span className={classes.link}>Users &nbsp;</span>
        </MenuItem>
    );
    const opsmenu = (
        <MenuItem component={Link} to='/operations'>
            <IconButton aria-label='operations' color='inherit'>
                <LanguageIcon />
            </IconButton>
            <span className={classes.link}>Operations &nbsp;</span>
        </MenuItem>
    );
    const adminMenu = (
        <>
            <PrivateComponent component={usermenu} roles={roles.Administrator} />
            <PrivateComponent component={logmenu} roles={roles.Administrator} />
            <PrivateComponent component={footagemenu} roles={roles.Administrator} />
        </>
    );
    const productionMenu = (
        <>
            <PrivateComponent component={transmittermenu} roles={roles.Production} />
        </>
    );
    const wcctvMenu = (
        <>
            <PrivateComponent component={catmenu} roles={roles.WCCTV} />
            <PrivateComponent component={sensormenu} roles={roles.WCCTV} />
            <PrivateComponent component={logmenu} roles={roles.WCCTV} />
            <PrivateComponent component={sitemenu} roles={roles.WCCTV} />
            <PrivateComponent component={tenantmenu} roles={roles.WCCTV} />
            <PrivateComponent component={transmittermenu} roles={roles.WCCTV} />
            <PrivateComponent component={footagemenu} roles={roles.WCCTV} />
            <PrivateComponent component={usermenu} roles={roles.WCCTV} />
        </>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <>
            {(user) && (
                <Menu
                    anchorEl={mobileMoreAnchorEl}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    id={mobileMenuId}
                    keepMounted
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={isMobileMenuOpen}
                    onClose={handleMobileMenuClose}
                >
                    <MenuItem component={Link} to='/dashboard' onClick={() => handleMenuClose()}>
                        <IconButton aria-label='show dashboard' color='inherit'>
                            <DeviceHubIcon />
                        </IconButton>
                        <span className={classes.link}>Dashboard &nbsp;</span>
                    </MenuItem>
                    <MenuItem component={Link} to='/multiview' onClick={() => handleMenuClose()}>
                        <IconButton aria-label='show multi view' color='inherit'>
                            <BorderAllIcon />
                        </IconButton>
                        <span className={classes.link}>Multiview &nbsp;</span>
                    </MenuItem>
                    <PrivateComponent component={adminMenu} roles={roles.Administrator} />
                    <PrivateComponent component={wcctvMenu} roles={roles.WCCTV} />
                    <PrivateComponent component={productionMenu} roles={roles.Production} />

                    <PrivateComponent component={opsmenu} roles={roles.WCCTV} />


                </Menu>
            )}
        </>
    );
    function openMenu(ev) {
        handleMobileMenuOpen(ev);
    }

    function clearAllNotificationsClick() {
        props.onClearAllNotifications();
    }
    return (
        <div className={classes.grow}>
            <AppBar color='secondary'>
                <Toolbar style={{ height: '64px' }}>
                    <Link to='/'>
                        <img width='200px' src={StellifiiLogo} alt='WCCTV Logo' />
                    </Link>
                    <NavMenu />

                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {(user) && (
                            <NotificationTray messages={props.messages} onClearAllNotifications={clearAllNotificationsClick}></NotificationTray>
                        )}
                        {(user) && (
                            <IconButton className={classes.menuButton} color='inherit' aria-label='open drawer' onClick={openMenu} >
                                <MenuIcon />
                            </IconButton>
                        )}

                    </div>
                    {(user) && (
                        <AccountPopover />
                    )}
                    <div className={classes.sectionMobile}>
                        {(user) && (
                            <IconButton
                                aria-label='show more'
                                aria-controls={mobileMenuId}
                                aria-haspopup='true'
                                onClick={handleMobileMenuOpen}
                                color='inherit'
                            >
                                <MoreIcon />
                            </IconButton>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

        </div>
    );
}
NavigationBar.propTypes = {
    messages: PropTypes.array,
    onClearAllNotifications: PropTypes.func
};