// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MUI
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

// Components
import UserComponent from '../../components/users/user.component';
import UserInviteComponent from '../../components/users/userinvite.component';
import UsersService from '../../components/users/users.service';
import TenantsService from '../tenants/tenants.service';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import { stableData, getComparator } from '../../helpers/Sorting';
import { roles } from '../../utils/roles.js';
import { getUser } from '../../services/userService';
import UserService from '../../components/users/user.service';
import AreYouSure from '../../ui/molecules/AreYouSure';
import FilterComponent from '../../ui/molecules/FilterComponent';

const headCells = [
    { id: 'Email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'Role', numeric: false, disablePadding: false, label: 'Role' },
    { id: 'TwoFactorEnabled', label: 'Two Factor Enabled' },
    { id: 'ExternalProvider', label: 'External Provider' }
];

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black'
    },
    table: {
        minWidth: 750,
    },
}));

/**A table component with a toolbar and edit functions */
function UsersComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ users: [], unregisteredUsers: [], customers: [], sites: [], loading: false });
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('Email');
    const [loading, setLoading] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [tenants, setTenants] = React.useState([]);
    const [sites, setSites] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [currentRowToDelete, setCurrentRowToDelete] = React.useState();
    const headRow = [
        { id: 'Customer', numeric: false, disablePadding: false, Value: 2, IsDate: false, Text: 'Customer', filterBy: true, IsBoolean: false, label: 'Customer' },
        { id: 'Site', numeric: false, disablePadding: false, IsDate: false, Value: 5, Text: 'Site', filterBy: true, label: 'Site' }
    ];
    const [sourceDropdownData, setsourceDropdownData] = React.useState(headRow.filter(data => data.filterBy));
    const [applyData, setApplyData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    function handleCloseConfirmation() {
        setOpenDialog(false);
    }

    let user = useSelector(state => state.auth.user);

    useEffect(() => {
        populateUserData();

    }, [search, applyData, page]);

    useEffect(() => {
        populateFilterData();

    }, []);


    async function populateUserData() {
        setLoading(true);
        if (!user) user = await getUser();
        let rowData = {};
        let tenants = {};
        let sitesData = {};
        try {
            /** an array of rows */
            rowData = await UsersService.getUsers(search, user.profile.sub, applyData);
        } catch (err) {
            props.snackbarShowMessage('Unable to load the users');
        }
        try {
            tenants = await await TenantsService.getAllTenantsDropdown('asc', '', '');
            setTenants(tenants);
        } catch (err) {
            props.snackbarShowMessage('Unable to load the customers');
        }
        try {
            sitesData = await UsersService.getAllSites();
            setSites(sitesData.items)
        } catch (err) {
            props.snackbarShowMessage('Unable to load the customers');
        }
        setState({ users: rowData.items, customers: tenants, sites: sitesData.items, loading: false });
        setLoading(false);
    }

    async function populateFilterData() {
        let customers = await UserService.getCustomersBySubject(user.profile.sub);
        let sites = await UserService.getCheckedSitesByTenantIds(user.profile.sub);
        const dropdownData = sourceDropdownData.map(data => {
            if (data.id === 'Customer') {
                data.dropdown = customers.items.map(a => a.Tenant)
            }
            if (data.id === 'Site') {
                data.dropdown = sites.items
            }
            return data;
        });

        setsourceDropdownData(dropdownData);
    }

    const handleRequestSort = (event, property) => {
        if (order === 'asc') {
            const sortedData = [...state.users].sort((a, b) => a[property].toString().toLowerCase() > b[property].toString().toLowerCase() ? 1 : -1);
            setState({ users: sortedData, customers: tenants, sites: sites });
            setOrder('desc');
            setOrderBy(property);
        }
        if (order === 'desc') {
            const sortedData = [...state.users].sort((a, b) => a[property].toString().toLowerCase() < b[property].toString().toLowerCase() ? 1 : -1);
            setState({ users: sortedData, customers: tenants, sites: sites });
            setOrder('asc');
            setOrderBy(property);
        }
    };

    async function handleDeactivateUser() {
        setLoading(true);
        let deleted = await UsersService.deactivateUser(currentRowToDelete);
        if (deleted === true) props.snackbarShowMessage('The user has been deactivated. ', 'success', 4000);
        else props.snackbarShowMessage(deleted + 'The user could not be deactivated. ', 'warning', 4000);

        populateUserData();
        setLoading(false);
        setOpenDialog(false);
    }

    async function deactivateUser(event, row) {
        setOpenDialog(true);
        setCurrentRowToDelete(row);
    }


    const invitemodal = (
        <UserInviteComponent btnFunction={populateUserData} data-testid={'invite-user-button'} />
    );

    return (
        <div className={classes.root}>
            <div>
                <FilterComponent sourcedropdownText={sourceDropdownData} setApply={setApplyData} setPage={setPage} filterList={applyData} ></FilterComponent>
            </div>
            <p ></p>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    additionalmodal={invitemodal}
                    searchPlaceholder={'Search users...'}
                    setSearch={setSearch}
                />
                <hr />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby='tableTitle'
                        size={'small'}
                        aria-label='enhanced table'
                    >
                        <EnhancedTableHead
                            isRowEditable={true}
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : (!state.users || state.users.length < 1) ? <TableRow><TableCell colSpan='2'>No users were found. </TableCell></TableRow> : stableData(state.users, getComparator(order))

                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const btndeactivate = <Tooltip title={'Remove this user'}><IconButton onClick={async (event) => await deactivateUser(event, row)} variant='contained' color='primary' data-testid={'user-delete-button'}><DeleteIcon /></IconButton></Tooltip>
                                    const buttonIcon = <Tooltip title={'Edit this user'}><EditIcon></EditIcon></Tooltip>
                                    return (
                                        <TableRow
                                            className={'standard-row'}
                                            hover
                                            role='checkbox'
                                            tabIndex={-1}
                                            key={row.Id}
                                        >
                                            <TableCell component='th' id={labelId} scope='row' align='left'>
                                                {row.Email}
                                            </TableCell>
                                            <TableCell align='left'>{row.Role}</TableCell>
                                            <TableCell align='left'>
                                                {<Checkbox checked={row.TwoFactorEnabled} disabled data-testid='user-two-factor-checkbox' />}
                                            </TableCell>
                                            <TableCell align='left'>
                                                {<Checkbox checked={row.ExternalProvider} disabled data-testid='user-external-provider-checkbox' />}
                                            </TableCell>
                                            <TableCell align='right' >
                                                <ButtonGroup >
                                                    {(row.Subject !== user.profile.sub && (row.Role !== roles.Administrator || user?.profile.nvrclient_access === roles.WCCTV)) && (
                                                        btndeactivate
                                                    )}
                                                    {((row.Role === roles.User && user?.profile.nvrclient_access !== roles.User) || (row.Role !== roles.Administrator || user?.profile.nvrclient_access === roles.WCCTV)) && (
                                                        <UserComponent
                                                            item={row}
                                                            unregisteredUsers={state.unregisteredUsers}
                                                            customers={state.customers}
                                                            sites={state.sites}
                                                            btnFunction={populateUserData}
                                                            buttonIcon={buttonIcon}
                                                            data-testid='user-edit-role-dropdown'
                                                        />
                                                    )}
                                                </ButtonGroup>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {openDialog ?
                <AreYouSure isOpenDialog={openDialog} closeDialog={handleCloseConfirmation} titleText='Delete User' contentText={<span>Are you sure you want to delete this User: {currentRowToDelete.Email} ?</span>} onYesClick={handleDeactivateUser} > </AreYouSure>
                : null}
        </div>
    );
}
export default withSnackbar(UsersComponent);

UsersComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};