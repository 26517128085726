// React
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// MUI
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    breadcrumb: {
        paddingBottom: 10
    },
    link: {
        color: theme.palette.primary.main,
    }
}));

export function BasicBreadcrumbs(props) {
    const { page, backTo, backLabel, returnState } = props;
    const classes = useStyles();
    const link = returnState ? { pathname: backTo, state: { returnState: returnState } }: backTo;
    return (
        <div role="presentation" className={classes.breadcrumb} >
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" className={classes.link} to={link} data-testid="back-link">
                    {backLabel}
                </Link>
                <Typography color="text.primary" data-testid="current-page">
                    {page}
                </Typography>
            </Breadcrumbs>
        </div>
    );
}

BasicBreadcrumbs.propTypes = {
    returnState: PropTypes.object,
    page: PropTypes.string,
    backTo: PropTypes.string,
    backLabel: PropTypes.string
}; 