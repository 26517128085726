// Services
import Http from '../../services/http';

export default class SensorAssignmentService {
    static async getSensorAssignmentsDropdown(transmitterId) {
        const sensorassignment = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensorassignmentdropdown?transmitterId=' + transmitterId).catch(error => console.error(error));
        if (sensorassignment === undefined) return;

        return sensorassignment;
    }
  
}
