// Services
import Http from '../../services/http';

export default class TransmitterFirmwareService {

    static async getTransmitterVersions() {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/transmitterVersions');
    }

    static async insertTransmitterFirmware(row) {
        let data = {
            transmitterId: row.transmitterId,
            version:row.version
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/transmitterFirmwareRequest', data);
    }

}