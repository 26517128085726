// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Mui
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InfoIcon from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ArticleIcon from '@mui/icons-material/Article';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import SensorsIcon from '@mui/icons-material/Sensors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageIcon from '@mui/icons-material/Message';

// Formik

// Services
import AuditsService from '../audits/audits.service';
import TransmittersService from '../../components/transmitters/transmitters.service';
import { getLocalDateTime } from '../../utils/dateFormatter';
import { auditNames } from '../../utils/auditNames';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import FilterComponent from '../../ui/molecules/FilterComponent';
import { roles } from '../../utils/roles.js';

// SignalR
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getAccessToken } from '../../services/userService';

// New
import Dialog from '@mui/material/Dialog';
import SitesComponent from '../../components/sites/sites.component.js';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle.js';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

function stableData(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black'
    },
    table: {
        minWidth: 750,
    }
}));

/**A table component with a toolbar and edit functions */
function DashboardComponent(props) {
    const classes = useStyles();
    const location = useLocation();
    let dashboardState = { order: 'asc', orderBy: 'TransmitterName', page: 0, rowsPerPage: 10, applyData: [] };
    if (location.state) {
        dashboardState = location.state.returnState;
    }

    /** The page data */
    const [assignDialogOpen, setAssignDialogOpen] = React.useState(false);
    const [isForceReload, setIsForceReload] = React.useState(false);
    const [selectedTransmitter, setSelectedTransmitter] = React.useState(null);
    const [state, setState] = React.useState({ transmitters: [], sites: [] });
    const [order, setOrder] = React.useState(dashboardState.order);
    const [orderBy, setOrderBy] = React.useState(dashboardState.orderBy);
    const [page, setPage] = React.useState(dashboardState.page);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(dashboardState.rowsPerPage);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentRow, setCurrentRow] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [connection, setConnection] = React.useState();
    const [applyData, setApplyData] = React.useState(dashboardState.applyData);
    const user = useSelector(state => state.auth.user);
    const headerRow = [
        { id: 'Status', numeric: false, disablePadding: false, label: 'Status', Text: 'Status', Value: 1, dropdown: [] },
        { id: 'TransmitterName', numeric: false, disablePadding: false, label: 'Transmitter', Text: 'Transmitter', Value: 2, dropdown: [] },
        { id: 'Site', numeric: false, disablePadding: false, label: 'Site', Text: 'Site', Value: 3, dropdown: [] },
        { id: 'Customer', numeric: false, disablePadding: false, label: 'Customer', Text: 'Customer', Value: 4, dropdown: [] },
        { id: 'Category', numeric: false, disablePadding: false, label: 'Category', Text: 'Category', Value: 5, dropdown: [] }
    ]
    const [headCells, setHeadCells] = React.useState(headerRow);

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentRow(null);
    };
    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setCurrentRow(row);
    };

    const handleOpenAssignSite = (transmitter) => {
        setSelectedTransmitter(transmitter);
        setAssignDialogOpen(true);
    };

    const handleCloseAssignDialog = () => {
        setAssignDialogOpen(false);
    };

    //useeffect to load transmitter data
    useEffect(() => {
        async function constructor() {
            return await loadData();
        }
        async function loadData() {
            return await populateTransmitterData();
        }
        constructor();
    }, [page, rowsPerPage, order, orderBy, applyData, isForceReload]);

    //useeffect to load auditData
    useEffect(() => {
        populateAuditData();
    }, []);

    //useeffect to load signalR
    useEffect(() => {
        loadSignalR();
    }, [user])

    //useeffect to process signalR message
    useEffect(() => {
        processMessage(message);
    }, [message])

    // useEffect to get dropdown data
    useEffect(() => {
        populateDashboardInfoData();
    }, []);

    async function populateAuditData() {
        await AuditsService.insertAuditData(auditNames.ViewDashboard, '');
    }

    async function populateTransmitterData() {
        setLoading(true);
        let rowData = {};
        let siteData = [];

        try {
            rowData = await TransmittersService.getFilteredTransmittersDashboard(applyData, page + 1, rowsPerPage, order, orderBy);
        } catch (err) {
            props.snackbarShowMessage('The transmitters could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ transmitters: [], sites: state.sites });
            setLoading(false);
            return;
        }
        setState({ transmitters: rowData.items, sites: siteData });
        setTotalPageCount(rowData.pageInfo.pageCount);
        setLoading(false);
    }

    async function loadSignalR() {
        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_NVR_API + 'hubs/notifications', { accessTokenFactory: async () => { return await getAccessToken() } })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        await connection.start();

        setConnection(connection);

        connection.on('SendMessageToDashboard', message => {
            setMessage(message);
        });
        if (user) {
            connection.invoke('JoinGroup', user.profile.sub);
        }
        connection.onreconnected(() => {
            if (user) {
                connection.invoke('JoinGroup', user.profile.sub);
            }
        });

    }

    function processMessage(message) {
        if (connection) {
            const messageObject = JSON.parse(message);
            if (messageObject != null) {
                const updatedTransmitters = state.transmitters.map(transmitter => {
                    if (transmitter.Id === messageObject.Id) {
                        transmitter.Status = messageObject.Status;
                        transmitter.LastChecked = messageObject.LastChecked;
                        transmitter.LastFault = messageObject.LastFault;
                    }
                    return transmitter;
                });
                setState({ transmitters: updatedTransmitters })
            }
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);
    };

    async function handleChangePage(e, newPage) {
        setLoading(true);
        setPage(newPage);
    }

    async function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }


    async function populateDashboardInfoData() {
        let rowData = await TransmittersService.getDashboardInfoData();

        const dropdownData = headCells.map(data => {
            if (data.id === 'Status') {
                data.dropdown = rowData.StatusDropdown
            }
            if (data.id == 'TransmitterName') {
                data.dropdown = rowData.TransmitterDropdown
            }
            if (data.id == 'Site') {
                data.dropdown = rowData.SiteDropdown
            }
            if (data.id == 'Customer') {
                data.dropdown = rowData.CustomerDropdown
            }
            if (data.id == 'Category') {
                data.dropdown = rowData.CategoryDropdown
            }
            return data;
        });
        setHeadCells(dropdownData);
    }

    async function publishGSMMessage(transmitterId) {
        await TransmittersService.postGSMMessage( transmitterId );
    }

    const handleRefresh = () => {
        setIsForceReload(!isForceReload);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.transmitters.length - page * rowsPerPage);
    return (
        <div>
            <div className={classes.root}>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <FilterComponent sourcedropdownText={headCells} setApply={setApplyData} filterList={applyData} setPage={setPage} data-testid='dashboard-head-cells'>
                    </FilterComponent>
                </div>
            </div>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    < TableContainer >
                        <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size={'small'}
                            aria-label='enhanced table'
                        >
                            <EnhancedTableHead
                                isRowEditable={true}
                                classes={classes}
                                headCells={headerRow}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.transmitters.length < 1 ? <TableRow><TableCell colSpan='7'>No transmitters were found. </TableCell></TableRow> : stableData(state.transmitters)
                                    .map((row, index) => {

                                        const checkTime = getLocalDateTime(row.LastChecked);
                                        const faultCheckTime = getLocalDateTime(row.LastFault);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const successMessage = 'Status last checked on ' + checkTime;
                                        const failureMessage = row.LastFault ? 'Failed on checks on ' + faultCheckTime : 'Unknown status';
                                        return (

                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.Id}
                                            >
                                                <TableCell component='th' id={labelId} scope='row' align='left' >
                                                    <Tooltip title={row.Status === 10 ? successMessage : failureMessage}>
                                                        <InfoIcon data-testid='status-icon' color={row.Status === 10 ? 'success' : 'warning'} />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell component='th' id={labelId} scope='row' align='left'>
                                                    <Link className={classes.link} to={{ pathname: 'liveview', state: { transmitterName: row.TransmitterName, transmitterReverseProxy: row.ProxyLink, returnState: { page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, applyData: applyData } } }}>
                                                        <Tooltip title={<span>Connect to {row.TransmitterName}</span>}>
                                                            <label className={classes.link}>{row.TransmitterName}</label>
                                                        </Tooltip>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align='left'>{row.Site}</TableCell>
                                                <TableCell align='left'>{row.Customer}</TableCell>
                                                <TableCell align='left'>{row.Category}</TableCell>
                                                <TableCell align='right' id={labelId} scope='row' >
                                                    <IconButton
                                                        keepMounted
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={open ? 'long-menu' : undefined}
                                                        aria-expanded={open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={(e) => handleClick(e, row)}
                                                    >
                                                        <MoreVertIcon color='primary' />
                                                    </IconButton>
                                                    <Menu
                                                        keepMounted
                                                        anchorEl={anchorEl}
                                                        onClose={() => handleClose()}
                                                        open={currentRow === row}
                                                    >
                                                        <MenuItem>
                                                            <Link className={classes.link} to={{ pathname: 'liveview', state: { transmitterName: row.TransmitterName, transmitterReverseProxy: row.ProxyLink, returnState: { page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, applyData: applyData } } }}>
                                                                <IconButton aria-label='show logs' color='primary' >
                                                                    <PlayCircleOutlineIcon data-link={row.ProxyLink} color='primary' />
                                                                </IconButton>
                                                                <span className={classes.link}>Connect &nbsp;</span>
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <Link className={classes.link} to={{ pathname: 'logs', state: { transmitterName: row.TransmitterName, transmitterId: row.Id, returnState: { page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, applyData: applyData } } }} >
                                                                <IconButton aria-label='show logs' color='primary' >
                                                                    <ArticleIcon />
                                                                </IconButton>
                                                                <span className={classes.link}>Logs &nbsp;</span>
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <Link className={classes.link} to={{ pathname: 'sensors', state: { transmitterId: row.Id, transmitterName: row.TransmitterName, returnState: { page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, applyData: applyData } } }}>
                                                                <IconButton aria-label='show sensor history' color='primary' >
                                                                    <SensorsIcon />
                                                                </IconButton>
                                                                <span className={classes.link}>Sensors &nbsp;</span>
                                                            </Link>
                                                        </MenuItem>
                                                        <MenuItem>
                                                            <Link className={classes.link} to={{ pathname: 'footage', state: { transmitterName: row.TransmitterName, transmitterId: row.Id, returnState: { page: page, rowsPerPage: rowsPerPage, order: order, orderBy: orderBy, applyData: applyData } } }}>
                                                                <IconButton aria-label='show footage' color='primary'>
                                                                    <PlaylistPlayIcon />
                                                                </IconButton>
                                                                <span className={classes.link}> Footage &nbsp;</span>
                                                            </Link>
                                                        </MenuItem>
                                                        {(row.RentalNumber == null || row.RentalNumber == '') && (user?.profile.nvrclient_access === roles.Administrator || user?.profile.nvrclient_access === roles.WCCTV) && (
                                                            <MenuItem onClick={() => { handleOpenAssignSite(row); handleClose(); }}>
                                                                <IconButton data-testid='assign-site-option' aria-label='Assign Site' color='primary'>
                                                                    <AssignmentReturnedIcon />
                                                                </IconButton>
                                                                <span className={classes.link}>Assign Site</span>
                                                            </MenuItem>
                                                        )}
                                                        {(row.RentalNumber == null) && (user?.profile.nvrclient_access === roles.Administrator) && (
                                                            <MenuItem onClick={() => { publishGSMMessage(row.Id); handleClose(); }}>
                                                                <IconButton aria-label='gsm reboot' color='primary' data-testid='gsm-reboot-button'>
                                                                    <MessageIcon />
                                                                </IconButton>
                                                                <span className={classes.link}>GSM Reboot &nbsp;</span>
                                                            </MenuItem>
                                                        )}
                                                    </Menu>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 0 }}>
                                        <TableCell style={{ border: 'none' }} colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={totalPageCount * rowsPerPage}
                        disabled={false}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, params) => {
                            handleChangePage(event, params);
                        }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            inputProps: { 'data-testid': 'rows-per-page-select' },
                            native: true,
                        }}
                    />
                </Paper>

            </div >
            <Dialog
                open={assignDialogOpen}
                onClose={handleCloseAssignDialog}
                aria-labelledby="assign-site-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <AppDialogTitle onClose={handleCloseAssignDialog}>
                    Assign Site to {selectedTransmitter?.TransmitterName}
                </AppDialogTitle>
                <SitesComponent isDashboard={true} selectedTransmitter={selectedTransmitter} onClose={() => { handleCloseAssignDialog(); handleRefresh(); }} snackbarShowMessage={props.snackbarShowMessage} />
            </Dialog>
        </div>
    );
}
export default withSnackbar(DashboardComponent);

DashboardComponent.propTypes = {
    isDashboard: PropTypes.bool,
    snackbarShowMessage: PropTypes.func
};