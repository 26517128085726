import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    image: {
        width: '100%'
    },
    red: {
        color: '#ff0000',
    },
    detailsCard: {
        borderWidth: '2px !important',
        margin: '5px !important',
        boxShadow:
            '-3529px 2px 1px -1px rgba(0,0,0,0.2),0px 3px 2px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)',
        minHeight: '410px !important'
    },
    infoLabel: {
        fontWeight: '600 !important'
    },
    errorLabel: {
        fontWeight: '600 !important',
        color: '#ff0000'
    }
}));

export function CameraCard ({ camera }){
    const classes = useStyles();

    return (
        <Grid item lg={4} sm={4} xs={12}>
            <Card className={classes.detailsCard}>
                <CardContent>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body2" color="text.secondary" className={classes.infoLabel} gutterBottom>
                            Camera Name:&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {camera.Name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body2" color="text.secondary" className={classes.infoLabel} gutterBottom>
                            Image Capture Time (UTC):&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {camera.ImageSnapshotDateTimeUtc}
                        </Typography>
                    </Box>
                    <div>
                        <img src={camera.ImageUrl} className={classes.image} alt="snapshot" />
                    </div>
                    {camera.ErrorType !== 0 && (
                        <Stack >
                        <React.Fragment>
                                <Typography variant="text.secondary" className={classes.red, classes.errorLabel}>
                                {camera.error === null ? ' ' : 'Error:'}
                            </Typography>
                                <Typography variant="text.secondary" className={classes.red}>
                                {camera.error === null ? ' ' : camera.Error}
                            </Typography>
                            </React.Fragment>
                        </Stack>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
}

CameraCard.propTypes = {
    camera: PropTypes.object.isRequired,
};

