// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from '@mui/material';
import Slide from '@mui/material/Slide';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Components
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';

// Services
import TransmitterService from '../../components/transmitters/transmitter.service';
import SensorAssignmentService from '../sensorassignment/sensorassignments.service';
// Formik
import { Formik } from 'formik';

// Yup
let yup = require('yup');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;


function TransmitterRecordComponent(props) {
    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ item: {}, sites: [] });
    const [existingTransmitters, setExistingTransmitters] = useState([]);
    const [sensorcheckedoptions, setsensorcheckedoptions] = useState([]);
    const [sensoroptions, setsensoroptions] = useState([]);
    const webSocketPrefix = 'wss://';
    const webSocketSuffix = '/wsock';

    const fetchExistingTransmitters = async (remove) => {
        let transmitters = await TransmitterService.getAllTransmittersWebSocket();
        setExistingTransmitters(transmitters.items.filter(r => r !== remove));
    };

    const handleClickOpen = () => {
        fetchExistingTransmitters(props.item == null ? '' : props.item.WebSocketAddress)
        getSensors();
        setOpen(true);
    };

    async function getSensors() {
        let sensorassignmentrowData = await SensorAssignmentService.getSensorAssignmentsDropdown(props.item == null ? 0 : props.item?.Id);
        setsensorcheckedoptions(sensorassignmentrowData.checkedSensors);
        setsensoroptions(sensorassignmentrowData ? sensorassignmentrowData.items : []);
        sensorassignmentrowData = sensorassignmentrowData ? sensorassignmentrowData.items : [];
    }

    const handleClose = () => {
        setOpen(false);
    };

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    useEffect(() => {
        setState({ item: props.item, sites: props.sites });

    }, []);

    let addTransmitterSchema = yup.object().shape({
        transmitterName: yup.string()
            .min(3, 'Must be at least 3 characters')
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
        webSocketAddress: yup.string()
            .matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, 'Invalid web socket address')
            .test('unique', 'This web socket address is already in use', (value) => { return !existingTransmitters.includes(webSocketPrefix + value + webSocketSuffix); })
            .required('Required'),
        siteId: yup.string()
            .required('Select a site')
    });

    const sites = props.sites ? props.sites : [];

    return (
        <div>
            <IconButton onClick={() => handleClickOpen()} variant='contained' color='primary' >
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        id: state.item?.Id,
                        transmitterName: state.item?.TransmitterName,
                        webSocketAddress: state.item?.WebSocketAddress?.match(/\/\/([^/]+)/)[1],
                        siteId: state.item?.SiteId,
                        site: { Text: state.item?.Site, Value: state.item?.SiteId },
                        gsmNumber: state.item?.GSMNumber,
                        rentalNumber: state.item?.RentalNumber,
                        sensorassignmentsSelected: sensorcheckedoptions,
                    }}
                    validationSchema={addTransmitterSchema}
                    onSubmit={async (values) => {
                        let reload = false;
                        setState({ item: props.item, tenants: props.tenants, sites: props.sites });
                        values.webSocketAddress = webSocketPrefix + values.webSocketAddress + webSocketSuffix;

                        if (values.id === undefined) {
                            try {
                                let added = await TransmitterService.insertTransmitter(values);
                                if (added === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The transmitter has been added. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue adding the transmitter. ', 'warning', 4000);
                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the transmitter. ', 'danger', 4000);
                            }
                        }
                        else {
                            try {
                                let updated = await TransmitterService.updateTransmitter(values);
                                if (updated === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The transmitter has been updated. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue updating the transmitter. ', 'warning', 4000);

                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the transmitter. ', 'danger', 4000);
                            }
                        }
                        await sleep(2000);
                        setState({ item: props.item, tenants: props.tenants });
                        if (reload === true) props.btnFunction();
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span className={'float-left'}>Add Transmitter</span> : <span className={'float-left'}>Edit Transmitter: {state.item.TransmitterName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Transmitter Name'
                                    name='transmitterName'
                                    value={values.transmitterName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.transmitterName && Boolean(errors.transmitterName)}
                                    fullWidth
                                />
                                <TextField
                                    variant='outlined'
                                    label='Web Socket Address'
                                    name='webSocketAddress'
                                    onChange={(e) => { /^(\d{1,3}\.){0,3}(\d{0,3})$/.test(e.target.value) && handleChange(e) }}
                                    value={values.webSocketAddress ? values.webSocketAddress : ''}
                                    onBlur={handleBlur}
                                    error={touched.webSocketAddress && Boolean(errors.webSocketAddress)}
                                    style={{ marginTop: '1rem', width: '275px' }}
                                    InputProps={{
                                        startAdornment: (<InputAdornment position='start'>{webSocketPrefix}</InputAdornment>),
                                        endAdornment: (<InputAdornment position='end'>{webSocketSuffix}</InputAdornment>)
                                    }}
                                />
                                <Autocomplete
                                    name='sites'
                                    options={sites}
                                    getOptionLabel={group => group.Text ?? ''}
                                    getOptionSelected={(option, value) => option.Value === value.Value}
                                    value={values.site}
                                    onChange={(e, value) => {
                                        setFieldValue('siteId', value !== null ? value.Value : '');
                                        setFieldValue('site', value !== null ? { Text: value.Text, Value: value.Value } : {})
                                    }}
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label='Site'
                                            name='siteId'
                                            variant='outlined'
                                            value={values.siteId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.siteId && Boolean(errors.siteId)}
                                        />
                                    )}
                                />
                                <TextField
                                    variant='outlined'
                                    label='GSM Number'
                                    name='gsmNumber'
                                    style={{ marginTop: '1rem' }}
                                    value={values.gsmNumber ? values.gsmNumber : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={touched.gsmNumber && Boolean(errors.gsmNumber)}
                                />
                                <br/>
                                <TextField
                                    variant='outlined'
                                    label='Rental Number'
                                    name='rentalNumber'
                                    value={values.rentalNumber ? values.rentalNumber : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    style={{ marginTop: '1rem' }}
                                    error={touched.rentalNumber && Boolean(errors.rentalNumber)}
                                />
                                <Autocomplete
                                    multiple
                                    options={sensoroptions ? sensoroptions : []}
                                    disableCloseOnSelect
                                    value={values.sensorassignmentsSelected}
                                    getOptionLabel={(option) => option.Text ?? ''}
                                    isOptionEqualToValue={(option, value) => option.Text === value.Text}
                                    onChange={(event, value) => {
                                        setFieldValue('sensorassignmentsSelected', value ? value : []);
                                    }}
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selected} />
                                            {option.Text}
                                        </li>
                                    )}
                                    renderInput={(params) => (<TextField {...params} label="Sensor Assignments" variant='outlined' onBlur={handleBlur} placeholder="Sensor Assignments" />)}
                                />
                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
}

export default withSnackbar(TransmitterRecordComponent);

TransmitterRecordComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    sites: PropTypes.array,
    tenants: PropTypes.array,
    item: PropTypes.object,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func
};