// Services
import Http from '../../services/http';

export default class TransmittersService {
    static async getTransmitters(filterData, page, size, order, orderBy, text) {
        const encodedText = encodeURIComponent(text);
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        let data = {
            items: filterData
        }
        const Transmitters = await Http.postData(process.env.REACT_APP_NVR_API + 'api/transmittersData?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + encodedText)), data).catch(error => console.error(error))
        return Transmitters;
    }

    static async getTransmittersDropdown(order, orderBy, text) {
        let data = [];

        const Transmitters = await Http.getData(process.env.REACT_APP_NVR_API + 'api/Transmitters/dropdown?order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text))).catch(error => console.error(error));
        if (Transmitters === undefined) return;
        for (let i = 0; i < Transmitters.items.length; i++) {
            data[i] = { text: Transmitters.items[i].TransmitterName, value: Transmitters.items[i].Id };
        }
        return data;
    }

    static async deleleTransmitter(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/Transmitters/delete', data).catch(error => console.error(error));
    }
    static async getDashboardInfoData() {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/transmitters/dashboard-info-data');
    }

    static async getTransmitterInfoData() {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/transmitters/transmitter-info-data');
    }

    static async getFilteredTransmittersDashboard(filterData, page, size, order, orderBy) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        let data = {
            items: filterData
        }
        const FilteredData = await Http.postData(process.env.REACT_APP_NVR_API + 'api/Transmitters/filtered/dashboarddata?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)), data).catch(error => console.error(error))
        return FilteredData;
    }
    static async postGSMMessage(transmitterId) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/transmitters/gsm/publish/message', transmitterId).catch(error => console.error(error))
    }
}
