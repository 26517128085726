// React
import React from 'react';

// Mui
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    }
}));

/**A page component */
function CookiePolicyComponent() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
                <Typography variant='h5'>Cookie Policy</Typography>

                <Typography>
                    <strong>
                        What are cookies?
                    </strong>
                </Typography>
                <p>
                    <Typography>
                        Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.
                    </Typography>
                </p>

                <Typography>
                    <strong>
                        How do cookies benefit me?
                    </strong>
                </Typography>
                <p>
                    <Typography>
                        Some of the cookies on this site are used to remember things you have specifically selected, such as closing or opening the search panel, choosing a language, expanding and collapsing sections on tables of contents, dismissing notification banners.
                    </Typography>
                </p>

                <Typography>
                    <strong>
                        Are cookies safe?
                    </strong>
                </Typography>
                <p>
                    <Typography>
                        As you may be aware, most websites use cookies. They will not cause any harm to your device. Cookies do not tell us who you are or give us any personal details about you. The data they help us collect is anonymous. We do not use cookies to look at individual journeys across the website, nor do we collect any data that could be used to personally identify you.
                    </Typography>
                </p>

                <Typography>
                    <strong>
                        How long do they last?
                    </strong>
                </Typography>
                <p>
                    <Typography>
                        A number of cookies and similar technologies we use last only for the duration of your web session and expire when you close your browser. Others are used to remember you when you return to Our Site and will last for longer.
                    </Typography>
                </p>

                <Typography>
                    <strong>
                        What type of cookies do we use?
                    </strong>
                </Typography>
                <Typography>
                    <p>
                        There are the following types of cookies:
                    </p>
                    <p>
                        Essential cookies These are cookies that are required for the operation of our website and under our terms with you. They include, for example, cookies that enable you to log into secure areas of our website.
                    </p>
                    <p>
                        Analytical cookies They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us for our legitimate interests of improving the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                    </p>
                    <p>
                        Functionality cookies These are used to recognise you when you return to our website. This enables us, subject to your choices and preferences, to personalise our content, greet you by name and remember your preferences (for example, your choice of language or region).
                    </p>
                    <p>
                        WCCTV just use Essential cookies.
                    </p>
                </Typography>



                <Typography>
                    <strong>
                        Cookies we use and why
                    </strong>
                </Typography>
                <Typography>
                    The table below explains the cookies we use and why.
                </Typography>
                <TableContainer>
                    <Table>
                        <TableRow>
                            <TableCell>
                                Cookie
                            </TableCell>
                            <TableCell>
                                Purpose
                            </TableCell>
                            <TableCell>
                                Expires
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                ARRAffinitySameSite / ARRAffinity
                            </TableCell>
                            <TableCell>
                                This cookie is set by websites run on the Windows Azure cloud platform. It is used for load balancing to make sure the visitor page requests are routed to the same server in any browsing session
                            </TableCell>
                            <TableCell>
                                When your session ends
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                .AspNetCore.Identity.Application
                            </TableCell>
                            <TableCell>
                                This cookie is set when logging in. It is used for the Identity Server implementation
                            </TableCell>
                            <TableCell>
                                When your session ends
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                idsrv.session
                            </TableCell>
                            <TableCell>
                                This cookie is only used by the session monitoring endpoint to detect if the current session has changed
                            </TableCell>
                            <TableCell>
                                When your session ends
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                .AspNetCore.Antiforgery
                            </TableCell>
                            <TableCell>
                                The cookie used by the antiforgery system is part of a security system that is necessary when using cookie-based authentication.
                            </TableCell>
                            <TableCell>
                                When your session ends
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>

        </div>
    );
}
export default withSnackbar(CookiePolicyComponent);
