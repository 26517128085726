// Services
import Http from '../../services/http';

export default class SensorsService {
    static async getSensors(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        const sensors = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensors?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text)))
        return sensors;
    }

  
    static async deleteSensors(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/sensor/delete', data);
    }
}
