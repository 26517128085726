import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

export function MapComponent({ lat, lng, containerStyle, zoom = 17 }) {
    const center = {
        lat: lat,
        lng: lng
    };

    return (

        <LoadScript googleMapsApiKey='AIzaSyA4VGnub5bbS3q9ZeuIR4b7naJAkP9Fr98'>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
}

MapComponent.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    containerStyle: PropTypes.object.isRequired,
    zoom: PropTypes.number
};
