import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import TransmitterDetails from '../operations/transmitterdetails.component';
import { makeStyles } from '@mui/styles';


export default function SitesComponent ({ rows, open, handleToggleRow, countOfTransmittersWithErrors, countOfCamerasWithErrors, countOfCameras }) {
    const useStyles = makeStyles(() => ({
        pageContainer: {
            backgroundColor: 'rgb(250,250,250)',
            minHeight: '100vh',
        },
        tableGrid: {
            marginTop: '40px',
        },
        red: {
            color: '#E63027',
        },
        orange: {
            color: '#ed6c02',
        },
        green: {
            color: '#00b359',
        },
        black: {
            color: '#000',
        },
        headingCell: {
            fontWeight: '600 !important'
        },
        backgroundOrange: {
            backgroundColor: '#ed6c02',
            color: '#fff',
            padding: '4px',
            borderRadius: '4px'
        },
        backgroundRed: {
            backgroundColor: '#E63027',
            color: '#fff',
            padding: '4px',
            borderRadius: '4px'
        },
    }));

    const classes = useStyles();

    return (
        <Table sx={{ minWidth: 640 }} aria-label="simple table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: '5%' }} />
                    <TableCell className={classes.headingCell} style={{ width: '50%' }}>Site</TableCell>
                    <TableCell className={classes.headingCell} style={{ width: '20%' }}>Transmitters</TableCell>
                    <TableCell className={classes.headingCell} style={{ width: '20%' }}>Cameras</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <React.Fragment key={row.DisplayId}>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ width: '5%' }}>
                                <IconButton aria-label="expand row" size="small" onClick={() => handleToggleRow(row.DisplayId)}>
                                    {open[row.DisplayId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row" style={{ width: '50%' }}>
                                {row.Name}
                            </TableCell>
                            <TableCell>
                                <span className={classes.tableCell, `${countOfTransmittersWithErrors(row) > 0
                                    ? classes.backgroundRed
                                    : classes.black
                                    }`} style={{ width: '20%' }}>
                                    {((row.Transmitters.length) - countOfTransmittersWithErrors(row))} / {(row.Transmitters.length)}
                                </span>
                            </TableCell>
                            <TableCell>
                                <span className={classes.tableCell, `${countOfCamerasWithErrors(row) > 0
                                    ? classes.backgroundOrange
                                    : classes.black
                                    }`} style={{ width: '20%' }}>
                                    {(countOfCameras(row) - countOfCamerasWithErrors(row))} / {countOfCameras(row)}
                                </span>
                            </TableCell>
                        </TableRow>

                        <TableRow className={classes.detailRow}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                <Collapse in={open[row.DisplayId]} timeout="auto" unmountOnExit>
                                    {row.Transmitters.map((transmitter, transmitterIndex) => (
                                        <TransmitterDetails key={transmitterIndex} transmitter={transmitter} />
                                    ))}
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}

SitesComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    open: PropTypes.object.isRequired,
    handleToggleRow: PropTypes.func.isRequired,
    countOfTransmittersWithErrors: PropTypes.func.isRequired,
    countOfCamerasWithErrors: PropTypes.func.isRequired,
    countOfCameras: PropTypes.func.isRequired,
};

