// Services
import Http from '../../services/http';

export default class UsersService {
    static async getUsers(text, subjectId, filterData) {
        let userids = '';
        let userItems = {};
        let users = [];
        if (text === '')
            text = '@';
        let filter = {
            items: filterData
        }
        const registeredusers = await Http.postData(process.env.REACT_APP_NVR_API + 'api/users/active?' +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text)), filter).catch(error => console.error(error));
        if (registeredusers === undefined) {
            userItems.items = [];
            return userItems;
        }
        userids = registeredusers.items.map(i => i.Subject);
        let data = userids;
        const postdata = await Http.postData(process.env.REACT_APP_IDP_API + 'api/client/nvrclient/Search/' + text + '/User/' + subjectId, data).catch(error => console.error(error));
        users = postdata.items;
        let currentUsers = [];

        for (let k = 0; k < users.length; k++) {
            for (let j = 0; j < registeredusers.items.length; j++) {
                if (users[k].subject === registeredusers.items[j].Subject) {
                    registeredusers.items[j].Email = users[k].email;
                    registeredusers.items[j].Role = users[k].role;
                    registeredusers.items[j].Active = users[k].active;
                    registeredusers.items[j].TwoFactorEnabled = users[k].twoFactorEnabled;
                    registeredusers.items[j].ExternalProvider = users[k].externalProvider;
                    if (currentUsers.find(x => x.Subject === registeredusers.items[j].Subject) == null)
                        currentUsers.push(registeredusers.items[j]);

                }
            }
        }

        userItems.items = currentUsers;
        return userItems;
    }

    static async getAllSites() {
        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/user/siteDropdown').catch(error => console.error(error));
        return sites;
    }

    static async deactivateUser(row) {
        let removeData = { subject: row.Subject };
        let deactivateData = {
            SubjectId: row.Subject,
            ClientId: process.env.REACT_APP_IDP_CLIENT_ID
        }
        if (await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/users/remove', removeData).catch(error => console.error(error)))
            return await Http.postData(process.env.REACT_APP_IDP_API + 'api/account/deactivate', deactivateData).catch(error => console.error(error));
        else return false;
    }

}
