// Services
import Http from '../../services/http';

export default class SensorService {

    static async updateSensor(row) {
        let data = {
            Id: row.id,
            SensorName: row.sensorName,
            SensorReference: row.sensorReference,
            CollectTempC: row.collectTempC,
            CollectWind: row.collectWind,
            CollectRain: row.collectRain,
            SensorTypeSelected: row.sensorTypeSelected
        };
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/sensor/update', data).catch(error => console.error(error));
    }

    static async insertSensor(row) {
        let data = {
            SensorName: row.sensorName,
            SensorReference: row.sensorReference,
            CollectTempC: row.collectTempC,
            CollectWind: row.collectWind,
            CollectRain: row.collectRain,
            SensorTypeSelected: row.sensorTypeSelected
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/sensor', data).catch(error => console.error(error));
    }
    static async getSensorTypes(sensorId) {
        const sensorTypes = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensor/sensortypes?sensorId=' + sensorId).catch(error => console.error(error));
        return sensorTypes;
    }

    static async getAllSensorsName() {
        const names = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensors/getallsensorsname');
        return names;
    }
}