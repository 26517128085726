// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Services
import { getRole } from '../../services/userService';

function PrivateComponent (props) {
    const [isAuthenticated, setIsAuthenticated] = React.useState(true);
    const { component, roles } = props;

    useEffect(() => {
        async function constructor() {

            const role = await getRole();
            if (roles) {
                const required = roles.split(' ');
                if (required.indexOf(role) === -1) {
                    setIsAuthenticated(false);
                }
            }
        }
        constructor();
    }, []);

    return (isAuthenticated) ? (
        <>{component}</>
    ) : (
        <></>
    );
}
export default PrivateComponent;

PrivateComponent.propTypes = {
    component: PropTypes.object,
    roles: PropTypes.string
};