// Services
import Http from '../../services/http';


export default class SitesService {
    static async getSitesAsync(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sites?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text))).catch(error => console.error(error))
        return sites;
    }


    static async getSitesForUserAsync(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        const sites = await Http.getData(
            process.env.REACT_APP_NVR_API + 'api/sites/user?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text))
        ).catch(error => console.error(error));

        return sites;
    }

    static async getSitesDropdown(order) {
        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/sites/dropdown?order=' + order).catch(error => console.error(error));
        if (sites === undefined) return;
        
        return sites;
    }
    
    static async deleteSite(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/sites/delete', data).catch(error => console.error(error));
    }

    static async assignSiteToTransmitter(transmitterId, siteId) {
        let data = {
            transmitterId: transmitterId,
            siteId: siteId
        };

        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/sites/assign', data).catch(error => console.error(error));
    }
}
