// Services
import Http from '../../services/http';
import { getLocalDateTime } from '../../utils/dateFormatter';

export default class SensorHistoryService {
    static async getSensorHistory(transmitterId, selectedSensorType, fromDate, toDate, every) {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensorhistory?transmitterId=' + transmitterId + '&sensorType=' + selectedSensorType +
            (fromDate === undefined || fromDate === '' || fromDate === null ? '' : ('&fromDate=' + fromDate)) +
            (toDate === undefined || toDate === '' || toDate === null ? '' : ('&toDate=' + toDate)) +
            (every === undefined || every === '' || every === null ? '' : ('&every=' + every)))

    }

    static async deleteSensorHistory(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/sensorhistory/delete', data).catch(error => console.error(error));
    }

    static async getSensorType(transmitterId) {
        return await Http.getData(process.env.REACT_APP_NVR_API + 'api/sensorhistory/sensorassignmentdropdownlist?transmitterId=' + transmitterId).catch(error => console.error(error))
    }
    static async getSensorHistoryExport(transmitterId, selectedSensorType, fromDate, toDate, every) {
        const exportData = await this.getSensorHistory(transmitterId, selectedSensorType, fromDate, toDate, every)
        const sensorsArrayObject = exportData.items;
        const formattedSensors = sensorsArrayObject.map((data) => {
            const Value = data.Value;
            const Time = getLocalDateTime(data.CapturedAt);
            return {
                Value, Time
            }
        });
        const updatedSensorsString = JSON.stringify(formattedSensors);
        return JSON.parse(updatedSensorsString);
    }
}
