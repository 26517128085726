// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import Slide from '@mui/material/Slide';

// Services
import TenantService from '../../components/tenants/tenant.service';

// Formik
import { Formik } from 'formik';
import Button from '@mui/material/Button';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';

// Yup
let yup = require('yup');


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function TenantRecordComponent(props) {
    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ item: {}, categories: [] });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    useEffect(() => {
        setState({ loading: false, item: props.item, categories: props.categories });
    }, []);

    let addTenantSchema = yup.object().shape({
        tenantName: yup.string()
            .min(3, 'Must be at least 5 characters')
            .max(35, 'Must be 35 characters or less')
            .required('Required'),
        categoryId: yup.string()
            .required('Select Category'),
        anprRetention: yup.number()
            .min(14, 'Must be at least 14 days')
            .max(2000, 'Cannot be more than 2000 days')
            .required('Required')
    });

    const categories = props.categories;

    return (
        <div>
            <IconButton onClick={() => handleClickOpen()} variant='contained' color='primary'>
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    initialValues={{
                        id: state.item?.Id,
                        tenantName: state.item?.TenantName,
                        categoryId: state.item?.CategoryId,
                        category: { Text: state.item?.Category, Value: state.item?.CategoryId },
                        anprRetention: state.item?.AnprRetention || 180,
                    }}
                    validationSchema={addTenantSchema}
                    onSubmit={async (values) => {
                        let reload = false;
                        setState({ item: state.item, categories: state.categories });
                        if (values.id === undefined) {
                            try {
                                let added = await TenantService.insertTenant(values);
                                if (added === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The customer has been added. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue adding the customer. ', 'warning', 4000);
                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the customer. ', 'danger', 4000);
                            }
                        }
                        else {
                            try {
                                let updated = await TenantService.updateTenant(values);
                                if (updated === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The customer has been updated. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('There was an issue updating the customer. ', 'warning', 4000);

                            } catch (err) {
                                props.snackbarShowMessage('There was an issue updating the customer. ', 'danger', 4000);
                            }
                        }
                        await sleep(2000);
                        if (reload === true) props.btnFunction();
                        setState({ item: state.item, categories: state.categories });
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span className={'float-left'}>Add Customer</span> : <span className={'float-left'}>Edit Customer: {state.item.TenantName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <TextField
                                    autoFocus
                                    variant='outlined'
                                    label='Customer Name'
                                    name='tenantName'
                                    value={values.tenantName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.tenantName && Boolean(errors.tenantName)}
                                    fullWidth
                                />

                                <Autocomplete
                                    name='categories'
                                    options={categories}
                                    getOptionLabel={group => group.Text ?? ''}
                                    value={values.category}
                                    onChange={(e, value) => {
                                        setFieldValue('categoryId', value !== null ? value.Value : '');
                                        setFieldValue('category', value !== null ? { Text: value.Text, Value: value.Value } : {})
                                    }}
                                    fullWidth
                                    style={{ marginTop: '1rem' }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label='Category'
                                            name='categoryId'
                                            variant='outlined'
                                            value={values.categoryId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.categoryId && Boolean(errors.categoryId)}
                                        />
                                    )}
                                />

                                <TextField
                                    type='number'
                                    variant='outlined'
                                    label='ANPR Retention (Days)'
                                    name='anprRetention'
                                    value={values.anprRetention}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.anprRetention && Boolean(errors.anprRetention)}
                                    className='small-text-box'
                                    inputProps={{ 'data-testid': 'anpr-retention-textbox' }}
                                    style={{ marginTop: '1rem' }}
                                />
                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                    data-testid='tenant-save-button'
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
}
export default withSnackbar(TenantRecordComponent);

TenantRecordComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    categories: PropTypes.array,
    item: PropTypes.object,
    buttonIcon: PropTypes.func,
    btnFunction: PropTypes.func
};