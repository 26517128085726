// React
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// Mui
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import { makeStyles } from '@mui/styles';

// Components
import { BasicBreadcrumbs } from '../../ui/molecules/BasicBreadcrumbs';
import AuditsService from '../audits/audits.service';
import { auditNames } from '../../utils/auditNames';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingLeft: '20px'
    },
    frame: {
        //width: '100%',
        //height: '100%',
        //border: 'none',
        aspectRatio: '16 / 9',
        width: '100%'
    },
    outerbox: {
        padding: '0',
        height: 'calc(100vh - 150px)',
    },
    box: {
        margin: 'auto',
        aspectRatio: '16 / 9',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
        '@media (orientation: portrait)': {
            width: '100%',
            height: 'auto'
        },
        '@media (orientation: landscape)': {
            height: '100%',
            width: 'auto'
        }
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh'
    },
    modalContent: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderBottom: '1px solid #ddd',
    },
    modalFrame: {
        width: '100%',
        height: '100%',
        flexGrow: 1,
        border: 'none'
    },
    headerText: {
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
        fontWeight: '500',
        fontSize: '1.25rem'
    }
}));

function LiveViewComponent() {
    const history = useHistory();
    const location = useLocation();
    const { transmitterName, transmitterReverseProxy, backLabel, backTo } = location.state;
    const classes = useStyles();
    const forceModal = true;
    const [open, setOpen] = useState(forceModal);
    let returnState;
    if (location.state) {
        returnState = location.state.returnState;
    }

    useEffect(() => {
        populateAuditData();
    }, []);

    async function populateAuditData() {
        await AuditsService.insertAuditData(auditNames.ViewConnect, transmitterName ? 'View Connect - for transmitter ' + transmitterName : null);
    }

    const handleOpen = () => setOpen(true);
    function handleClose(){
        if (forceModal) {
            if (returnState.selectedMultiView) {
                history.push('/multiview', { returnState: returnState });
            }
            else {
                history.push('/dashboard', { returnState: returnState });
            }
        } else {
            setOpen(false);
        }
    }

    const iframeElement = (
        <iframe className={open ? classes.modalFrame : classes.frame} src={transmitterReverseProxy} allowFullScreen></iframe>
    );

    return (
        <>
            <Box className={classes.outerbox}>
                {transmitterName && (
                    <Box display="flex" alignItems="center">
                        <BasicBreadcrumbs page={'Live for ' + transmitterName} backLabel={backLabel ? backLabel : 'Dashboard'} backTo={backTo ? backTo : '/dashboard'} returnState={returnState} />
                        <IconButton onClick={handleOpen} color="primary" style={{ padding: '0px 0px 10px 10px'}}>
                            <PhotoSizeSelectLargeIcon />
                        </IconButton>
                    </Box>
                )}
                <Box className={classes.box}>
                    {!open && iframeElement}
                </Box>
            </Box>
            <Modal open={open} onClose={handleClose} className={classes.modal}>
                <Box className={classes.modalContent}>
                    <Box className={classes.modalHeader}>
                        <div className={classes.headerText}>Live View - {transmitterName}</div>
                        <IconButton onClick={handleClose} style={{ color: 'white' }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {open && iframeElement}
                </Box>
            </Modal>
        </>
    );
}

export default LiveViewComponent;
