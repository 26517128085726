// React
import React from 'react';
import PropTypes from 'prop-types';

// Components
import AppDialogContent from '../../ui/atoms/AppDialogContent';

// MUI
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function GroupedAutocomplete(props) {
    const { data, handleCameraSelection, onClose } = props;
    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleChange = (value) => {
        setSelectedValue(value.target.value);
    };
    const handleListItemClick = (e) => {
        handleCameraSelection(e.Id);
        onClose();
    };
    return (
        <Autocomplete
            getOptionSelected={(option, value) => option.Id === value.Id}
            onChange={(e, value) => {
                handleListItemClick(value);
            }}
            id='grouped-demo'
            options={data}
            groupBy={(option) => option.SiteName}
            getOptionLabel={(option) => option.CameraName + ', ' + option.TransmitterName}
            sx={{ width: 300 }}
            renderInput={params => (
                <TextField
                    {...params}
                    label='Camera'
                    name='cameraId'
                    variant='outlined'
                    value={selectedValue}
                    onChange={handleChange}
                />
            )}
        />
    );
}

GroupedAutocomplete.propTypes = {
    handleCameraSelection: PropTypes.func,
    data: PropTypes.object,
    onClose: PropTypes.func,
};

function CameraDdlDialog(props) {
    const { onClose, handleCameraSelection, isOpen, camerasData } = props;
    let sites = [];

    const handleClose = () => {
        onClose();
    };
    if (camerasData && camerasData.items && camerasData.items.length > 0) {
        let sitedata = camerasData.items.map(({ SiteName }) => SiteName);
        sites = Array.from(new Set(sitedata));
    }

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Select a camera</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <CloseIcon />
            </IconButton>
            <AppDialogContent>
                <div style={{ marginTop: '10px' }}>
                    <GroupedAutocomplete
                        sites={sites}
                        handleCameraSelection={handleCameraSelection}
                        data={camerasData.items}
                        onClose={onClose}
                    ></GroupedAutocomplete>
                </div>
            </AppDialogContent>
        </Dialog >
    );
}
CameraDdlDialog.propTypes = {
    handleCameraSelection: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    camerasData: PropTypes.array
};

export default function CamerasDropdownComponent(props) {
    const { isOpen, handleClose, handleCameraSelection, camerasData } = props;
    return (
        <div>
            <CameraDdlDialog
                camerasData={camerasData}
                handleCameraSelection={handleCameraSelection}
                isOpen={isOpen}
                onClose={handleClose}
            />
        </div>
    );
}


CamerasDropdownComponent.propTypes = {
    handleCameraSelection: PropTypes.func.isRequired,
    handleClose: PropTypes.func,
    camerasData: PropTypes.object,
    isOpen: PropTypes.bool
};

