// External Library Imports
import React, { useEffect, useState, useRef } from 'react';
import { Player, ControlBar, PlayToggle, ForwardControl, ReplayControl, PlaybackRateMenuButton } from 'video-react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// CSS Imports
import '../../../node_modules/video-react/dist/video-react.css';

// Icon Imports
import CameraIcon from '@mui/icons-material/Camera';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

// Utility Imports
import { getLocalDateTime } from '../../utils/dateFormatter';
import { auditNames } from '../../utils/auditNames';

// Service Imports
import AuditsService from '../../components/audits/audits.service';

// Local Component or Asset Imports
import StellifiiLogo from '../../Stellifii-White-Logo.png';

const useStyles = makeStyles(() => ({
    videoControlButton: {
        color: 'white',
        padding: '0px',
        minWidth: 'auto !important',
        margin: '0 4px !important',
        '&:hover': {
            backgroundColor: '#rgba(255, 255, 255, 0.2) !important',
            cursor: 'pointer !important'
        }
    },
    videoControlIcon: {
        fontSize: '19px !important',
        color: 'white'
    },
    videoPlayer: {
        '& .video-react-video': {
            position: 'relative',
            width: '100%',
            height: 'auto'
        }
    },
    customButton: {
        fontFamily: 'Arial, sans-serif',
        fontSize: '12px',
        color: '#fff'
    },
    playbackRate: {
        fontFamily: 'Arial, sans-serif'
    },
    hidden: {
        display: 'none',
    },
    videoContainer: {
        '& .video-react.video-react-fluid, & .video-react.video-react-16-9, & .video-react.video-react-4-3': {
            width: 'auto',
            height: 'auto',
            paddingTop: '0 !important'
        }
    },
    transformWrapper: {
        width: '100%',
        height: '100%',
    },
    transformComponent: {
        width: '100%',
        height: 'auto',
    },
    fullscreenButtonHide: {
        '& .video-react-control-bar .video-react-fullscreen-control': {
            display: 'none !important'
        }
    }
}));

function ScreenshotButton({ playerRef, item }) {
    const classes = useStyles();

    const handleScreenshotAudit = async () => {
        await AuditsService.insertAuditData(auditNames.ViewFootage, 'Screenshot of Footage Video - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
    };

    const handleClick = async () => {
        const videoElement = playerRef.current && playerRef.current.video && playerRef.current.video.video;

        if (!videoElement) {
            console.error('Video element is not ready or the wrong element type');
            return;
        }

        await handleScreenshotAudit();

        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Screenshot-${getLocalDateTime(new Date(item.FootageStart)).replace(/[:\s]/g, '-')}.jpg`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }, 'image/jpeg', 0.92);
    };

    return (
        <Button onClick={handleClick} className={classes.videoControlButton}>
            <CameraIcon className={classes.videoControlIcon} />
        </Button>
    );
}

function ResetZoomButton({ onReset }) {
    const classes = useStyles();
    return (
        <Button onClick={onReset} className={classes.videoControlButton}>
            <FindReplaceIcon className={classes.videoControlIcon} />
        </Button>
    );
}

function FullscreenButton({ isFullscreen, onToggle }) {
    const classes = useStyles();
    return (
        <Button onClick={onToggle} className={classes.videoControlButton}>
            {isFullscreen ? <FullscreenExitIcon className={classes.videoControlIcon} /> : <FullscreenIcon className={classes.videoControlIcon} />}
        </Button>
    );
}

ScreenshotButton.propTypes = {
    playerRef: PropTypes.shape({
        current: PropTypes.shape({
            video: PropTypes.object
        })
    }),
    item: PropTypes.shape({
        Name: PropTypes.string,
        FootageStart: PropTypes.string
    })
};

FootageVideoPlayer.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    playerRef: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
};

ResetZoomButton.propTypes = {
    onReset: PropTypes.func.isRequired
};

FullscreenButton.propTypes = {
    isFullscreen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
};

function FootageVideoPlayer({ videoUrl, playerRef, item }) {
    const classes = useStyles();
    const transformRef = useRef(); 
    const [isFullscreen, setIsFullscreen] = useState(false);
    const videoContainerRef = useRef(null);

    const handleResetZoom = () => {
        if (transformRef.current) {
            transformRef.current.resetTransform();
        }
    };

    useEffect(() => {
        if (playerRef.current) {
            const videoElement = playerRef.current.video.video;

            const playerContainer = videoElement.parentElement;
            playerContainer.style.overflow = 'hidden';
        }
    }, []);

    const handleFullscreenToggle = () => {
        if (!videoContainerRef.current) return;

        if (!isFullscreen) {
            if (videoContainerRef.current.requestFullscreen) {
                videoContainerRef.current.requestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
            setIsFullscreen(!!fullscreenElement);
            if (transformRef.current) {
                transformRef.current.resetTransform();
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    async function handlePlay() {
        await AuditsService.insertAuditData(auditNames.ViewFootage, 'View Footage Video - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
    }

    async function handleSeeking() {
        await AuditsService.insertAuditData(auditNames.ViewFootage, 'Dragging through Footage Video - for footage ' + item.Name + ' on ' + getLocalDateTime(item.FootageStart));
    }

    return (
        <div ref={videoContainerRef} className={`${classes.videoContainer} ${classes.fullscreenButtonHide}`}>
            <TransformWrapper
                ref={transformRef}
                className={classes.transformWrapper}
                doubleClick={{
                    disabled: true,
                }}
            >
                <TransformComponent className={classes.transformComponent}>
                    <Player
                        autoPlay
                        playsInline
                        poster={StellifiiLogo}
                        src={videoUrl}
                        ref={playerRef}
                        onPlay={handlePlay}
                        onSeeking={handleSeeking}
                        crossOrigin='anonymous'
                        className={classes.videoPlayer}
                    >
                        <ControlBar autoHide={false} className={classes.customButton}>
                            <PlayToggle />
                            <ReplayControl seconds={10} order={2.1} />
                            <ForwardControl seconds={10} order={3.1} />
                            <PlaybackRateMenuButton rates={[15, 10, 5, 2, 1, 0.5, 0.1]} order={7.1} className={classes.playbackRate} />
                            <ResetZoomButton onReset={handleResetZoom} order={7.2} />
                            <ScreenshotButton playerRef={playerRef} item={item} order={7.3} />
                            <FullscreenButton isFullscreen={isFullscreen} onToggle={handleFullscreenToggle} order={7.4} />
                        </ControlBar>
                    </Player>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}

export default FootageVideoPlayer;
