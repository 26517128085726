import Http from '../../services/http';

export default class HeartbeatImageService {

    static async getFilteredImageHeartbeatsAsync(filterData, page, size) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        let data = {
            items: filterData
        }
        const FilteredData = await Http.postData(process.env.REACT_APP_NVR_API + 'api/imageheartbeat/filtered?page=' + page + '&size=' + size, data).catch(error => console.error(error))
        return FilteredData;
    }

    static async getlastExecutionImageHeartbeatsAsync(filterData, page, size) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        let data = {
            items: filterData
        }

        const result = await Http.postData(process.env.REACT_APP_NVR_API + 'api/imageheartbeat/lastexecution?page=' + page + '&size=' + size, data).catch(error => console.error(error))

        return result;
    }

    static async getFilterData() {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/imageheartbeat/filters')
    }
}