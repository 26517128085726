// Services
import Http from '../../services/http';

export default class MultiViewsService {
    static async getMultiViews(page, size, order, orderBy, text) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        const multiviews = await Http.getData(process.env.REACT_APP_NVR_API + 'api/multiview?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy === undefined || orderBy === '' || orderBy === null ? '' : ('&orderBy=' + orderBy)) +
            (text === undefined || text === '' || text === null ? '' : ('&text=' + text)))
        return multiviews;
    }

    static async changeCamera(multiviewId, column, row, transmitterId, transmitterCameraId, newTransmitterCameraId) {

        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/multiview/changeCamera?multiviewId=' + multiviewId
            + '&columnIndex=' + column + '&rowIndex=' + row
            + '&transmitterId=' + transmitterId + '&transmitterCameraId=' + transmitterCameraId + '&newTransmitterCameraId=' + newTransmitterCameraId);
    }

    static async getTransmitterCameras() {
        const cameras = await Http.getData(process.env.REACT_APP_NVR_API + 'api/transmittercameradropdown');

        return cameras;
    }
    static async maintainMultiView(id, name, columns, rows, items) {

        let data = {
            id: id ?? 0,
            name: name,
            columns: columns,
            rows: rows,
            items: items
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/multiview', data);
    }
    static async deleteMultiView(row) {
        let data = {
            id: row.Id
        };

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/multiview/delete', data);
    }

    static async getStreamDetails(transmitterId, cameraNumber) {
         return await Http.getData(process.env.REACT_APP_NVR_API + 'api/multiview/streamdetails?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber);
    }

    static async panLeft(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/pan/left?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async panRight(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/pan/right?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async tiltUp(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/tilt/up?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async tiltDown(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/tilt/down?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async zoomIn(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/zoom/in?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async zoomOut(transmitterId, cameraNumber, step) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/zoom/out?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&step=' + step);
    }

    static async getPresets(transmitterId, cameraNumber) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/presets/get?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber);
    }

    static async gotoPreset(transmitterId, cameraNumber, presetToken) {
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/ptz/presets/goto?transmitterId=' + transmitterId + '&cameraNumber=' + cameraNumber + '&presetToken=' + presetToken);
    }
}
