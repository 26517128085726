// Services
import { getAccessToken, refreshAccessToken } from '../services/userService';
import { signinRedirect, loggedIn, getUser } from './userService';

export default class Http {
    /**
     * HTTP method implementation
     * @param {any} url
     * @param {any} data
     */
    static async sendData(url = '', data = {}, method = 'POST') {
        let token = await getAccessToken();

        let response = await this.callApiWithToken(url, method, data, token)

        if (response === undefined) {
            return Promise.reject('Unable to send request. ');
        }
        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 401) {
                token = await refreshAccessToken();

                response = await this.callApiWithToken(url, method, data, token)

                if (response === undefined) {
                    return Promise.reject('Unable to send request. ');
                }
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.status === 401 || response.status === 403) {
                        await this.checkLogin()
                    }
                    return Promise.reject(response);
                }
            }
            return Promise.reject(response);
        }
    }
    /**
     * GET method implementation
     * @param {any} url
     * @param {any} data
     */
    static async getData(url = '') {

        let token = await getAccessToken();
        let data = {};
        let response = await this.callApiWithToken(url, 'GET', data, token)

        if (response === undefined) {
            return Promise.reject('Unable to send request. ');
        }
        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 401) {
                token = await refreshAccessToken();

                response = await this.callApiWithToken(url, 'GET', data, token)

                if (response === undefined) {
                    return Promise.reject('Unable to send request. ');
                }
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.status === 401 || response.status === 403) {
                        await this.checkLogin()
                    }
                    return Promise.reject(response);
                }
            }
            return Promise.reject(response);
        }
    }

    /**
     * POST method implementation
     * @param {any} url
     * @param {any} data
     */
    static async postData(url = '', data = {}) {

        let token = await getAccessToken();

        let response = await this.callApiWithToken(url, 'POST', data, token)

        if (response === undefined) {
            return Promise.reject('Unable to send request. ');
        }
        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 401) {
                token = await refreshAccessToken();

                response = await this.callApiWithToken(url, 'POST', data, token)

                if (response === undefined) {
                    return Promise.reject('Unable to send request. ');
                }
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.status === 401 || response.status === 403) {
                        await this.checkLogin()
                    }
                    return Promise.reject(response);
                }
            }
            return Promise.reject(response);
        }
    }

    /**
     * PUT method implementation
     * @param {any} url
     * @param {any} data
     */
    static async putData(url = '', data = {}) {
        let token = await getAccessToken();

        let response = await this.callApiWithToken(url, 'PUT', data, token)

        if (response === undefined) {
            return Promise.reject('Unable to send request. ');
        }
        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 401) {
                token = await refreshAccessToken();

                response = await this.callApiWithToken(url, 'PUT', data, token)

                if (response === undefined) {
                    return Promise.reject('Unable to send request. ');
                }
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.status === 401 || response.status === 403) {
                        await this.checkLogin()
                    }
                    return Promise.reject(response);
                }
            }
            return Promise.reject(response);
        }
    }

    /**
     * DELETE method implementation
     * @param {any} url
     * @param {any} data
     */
    static async deleteData(url = '', data = {}) {
        let token = await getAccessToken();

        let response = await this.callApiWithToken(url, 'DELETE', data, token)

        if (response === undefined) {
            return Promise.reject('Unable to send request. ');
        }
        if (response.ok) {
            return response.json();
        } else {
            if (response.status === 401) {
                token = await refreshAccessToken();

                response = await this.callApiWithToken(url, 'DELETE', data, token)

                if (response === undefined) {
                    return Promise.reject('Unable to send request. ');
                }
                if (response.ok) {
                    return response.json();
                }
                else {
                    if (response.status === 401 || response.status === 403) {
                        await this.checkLogin()
                    }
                    return Promise.reject(response);
                }
            }
            return Promise.reject(response);
        }
    }

    static async callApiWithToken(url, type, data, token) {
        if (type == 'GET')
            return this.callApiGetWithToken(url, type, token)
        else
            return fetch(url, {
                method: type, // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                //credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data)
            });
    }

    static async callApiGetWithToken(url, type, token) {
        return fetch(url, {
            method: type, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
    }

    static async checkLogin() {
        const isLoggedIn = await loggedIn();
        const currentUser = await getUser();
        if (currentUser === null || isLoggedIn === false) {
            await signinRedirect();
        }
    }
}
