// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MUI
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material';
import { FormControlLabel } from '@mui/material';

// Formik
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { roles } from '../../utils/roles.js';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';

// Users
import UserService from '../../components/users/user.service';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function UserComponent(props) {
    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ item: {}, tenants: [] });
    const [tenantOptions, setTenantOptions] = React.useState([]);
    const [checkedTenantOptions, setCheckedTenantOptions] = useState([]);
    const [checkedTenantIds, setCheckedTenantIds] = useState([]);
    const [sites, setSites] = useState([]);
    const [checkedSiteBySubject, setCheckedSiteBySubject] = useState([]);
    const [checkedSiteOptions, setCheckedSiteOptions] = useState([]);
    const [checkedSiteIds, setCheckedSiteIds] = useState([]);
    const [selectSiteWithReference, setSelectSiteWithReference] = useState([]);
    const [twoFactorEnabled, setTwoFactorEnabled] = useState([]);
    const [role, setRole] = useState([]);
    const [originalCheckedSites, setOriginalCheckedSites] = useState([]);

    const user = useSelector(state => state.auth.user);

    const handleOpen = () => {
        setState({ item: props.item });
        getCheckedSitesBySubject(props.item.Subject);
        getCheckedCustomersBySubject(props.item.Subject);
        setTenantOptions(props.customers ? props.customers : []);
        setSites(props.sites ? props.sites.items : [])
        setOpen(true);
        setTwoFactorEnabled(props.item.TwoFactorEnabled);
        setRole(props.item.Role);
    };
    async function getCheckedCustomersBySubject(subject) { 
        let selectedCustomers = await UserService.getCustomersBySubject(subject);
        let tenantIds = selectedCustomers.items.map(a => a.TenantId);
        setCheckedTenantOptions(selectedCustomers.items.map(a => a.Tenant));
        setCheckedTenantIds(tenantIds);
        getSiteByTenantId(tenantIds);
    }

    async function getCheckedSitesBySubject(subject) {
        let selectedCheckedCustomers = await UserService.getCheckedSitesByTenantIds(subject);
        setCheckedSiteBySubject(selectedCheckedCustomers.items);
        setCheckedSiteOptions(selectedCheckedCustomers.items);
        setOriginalCheckedSites(selectedCheckedCustomers.items);
        setCheckedSiteIds(selectedCheckedCustomers.items.map(a => a.Value));
    }
    async function getSiteByTenantId(tenantIds) {
        const tenantIdsStr = tenantIds.map(id => id.toString());
        let filteredSites = props.sites.filter(site => tenantIdsStr.includes(site.Reference));
        setSites(filteredSites);
    }
    async function getCheckedSitesByTenantIds(tenantIds) {
        let sites = checkedSiteBySubject.filter(a => tenantIds.includes(a.Reference));
        setCheckedSiteOptions(sites);
        setCheckedSiteIds(sites.map(a => a.Value));
        let filteredSites = sites.filter(item => tenantIds.includes(item.Reference));
        tenantIds.forEach(id => {
            if (!filteredSites.some(site => site.Reference === id)) {
                filteredSites.push({ Text: '', Value: '', Reference: id });
            }
        });
        let sitesData = filteredSites;
        let siteIds = filteredSites.map(site => site.Value);
        getSiteChangeData(siteIds, sitesData, tenantIds)
    }

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    const handleClose = () => {
        setOpen(false);
    };

    const getSiteChangeData = async (siteIds, sites, tenantIds) => {
        setCheckedSiteIds(siteIds);
        const selectedSites = sites.filter(site => siteIds.includes(site.Value));
        const tenants = tenantIds ?? checkedTenantIds.map(id => id.toString());

        const tenantAssignments = [];
        selectedSites.forEach(site => {
            const tenantId = site.Reference;
            const siteId = site.Value;
            let tenantAssignment = tenantAssignments.find(ta => ta.TenantId === tenantId);
            if (!tenantAssignment) {
                tenantAssignment = {
                    TenantId: tenantId,
                    TenantSiteAssignments: []
                };
                tenantAssignments.push(tenantAssignment);
            }
            if (siteId != '') {

                tenantAssignment.TenantSiteAssignments.push({
                    SiteId: siteId,
                    TenantId: tenantId
                });
            }
        });

        tenants.forEach(tenantId => {
            let tenantAssignment = tenantAssignments.find(ta => ta.TenantId === tenantId);
            if (!tenantAssignment) {
                tenantAssignment = {
                    TenantId: tenantId,
                    TenantSiteAssignments: []
                };
                tenantAssignments.push(tenantAssignment);
            }
        });
        setSelectSiteWithReference(tenantAssignments);
    };

    const handleSiteChange = (siteIds, sites) => {
        getSiteChangeData(siteIds, sites, null);
    };
    const onTwoFactorEnabledChange = (e) => {
        setTwoFactorEnabled(e.target.checked);
        getSiteChangeData(checkedSiteIds, sites);
    }

    return (
        <div>
            <IconButton onClick={() => handleOpen()} variant='contained' color='primary' data-testid='user-edit-button' >
                {props.buttonIcon}
            </IconButton>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            id: state.item?.Id,
                            subject: state.item?.Subject,
                            role: role,
                            email: state.item?.Email,
                            tenantsSelected: checkedTenantOptions,
                            tenantId: checkedTenantIds,
                            twoFactorEnabled: twoFactorEnabled,
                            sitesSelected: checkedSiteOptions,
                            siteId: checkedSiteIds
                        }}
                        validationSchema={Yup.object().shape({
                            role: Yup.string().required(),
                            email: Yup.string().required(),
                            tenantsSelected: Yup.array()
                                .of(
                                    Yup.object().shape({
                                        Text: Yup.string(),
                                        Value: Yup.string(),
                                    })
                                )
                                .min(1, 'Select Atlest 1 Tenants Required')
                                .required('Required'),
                            sitesSelected: Yup.array()
                                .test(
                                    function () {
                                        if (user?.profile.nvrclient_access !== roles.Administrator) {
                                            return true;
                                        }
                                        if (selectSiteWithReference.length > 0 && user?.profile.nvrclient_access === roles.Administrator) {
                                            const tenantWithoutSites = selectSiteWithReference.some(tenant => {
                                                const correspondingSites = originalCheckedSites.filter(site => site.Reference === tenant.TenantId);
                                                return correspondingSites.length > 0 && tenant.TenantSiteAssignments.length === 0;
                                            });
                                            if (tenantWithoutSites) {
                                                return false;
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                    }
                                ).required('Each tenant must have at least one site.'),
                        })}
                        onSubmit={async (values) => {
                            let reload = false;
                            setState({ item: state.item, tenants: (state.tenants ? state.tenants : []) });

                            if (values.id === null || values.id === undefined) {
                                let updated = await UserService.insertUser(values);
                                if (updated === false) {
                                    props.snackbarShowMessage('The user could not be added. ', 'warning', 4000);
                                    return;
                                }
                                let idupdated = await UserService.insertUserIdentity(values);
                                if (idupdated === false) {
                                    props.snackbarShowMessage('The user identity could not be added. ', 'warning', 4000);
                                    return;
                                }
                                let activated = await UserService.activateUser(values);
                                if (activated === false) {
                                    props.snackbarShowMessage('The user could not be activated. ', 'warning', 4000);
                                    return;
                                }
                                else {
                                    reload = true;
                                    props.snackbarShowMessage('The user has been activated. ', 'success', 4000);
                                }
                            }
                            else {
                                let updated = await UserService.updateUser(values, selectSiteWithReference);
                                if (updated === true) {
                                    reload = true;
                                    props.snackbarShowMessage('The user has been updated. ', 'success', 4000);
                                }
                                else
                                    props.snackbarShowMessage('The user could not be updated. ', 'warning', 4000);

                                await sleep(2000);
                            }
                            setState({ item: state.item, tenants: (state.tenants ? state.tenants : []) });

                            if (reload === true) props.btnFunction();
                            handleClose();
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            isSubmitting
                        }) => (
                            <Form id='user_form'>
                                <AppDialogTitle classes={classes} onClose={handleClose}>{state.item == null ? <span className={'float-left'}>Add User</span> : <span className={'float-left'}>Edit User: {state.item.Email}</span>}</AppDialogTitle>
                                <AppDialogContent dividers>
                                    <div>
                                        <FormControl variant='outlined' className={classes.formControl} fullWidth>
                                            <TextField labelId={'user-select-label'} disabled={true} defaultValue={(props.item?.Email ?? roles.User)} />
                                        </FormControl>
                                        <p ></p>
                                        <FormControl variant='outlined' className={classes.formControl} fullWidth>
                                            <InputLabel id='role-select-label'>Role</InputLabel>
                                            <Select
                                                id='role'
                                                {...props}
                                                name='role'
                                                fullWidth
                                                disabled={((values.Role === roles.User && user?.profile.nvrclient_access === roles.User) || (values.Role === roles.Administrator && user?.profile.nvrclient_access !== roles.WCCTV) || values.Role === roles.WCCTV)}
                                                labelId='role-select-label'
                                                error={touched.role && Boolean(errors.role)}
                                                value={values.role ? values.role : ''}
                                                onChange={(e) => {
                                                    let role = e.target.value;
                                                    setFieldValue('role', role);
                                                    setRole(role);
                                                    getSiteChangeData(checkedSiteIds, sites)
                                                }}
                                                label='Role'
                                            >
                                                <MenuItem value=''>None selected...</MenuItem>
                                                {(user?.profile.nvrclient_access === roles.WCCTV) && (
                                                    <MenuItem value={roles.WCCTV}>{roles.WCCTV}</MenuItem>
                                                )}
                                                {(user?.profile.nvrclient_access === roles.WCCTV || user?.profile.nvrclient_access === roles.Administrator) && (
                                                    <MenuItem value={roles.Administrator}>{roles.Administrator}</MenuItem>
                                                )}
                                                <MenuItem value={roles.User}>{roles.User}</MenuItem>
                                                {(user?.profile.nvrclient_access === roles.WCCTV) && (
                                                    <MenuItem value={roles.Production}>{roles.Production}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <p ></p>

                                    <Autocomplete
                                        name="tenantOptions"
                                        multiple
                                        data-testid='user-edit-customer-dropdown'
                                        options={tenantOptions ? tenantOptions : []}
                                        disableCloseOnSelect
                                        value={values.tenantsSelected}
                                        getOptionLabel={(option) => option.Text ?? ''}
                                        isOptionEqualToValue={(option, value) => option.Value === value.Value}
                                        onChange={(event, value) => {
                                            const selectedTenantIds = value.map(v => v.Value);
                                            setFieldValue('tenantsSelected', value);
                                            setFieldValue('tenantId', value.map(v => v.Value));
                                            setCheckedTenantOptions(value);
                                            getSiteByTenantId(selectedTenantIds);
                                            setCheckedTenantIds(selectedTenantIds);
                                            getCheckedSitesByTenantIds(selectedTenantIds);
                                        }}
                                        fullWidth
                                        style={{ marginTop: '1rem' }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected} />
                                                {option.DisplayText}
                                            </li>
                                        )}
                                        renderInput={(params) => (<TextField {...params} error={touched.tenantsSelected && Boolean(errors.tenantsSelected)} name="Customers" label="Customers" variant='outlined' placeholder="Customers" />)}
                                    />
                                    <Autocomplete
                                        name="siteOptions"
                                        multiple
                                        options={sites ? sites : []}
                                        disableCloseOnSelect
                                        value={values.sitesSelected}
                                        getOptionLabel={(option) => option.Text ?? ''}
                                        isOptionEqualToValue={(option, value) => option.Value === value.Value}
                                        onChange={(event, value) => {
                                            setFieldValue('sitesSelected', value ? value : []);
                                            let selectedSitesIds = [];
                                            for (const selectedValue of value) {
                                                selectedSitesIds.push(selectedValue.Value);
                                            }
                                            setCheckedSiteOptions(value);
                                            handleSiteChange(selectedSitesIds, sites)
                                        }}
                                        fullWidth
                                        style={{ marginTop: '1rem' }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    checked={selected} />
                                                {option.Text}
                                            </li>
                                        )}
                                        renderInput={(params) => (<TextField {...params} error={touched.sitesSelected && Boolean(errors.sitesSelected)} name="Sites" label="Sites" variant='outlined' placeholder="Sites" data-testid='user-edit-site-dropdown' />)}
                                    />

                                    <FormControlLabel control={<Checkbox checked={values.twoFactorEnabled} name="twoFactorEnabled" onChange={(e) => (setFieldValue('twoFactorEnabled', e.target.checked), onTwoFactorEnabledChange(e))} disabled={props.item.ExternalProvider} />} label="Two Factor Enabled" />
                                </AppDialogContent>
                                <AppDialogActions>
                                    <Button
                                        type='submit'
                                        color='primary'
                                        variant='contained'
                                        data-testid='user-save-button'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'Save'}
                                    </Button>
                                </AppDialogActions>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Dialog>
        </div>
    );
}
export default withSnackbar(UserComponent);


UserComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    item: PropTypes.object,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func,
    customers: PropTypes.object,
    sites: PropTypes.object,
};