// Services
import Http from '../../services/http';

export default class SiteService {

    static async updateSite(row) {
        let data = {
            Id: row.id,
            tenantId: row.tenantId,
            SiteName: row.siteName,
            WebSocketAddress: row.webSocketAddress
        };
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/sites/update', data).catch(error => console.error(error));
        
    }

    static async insertSite(row) {
        let data = {
            siteName: row.siteName,
            tenantId: row.tenantId
        };
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/sites', data).catch(error => console.error(error));
        
    }
}