import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { CameraCard } from '../operations/carmeracard.component';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    detailsContainer: {
        padding: '10px',
        borderRadius: '3px',
        margin: '5px 0px 5px 0px',
    },
    black: {
        color: '#000',
    },
    red: {
        color: '#ff0000',
    },
    infoLabel: {
        fontWeight: '600 !important'
    }
}));

export default function TransmitterDetails ({ transmitter }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box display="flex" alignItems="center">
                <Typography variant="body2" color="text.secondary" className={classes.infoLabel} gutterBottom>
                    Transmitter Name:&nbsp;&nbsp;
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {transmitter.Name}
                </Typography>
            </Box>
            <Box className={classes.detailsContainer}>
                <Grid container spacing={3} >
                    {transmitter.Cameras.map((camera, index) => (
                        <CameraCard key={index} camera={camera} />
                    ))}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

TransmitterDetails.propTypes = {
    transmitter: PropTypes.object.isRequired,
};

