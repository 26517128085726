// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Slide from '@mui/material/Slide';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

// Components
import useAppStyles from '../../ui/atoms/AppDialogStyles';
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import AppDialogActions from '../../ui/atoms/AppDialogActions';
import AppDialogContent from '../../ui/atoms/AppDialogContent';
import AppDialogTitle from '../../ui/molecules/AppDialogTitle';

// Services
import TransmitterFirmwareService from '../../components/transmitterfirmware/transmitterfirmware.service';


// Formik
import { Formik } from 'formik';

// Yup
let yup = require('yup');

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

function TransmitterFirmwareComponent(props) {

    const classes = useAppStyles();
    const [open, setOpen] = React.useState(false);
    const [state, setState] = useState({ item: {}, sites: [] });
    const [transmitterVersionOptions, setTransmitterVersionOptions] = React.useState([]);

    const handleClickOpen = () => {
        populateTransmitterVersions();
        setOpen(true);
    };

    async function populateTransmitterVersions() {
        let transmitterVersions = {};
        try {
            transmitterVersions = await TransmitterFirmwareService.getTransmitterVersions();
            setTransmitterVersionOptions(transmitterVersions.items)
        } catch (err) {
            // props.snackbarShowMessage('The sensor data could not be loaded. ', 'error', 4000);
            return;
        }
    }
    const handleClose = () => {
        setOpen(false);
    };

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    useEffect(() => {
        setState({ item: props.item });

    }, []);

    let addTransmitterFrimwareSchema = yup.object().shape({
        version: yup.string()
            .required('Select a version')
    });

    return (
        <div>
            <span className={classes.link} onClick={() => handleClickOpen()} color='primary' onKeyDown={() => handleClickOpen()} >
                {props.buttonIcon}
            </span>

            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Formik
                    initialValues={{
                        version: '',
                        transmitterId: state.item.Id
                    }}
                    validationSchema={addTransmitterFrimwareSchema}
                    onSubmit={async (values) => {
                        let reload = false;
                        try {
                            let added = await TransmitterFirmwareService.insertTransmitterFirmware(values);
                            if (added === true) {
                                //props.snackbarShowMessage(`Firmware update sent to ${state.item.TransmitterName}.`, 'success', 4000);
                                reload = true;
                            }
                            else
                                props.snackbarShowMessage('There was an issue adding the transmitterFirmware. ', 'warning', 4000);
                        } catch (err) {
                            props.snackbarShowMessage('There was an issue updating the transmitterFirmware. ', 'danger', 4000);
                        }

                        setState({ item: state.item });
                        await sleep(500);
                        if (reload === true) props.btnFunction();
                        handleClose();
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting
                    }) => (
                        <form id='firmwarerequest_form' onSubmit={handleSubmit}>
                            <AppDialogTitle classes={classes} onClose={handleClose}>{<span className={'float-left'}>Firmware Upgrade for Transmitter {state.item?.TransmitterName}</span>}</AppDialogTitle>
                            <AppDialogContent dividers>
                                <FormControl variant='outlined' className={classes.formControl} fullWidth>
                                    <InputLabel id='transmitterVersions'>Versions</InputLabel>
                                    <Select
                                        {...props}
                                        labelId="transmitterVersions"
                                        label="Versions"
                                        options={transmitterVersionOptions}
                                        value={values.version ? values.version : ''}
                                        fullWidth
                                        error={touched.version && Boolean(errors.version)}
                                        onChange={(event) => {
                                            const selectedVersion = event.target.value;
                                            setFieldValue('version', selectedVersion);
                                        }}
                                    >
                                        {transmitterVersionOptions
                                            .sort((a, b) => a.Version.localeCompare(b.Version))
                                            .map((row, index) => (
                                                <MenuItem key={index} value={row.Version}>
                                                    {row.DisplayText}
                                                </MenuItem>
                                            ))}

                                    </Select>


                                </FormControl>

                            </AppDialogContent>
                            <AppDialogActions>
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? <CircularProgress color='secondary' size={30} /> : 'UPGRADE'}
                                </Button>
                            </AppDialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </div >
    );
}

export default withSnackbar(TransmitterFirmwareComponent);

TransmitterFirmwareComponent.propTypes = {
    snackbarShowMessage: PropTypes.func,
    item: PropTypes.object,
    buttonIcon: PropTypes.object,
    btnFunction: PropTypes.func
};