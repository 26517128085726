// Services
import Http from '../../services/http';
import { getLocalDateTime } from '../../utils/dateFormatter';

export default class FootageService {

    static async getVideoSource(id) {
        const url = process.env.REACT_APP_NVR_API + 'api/video/' + id;
        const data = await Http.getData(url);
        return data;
    }

    static async getSource(path) {
        const url = process.env.REACT_APP_NVR_API + 'api/media/' + path;
        const data = await Http.getData(url);
        return data;
    }
    static async getFootage(page, size, order, orderBy, text, start, end, transmitter, notification) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;
        const logs = await Http.getData(process.env.REACT_APP_NVR_API + 'api/footage?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy ? ('&orderBy=' + orderBy) : '') +
            (transmitter ? ('&transmitter=' + transmitter) : '') +
            (notification ? ('&notification=' + notification) : '') +
            (start ? ('&start=' + start) : '') +
            (end ? ('&end=' + end) : '') +
            (text ? ('&text=' + text) : '')).catch(error => console.error(error))
        return logs;
    }
    static async getFilteredFootage(page, size, order, orderBy, text, transmitter, notification, filterData) {
        let data = {
            items: filterData
        }

        if (page < 1) page = 1;
        if (size < 1) size = 10;

        const logs = await Http.postData(process.env.REACT_APP_NVR_API + 'api/footage/filtered?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy ? ('&orderBy=' + orderBy) : '') +
            (transmitter ? ('&transmitter=' + transmitter) : '') +
            (notification ? ('&notification=' + notification) : '') +
            (text ? ('&text=' + text) : ''), data).catch(error => console.error(error))
        return logs;
    }
    static async getSites() {
        const sites = await Http.getData(process.env.REACT_APP_NVR_API + 'api/footage/sites').catch(error => console.error(error));
        return sites;
    }

    static async getExportFootages(order, orderBy, text, transmitter, notification, filterData) {
        let data = {
            items: filterData
        }
        const logs = await Http.postData(process.env.REACT_APP_NVR_API + 'api/footage/export?order=' + order +
            (orderBy ? ('&orderBy=' + orderBy) : '') +
            (transmitter ? ('&transmitter=' + transmitter) : '') +
            (notification ? ('&notification=' + notification) : '') +
            (text ? ('&text=' + text) : ''), data).catch(error => console.error(error))
        const logsArrayObject = JSON.parse(logs.items);
        const formattedLogs = logsArrayObject.map((log) => {
            const footageStartDate = log['Footage Start'];
            const footageEndDate = log['Footage End'];
            log['Footage Start'] = getLocalDateTime(footageStartDate);
            log['Footage End'] = getLocalDateTime(footageEndDate);
            return log;
        });

        const updatedLogsString = JSON.stringify(formattedLogs);
        return JSON.parse(updatedLogsString);
    }

    static async updateImportance(id, important) {
        const data = { Id: id, Important: important }
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/footage/updateimportance', data).catch(error => console.error(error));
    }

    static async updateAnprImportance(id, important) {
        const data = { Id: id, Important: important }
        return await Http.putData(process.env.REACT_APP_NVR_API + 'api/footage/anpr/updateimportance', data).catch(error => console.error(error));
    }

    static async deleteFootage(id) {
        let data = { id }

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/footage/delete', data).catch(error => console.error(error));
    }

    static async deleteAnprFootage(id) {
        let data = { id }

        return await Http.deleteData(process.env.REACT_APP_NVR_API + 'api/footage/anpr/delete', data).catch(error => console.error(error));
    }
}
