// Services
import Http from '../../services/http';
import { getLocalDateTime, getLocalDateFormatUnix } from '../../utils/dateFormatter';

export default class LogsService {
    static async getEventTypes() {
        const logs = await Http.getData(process.env.REACT_APP_NVR_API + 'api/notificationlogs/eventTypes')
            .catch(error => console.error(error))
        return logs;
    }

    static async getFilteredLogs(page, size, order, orderBy, text, transmitter, filterData) {
        if (page < 1) page = 1;
        if (size < 1) size = 10;

        let data = {
            items: filterData
        }

        const logs = await Http.postData(process.env.REACT_APP_NVR_API + 'api/filtered/notificationlogs?page=' + page + '&size=' + size + '&order=' + order +
            (orderBy ? ('&orderBy=' + orderBy) : '') +
            (transmitter ? ('&transmitter=' + transmitter) : '') +
            (text ? ('&text=' + text) : ''), data)
            .catch(error => console.error(error))
        return logs;
    }
    static async getExportLogs(order, orderBy, text, transmitter, filterData) {
        let data = {
            items: filterData
        }
        const logs = await Http.postData(process.env.REACT_APP_NVR_API + 'api/export/notificationlogs/all?order=' + order +
            (orderBy ? ('&orderBy=' + orderBy) : '') +
            (transmitter ? ('&transmitter=' + transmitter) : '') +
            (text ? ('&text=' + text) : ''), data)
            .catch(error => console.error(error))

        const logsArrayObject = JSON.parse(logs.items);
        const formattedLogs = logsArrayObject.map((log) => {
            const AuditStartDate = getLocalDateTime(log.AuditStartDate);
            const AuditFinishDate = getLocalDateTime(log.AuditFinishDate);
            const Message = (!AuditStartDate ? log.Message : log.Message + AuditStartDate + ' - ' + AuditFinishDate);
            log.Created = getLocalDateFormatUnix(log.Created);
            delete log.AuditStartDate;
            delete log.AuditFinishDate;
            return {
                ...log,
                Message
            }
        });

        const updatedLogsString = JSON.stringify(formattedLogs);
        return JSON.parse(updatedLogsString);
    }
}
