// React
import React from 'react';
import PropTypes from 'prop-types';

// Mui
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import CookiePolicyComponent from '../policies/cookiepolicy.component';
import PrivacyPolicyComponent from '../policies/privacypolicy.component';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label='basic tabs example'>
                    <Tab label='Privacy Policy' {...a11yProps(0)} />
                    <Tab label='Cookie Policy' {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <PrivacyPolicyComponent />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CookiePolicyComponent />
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Box>
    );
}


const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black',
        boxShadow: 0
    },
    table: {
        minWidth: 750,
    },
}));

/**A table component with a toolbar and edit functions */
function PoliciesComponent() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <BasicTabs></BasicTabs>
            </Paper>
        </div>
    );
}
export default withSnackbar(PoliciesComponent);

