// React
import React from 'react';
import PropTypes from 'prop-types';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

function AreYouSure({ isOpenDialog, closeDialog, titleText, contentText, onYesClick }) {
    return (
        <div>
            <Dialog PaperProps={{ sx: { borderRadius: '9px' } }}
                open={Boolean(isOpenDialog)}
                onClose={() => closeDialog()}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title' data-testid="title"> 
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description' data-testid="content"> 
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button data-testid="yes-button" onClick={() => onYesClick()} autoFocus> 
                        Yes
                    </Button>
                    <Button data-testid="no-button" onClick={() => closeDialog()}>No</Button> 
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default AreYouSure;

AreYouSure.propTypes = {
    isOpenDialog: PropTypes.bool,
    closeDialog: PropTypes.func,
    titleText: PropTypes.string,
    contentText: PropTypes.string,
    onYesClick: PropTypes.func,
};