// React
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// MUI
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Avatar, Box } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';

// Services
import { signoutRedirect, getIdToken } from '../../services/userService';

const boxStyle = {
    display: 'grid',
    textAlign: 'left',
    gridAutoFlow: 'row',
    gridTemplateRows: 'repeat(2, 50px)',
    gap: 0
};
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                border: '0',
                p: 1,
                textAlign: 'left',
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}
function AccountPopover() {
    const user = useSelector(state => state.auth.user);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleSignOut() {
        let token = await getIdToken();
        await signoutRedirect(token);
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name, size) {
        try {
            if (name.indexOf(' ') === -1) {
                name = name.substring(0, 1);
            }
            else {
                name = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
            }
        }
        catch {
            name = '';
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                height: size,
                width: size
            },
            children: `${name}`,
        };
    }

    

   
    return (
        <div>

            <IconButton aria-describedby={id} aria-label='show users' color='inherit' variant='contained' onClick={handleClick}>
                <Avatar {...stringAvatar((user?.profile.name), '40px')}></Avatar>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={boxStyle} >
                    <Item sx={{ gridColumn: '1/2', gridRow: '1 / 2' }}>
                        <Avatar  {...stringAvatar((user?.profile.name), '80px')}></Avatar>
                    </Item>
                    <Item sx={{ gridColumn: '3', gridRow: '1' }}>
                        <Typography sx={{ p: 1 }}><strong>{user?.profile.name}</strong></Typography>
                    </Item>
                    <Item sx={{ gridColumn: '3', gridRow: '2' }}>
                        <Typography sx={{ p: 1 }}><small>{user?.profile.email}</small></Typography>
                    </Item>
                    <Item sx={{ gridColumn: '1/4', gridRow: '4' }}>
                        <Typography sx={{ p: 0 }}><small><NewspaperIcon /> &nbsp; <Link to={'policies'}> Policies</Link></small></Typography>
                    </Item>
                </Box>
                <Button fullWidth variant='contained' onClick={handleSignOut} >Log Out</Button>

            </Popover>
        </div >
    );
}
export default AccountPopover;

Item.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};