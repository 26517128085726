// React
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function ProtectedRoute({ component: Component, ...rest }) {
    const user = useSelector(state => state.auth.user)

    return user
        ? (<Route {...rest} component={Component} />)
        : (<Redirect to={'/login'} />)
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
    component: PropTypes.func
};