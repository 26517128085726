

export const dates = {
    today: { value: 1, displayName: 'Today' },
    yesterday: { value: 2, displayName: 'Yesterday' },
    isbefore: { value: 3, displayName: 'Is Before' },
    isafter: { value: 4, displayName: 'Is After' },
    isbetween: { value: 5, displayName: 'Is Between' },
    last7days: { value: 6, displayName: 'Last 7 Days' },
    last14days: { value: 7, displayName: 'Last 14 Days' },
};