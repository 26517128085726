// React
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Services
import { getRole } from '../services/userService';

function PrivateRoute (props) {
    const [isAuthenticated, setIsAuthenticated] = React.useState(true);
    const roles = props.roles;
    
    useEffect(() => {
        async function constructor() {
            const role = await getRole();
            if (roles) {
                if (roles.indexOf(role) === -1) {
                    setIsAuthenticated(false);
                }
            }
        }
        constructor();
    }, []);

    return (isAuthenticated) ? (
        <Route {...props} />
    ) : (
        <Redirect pathname='/' />
    );
}
export default PrivateRoute;


PrivateRoute.propTypes = {
    roles: PropTypes.array
};