// React
import React from 'react';
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Home() {
    const user = useSelector(state => state.auth.user)

    return (
        (user) ?
            (<Redirect to={'/dashboard'} />)
            :
            (
                <div>
                    Redirecting...
                </div>
            )
    )
}

export default Home;
