// React
import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// MUI
import { useTheme, makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

// Components
import NavigationBar from '../ui/organisms/NavigationBar';

// CSS
import '../../src/custom.css';
import { withSnackbar } from '../ui/molecules/withSnackbar';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessToken } from '../services/userService';
import moment from 'moment-timezone';

function Layout(props) {
    const [notificationLogs, setNotificationLogs] = React.useState({ id: null, message: null, Type: null, transmitterName: null });
    const [messagesArray, setMessagesArray] = React.useState([]);

    function handleClear() {
        setMessagesArray([])
    }
    const theme = useTheme();
    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing,
            textAlign: 'center',
            color: theme.palette.text.secondary,
            boxShadow: 0
        }
    }));
    const classes = useStyles();
    let user = useSelector(state => state.auth.user);

    useEffect(() => {
        loadSignalR();
    }, [user])

    useEffect(() => {
        loadNotificationData();
    }, [notificationLogs])

    async function loadSignalR() {

        const connection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_NVR_API + 'hubs/notifications', { accessTokenFactory: async () => { return await getAccessToken() } })
            .withAutomaticReconnect()
            .build();

        await connection.start();

        connection.on('SendFootageToGroup', (message, id, transmitterName) => {
            props.snackbarShowMessage(message, 'primary', 4000);
            setNotificationLogs({ id: id, message: message, type: 'Footage', transmitterName: transmitterName })
        });
        connection.on('SendGSMtoSingle', (id,message) => {
            props.snackbarShowMessage(message, 'primary', 4000);
            setNotificationLogs({ id: id, message: message, type: 'GSMSent', transmitterName: null })
        });
        connection.on('SendFirmwaretoSingle', (id, message) => {
            props.snackbarShowMessage(message, 'primary', 4000);
            setNotificationLogs({ id: id, message: message, type: 'FirmwareUpdate', transmitterName: null })
        });


        if (user) {
            connection.invoke('JoinGroup', user.profile.sub);
        }

        connection.onreconnected(() => {
            if (user) {
                connection.invoke('JoinGroup', user.profile.sub);
            }
        });
    }

    async function loadNotificationData() {
        if (notificationLogs.message != null) {
            const isDuplicate = messagesArray.some((notification) => notification.id === notificationLogs.id);

            if (!isDuplicate) {
                const notificationsData = {
                    text: notificationLogs.message,
                    id: notificationLogs.id,
                    type: notificationLogs.type,
                    isRead: false,
                    timestamp: moment(),
                    transmitterName: notificationLogs.transmitterName
                };
                setMessagesArray([...messagesArray, notificationsData]);
            }
        }
    }

    return (
        <div>
            <NavigationBar messages={messagesArray} onClearAllNotifications={handleClear} />
            <br />
            <div className="App">
                <div className={classes.root}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                            <div >
                                {props.children}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
export default withSnackbar(Layout);

Layout.propTypes = {
    children: PropTypes.any,
    snackbarShowMessage: PropTypes.func,
};