// Services
import Http from '../../services/http';

export default class AuditsService {

    static async insertAuditData(name, extraData) {
        let data = { Name: name, ExtraData: extraData }
        return await Http.postData(process.env.REACT_APP_NVR_API + 'api/audit', data).catch(error => console.error(error));
    }
}
