// MUI
import { withStyles } from '@mui/styles';
import MuiDialogContent from '@mui/material/DialogContent';

const AppDialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(5)
    }
}))(MuiDialogContent);


export default AppDialogContent;