// Services
import { UserManager, WebStorageStateStore, InMemoryWebStorage } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/authActions'

const config = {
    authority: process.env.REACT_APP_IDP_API.slice(0, -1),
    client_id: process.env.REACT_APP_IDP_CLIENT_ID,
    redirect_uri: window.location.protocol + '//' + window.location.host + '/signin-oidc',
    response_type: 'code',
    scope: 'openid profile nvrapi nvraccess environment email offline_access IdentityServerApi',
    post_logout_redirect_uri: window.location.protocol + '//' + window.location.host + '/signout-oidc',
    silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/silent-oidc',
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() })
};

const userManager = new UserManager(config);

export async function loadUserFromStorage(store) {
    try {
        let user = await userManager.getUser()
        if (!user) {
            return store.dispatch(storeUserError());
        }
        store.dispatch(storeUser(user));
    } catch (e) {
        console.error(`User not found: ${e}`);
        store.dispatch(storeUserError())
    }
}

export function signinRedirect() {
    return userManager.signinRedirect()
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signinSilentCallback() {
    return userManager.signinSilentCallback()
}

export async function signoutRedirect(token) {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirect({ 'id_token_hint': token })
}

export async function getAccessToken() {
    const user = await userManager.getUser();
    return user && user.access_token;
}
        

export async function refreshAccessToken() {
    const user = await userManager.signinSilent();
    return user && user.access_token;
}

export async function getUser() {
    const user = await userManager.getUser();
    return user;
}

export async function getRole() {
    const user = await userManager.getUser();
    return user && user.profile.nvrclient_access;
}

export async function getIdToken() {
    const user = await userManager.getUser();
    return user && user.id_token;
}

export async function getEmail() {
    const user = await userManager.getUser();
    return user && user.profile.email;
}

export async function getName() {
    const user = await userManager.getUser();
    return user && user.profile.name;
}


export async function loggedIn() {
    const user = await userManager.getUser();
    let isLoggedIn = (user !== null && user !== undefined);
    return isLoggedIn;
}


export default userManager