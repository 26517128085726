import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from '@mui/styles';
import SitesComponent from '../operations/sites.component';

const useStyles = makeStyles(() => ({
    headingCell: {
        fontWeight: '600 !important',
        fontSize: '16px !important',
    },
    tableCell: {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    },
    detailRow: {
        backgroundColor: 'rgb(250,250,250)',
    }
}));

export function FilteredSitesComponent ({ rows, open, handleToggleRow, countOfTransmittersWithErrors, countOfCamerasWithErrors, countOfCameras }) {
    const classes = useStyles();
    const [openImageReports, setOpenImageReports] = useState({});

    const handleToggleImageReport = (index) => {
        setOpenImageReports(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    return (
        <Table sx={{ minWidth: 640 }} aria-label="simple table" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell style={{ width: '30px' }} />
                    <TableCell className={classes.headingCell}>Scheduled Execution Time</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((report) => (
                    <React.Fragment key={report.DisplayId}>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell style={{ width: '30px' }}>
                                <IconButton aria-label="expand row" size="small" onClick={() => handleToggleImageReport(report.DisplayId)}>
                                    {openImageReports[report.DisplayId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {new Date(report.ScheduledExecutionDateTime).toLocaleString()}
                            </TableCell>
                        </TableRow>

                        <TableRow className={classes.detailRow}>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                <Collapse in={openImageReports[report.DisplayId]} timeout="auto" unmountOnExit>
                                    <Table sx={{ minWidth: 640 }} aria-label="nested table" stickyHeader>
                                        <TableBody>
                                            {report.Sites.map((site, siteIndex) => (
                                                <SitesComponent
                                                    key={siteIndex}
                                                    rows={[site]}
                                                    open={open}
                                                    handleToggleRow={handleToggleRow}
                                                    countOfTransmittersWithErrors={countOfTransmittersWithErrors}
                                                    countOfCamerasWithErrors={countOfCamerasWithErrors}
                                                    countOfCameras={countOfCameras}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
}

FilteredSitesComponent.propTypes = {
    rows: PropTypes.array.isRequired,
    open: PropTypes.object.isRequired,
    handleToggleRow: PropTypes.func.isRequired,
    countOfTransmittersWithErrors: PropTypes.func.isRequired,
    countOfCamerasWithErrors: PropTypes.func.isRequired,
    countOfCameras: PropTypes.func.isRequired,
};

export default FilteredSitesComponent;
