// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

// Mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Services
import AuditsService from '../audits/audits.service';
import { getLocalDateFormatUnix, getLocalDateTime } from '../../utils/dateFormatter';
import LogsService from './logs.service';
import { auditNames } from '../../utils/auditNames';

// Components
import { withSnackbar } from '../../ui/molecules/withSnackbar';
import EnhancedTableHead from '../../ui/molecules/EnhancedTableHead';
import EnhancedTableToolbar from '../../ui/molecules/EnhancedTableToolbar';
import { stableData, getComparator } from '../../helpers/Sorting';
import { BasicBreadcrumbs } from '../../ui/molecules/BasicBreadcrumbs';
import ExportButton from '../../ui/atoms/ExcelExport';
import FilterComponent from '../../ui/molecules/FilterComponent';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: 10,
        border: '1px solid black'
    },
    marginLeft: {
        marginLeft: 16
    },
    table: {
        minWidth: 750,
    }
}));

/**A table component with a toolbar and edit functions */
function LogsComponent(props) {
    const classes = useStyles();
    /** The page data */
    const [state, setState] = React.useState({ logs: [], loading: false });
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('Created');
    const [page, setPage] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = React.useState('');
    const [applyData, setApplyData] = React.useState([]);
    const [exportData, setExportData] = React.useState([]);
    const [isForceReload, setIsForceReload] = React.useState(false);
    const headRow = [
        { id: 'TransmitterName', numeric: false, disablePadding: false, IsDate: false, Value: 1, filterBy: false, Text: 'TransmitterName', label: 'Full Transmitter Name' },
        { id: 'Site', numeric: false, disablePadding: false, Value: 2, IsDate: false, Text: 'Site', filterBy: false, label: 'Site' },
        { id: 'EventType', numeric: false, disablePadding: false, IsDate: false, Value: 3, Text: 'Event Type', filterBy: true, label: 'Event Type' },
        { id: 'Created', numeric: false, disablePadding: false, IsDate: true, Value: 4, Text: 'Created', filterBy: true, label: 'Created' },
        { id: 'Device', numeric: false, disablePadding: false, IsDate: false, Value: 5, Text: 'Device', filterBy: false, label: 'Device' },
        { id: 'Message', numeric: false, disablePadding: false, IsDate: false, Value: 6, Text: 'Message', filterBy: false, label: 'Message' },
      
    ];

    const [sourceDropdownData, setsourceDropdownData] = React.useState(headRow.filter(data => data.filterBy));

    let transmitterId = 0;
    let transmitterName = '';
    let dashboardState;
    const location = useLocation();
    if (location.state) {
        transmitterId = location.state.transmitterId;
        transmitterName = location.state.transmitterName;
        dashboardState = location.state.dashboardState;
    }

    useEffect(() => {
        async function loadData() {
            await populateLogsData();
            setExportData(applyData)
        }
        loadData();
    }, [search, page, rowsPerPage, order, orderBy, isForceReload, location.state, applyData]);

    useEffect(() => {
        populateAuditData();
        populateEventType();
    }, []);


    async function populateEventType() {
        let eventTypes = await LogsService.getEventTypes();
        const dropdownData = sourceDropdownData.map(data => {
            if (data.id === 'EventType') {
                data.dropdown = eventTypes.items
            }
            return data;
        });
 
        setsourceDropdownData(dropdownData);
    }
    async function populateAuditData() {
        await AuditsService.insertAuditData(auditNames.ViewLogs, transmitterName ? 'Logs for transmitter ' + transmitterName : null);
    }

    async function populateLogsData() {
        setLoading(true);
        let rowData = {};

        try {
                rowData = await LogsService.getFilteredLogs(page + 1, rowsPerPage, order, orderBy, search, transmitterId, applyData);         
        } catch (err) {
            props.snackbarShowMessage('The logs could not be loaded. ', 'error', 4000);
            setTotalPageCount(1);
            setState({ logs: [] });
            setLoading(false);
            return;
        }
        setState({ logs: rowData.items });
        setTotalPageCount(rowData.pageInfo.pageCount);
        setLoading(false);
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDescAsc = isAsc ? 'desc' : 'asc'

        setOrder(orderDescAsc);
        setOrderBy(property);

    };

    async function handleChangePage(e, newPage) {
        setPage(newPage);
    }

    async function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }
    async function loadExportData() {
        return await LogsService.getExportLogs(order, orderBy, search, transmitterId, exportData);
    }
    /**
    * Handles search input changes and updates the search state.
    * @param {string} searchValue - The new search value to be set.
    */
    async function handleSearch(searchValue) {
        setSearch(searchValue);
        if (page === 0)
            setIsForceReload(!isForceReload);
        else
            setPage(0)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.logs.length - page * rowsPerPage);
    return (
        <div>
            <div className={classes.root}>
                {transmitterId > 0 && (
                    <BasicBreadcrumbs page={'Logs Data for ' + transmitterName} backLabel={'Dashboard'} backTo={'/dashboard'} returnState={dashboardState} />
                )}
                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <FilterComponent sourcedropdownText={sourceDropdownData} setApply={setApplyData} setPage={setPage} filterList={applyData} ></FilterComponent>
                    </div>

                <Paper className={classes.paper}>
                    <div className={classes.marginLeft}>
                        <EnhancedTableToolbar
                            searchPlaceholder={'Search logs...'}
                            setSearch={handleSearch}
                        />
                    </div>
                    <hr />
                    <Grid container>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6} >
                            <ExportButton
                                loadExportData={loadExportData}
                                title={'Export logs'}
                                filename={'logs' + JSON.stringify(getLocalDateTime(new Date())) + '.xlsx'}
                                worksheet={'Logs Data'}
                            />

                        </Grid>
                    </Grid>

                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby='tableTitle'
                            size={'small'}
                            aria-label='enhanced table'
                        >
                            <EnhancedTableHead
                                isRowEditable={false}
                                headCells={headRow}
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <span>
                            </span>
                            <TableBody>
                                {loading ? <tr><td><HourglassEmptyIcon /></td></tr> : state.logs.length < 1 ? <TableRow><TableCell colSpan='6'>No logs were found. </TableCell></TableRow> : stableData(state.logs, getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const dateTime = getLocalDateFormatUnix(row.UnixTimestamp);
                                        return (
                                            <TableRow
                                                hover
                                                role='checkbox'
                                                tabIndex={-1}
                                                key={row.Id}
                                            >
                                                <TableCell component='th' id={labelId} scope='row' align='left'>
                                                    {row.UnitName}
                                                </TableCell>
                                                <TableCell align='left'>{row.Location}</TableCell>
                                                <TableCell align='left'>{row.EventType}</TableCell>
                                                <TableCell align='left'>{dateTime}</TableCell>
                                                <TableCell align='left'>{row.DeviceName}</TableCell>
                                                <TableCell align='left'>{row.Message}{(row.AuditStartDate != null) ? getLocalDateTime(row.AuditStartDate) + ' - ' + getLocalDateTime(row.AuditFinishDate) : ''}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 0 }}>
                                        <TableCell style={{ border: 'none' }} colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={totalPageCount * rowsPerPage}
                        disabled={false}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, params) => {
                            handleChangePage(event, params);
                        }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

            </div >
        </div>
    );
}
export default withSnackbar(LogsComponent);


LogsComponent.propTypes = {
    snackbarShowMessage: PropTypes.func
};