//React
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Services
import { signinRedirect } from '../services/userService'

function Login() {
    const user = useSelector(state => state.auth.user)

    useEffect(() => {
        if (!user) {
            signinRedirect();
        }
    }, [])


    return (
        (user) ?
            (<Redirect to={'/dashboard'} />)
            :
            (
                <div>
                    Redirecting...
                </div>
            )
    )
}

export default Login