// React
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Components
import PrivateRoute from '../src/routes/PrivateRoute';
import ProtectedRoute from '../src/routes/ProtectedRoute';
import CategoryComponent from './components/categories/categories.component';
import DashboardComponent from './components/dashboard/dashboard.component';
import LiveViewComponent from './components/dashboard/liveview.component';
import FootageComponent from './components/footage/footage.component';
import Home from './components/Home';
import Layout from './components/Layout';
import PoliciesComponent from './components/policies/policies.component';
import LogsComponent from './components/logs/logs.component';
import SensorHistoryComponent from './components/sensors/sensorhistory.component';
import SitesComponent from './components/sites/sites.component';
import TenantsComponent from './components/tenants/tenants.component';
import TransmittersComponent from './components/transmitters/transmitters.component';
import UsersComponent from './components/users/users.component';
import MultiViewComponent from './components/multiview/multiviews.component';
import SensorComponent from './components/sensor/sensors.component';
import OperationsComponent from './components/operations/operations.component';
import './custom.css';
import Login from './pages/login';

// Utils
import SigninOidc from './pages/signin-oidc';
import SignoutOidc from './pages/signout-oidc';
import SilentOidc from './pages/silent-oidc';
import userManager, { loadUserFromStorage } from './services/userService';
import store from './store';
import AuthProvider from './utils/authProvider';
import { roles } from './utils/roles.js';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

function App() {
    useEffect(() => {

        loadUserFromStorage(store)
    }, [])

    const theme = createTheme({
        palette: {
            primary: {
                //BLUE
                main: '#002D73 !important',
            },
            secondary: {
                //RED
                main: '#E63027 !important',
            },

        },
    });
    return (
        <Provider store={store}>
            <AuthProvider userManager={userManager} store={store}>
                <ThemeProvider theme={theme}>
                    <Layout>
                        <Switch>
                            <Route path='/signin-oidc' component={SigninOidc} />
                            <Route path='/signout-oidc' component={SignoutOidc} />
                            <Route path='/silent-oidc' component={SilentOidc} />
                            <Route path='/login' component={Login} />
                            <ProtectedRoute exact path='/' component={Home} />
                            <PrivateRoute path='/liveview' roles={[roles.User, roles.Administrator, roles.WCCTV]} component={LiveViewComponent} />
                            <PrivateRoute path='/dashboard' roles={[roles.User, roles.Administrator, roles.WCCTV, roles.Production]} component={DashboardComponent} />
                            <PrivateRoute exact path='/logs' roles={[roles.WCCTV]} component={LogsComponent} />
                            <PrivateRoute path='/logs/:id' roles={[roles.User, roles.Administrator, roles.WCCTV]} component={LogsComponent} />
                            <PrivateRoute exact path='/footage' roles={[roles.WCCTV]} component={FootageComponent} />
                            <PrivateRoute path='/footage/:id' roles={[roles.User, roles.Administrator, roles.WCCTV]} component={FootageComponent} />
                            <PrivateRoute path='/multiview' roles={[roles.User, roles.Administrator, roles.WCCTV]} component={MultiViewComponent} />
                            <PrivateRoute path='/policies' roles={[roles.User, roles.Administrator, roles.WCCTV]} component={PoliciesComponent} />
                            <PrivateRoute exact path='/sensors' roles={[roles.WCCTV]} component={SensorHistoryComponent} />
                            <PrivateRoute path='/customers' roles={[roles.WCCTV]} component={TenantsComponent} />
                            <PrivateRoute path='/categories' roles={[roles.WCCTV]} component={CategoryComponent} />
                            <PrivateRoute path='/sensor' roles={[roles.WCCTV]} component={SensorComponent} />
                            <PrivateRoute path='/transmitters' roles={[roles.WCCTV, roles.Production]} component={TransmittersComponent} />
                            <PrivateRoute path='/sites' roles={[roles.WCCTV]} component={SitesComponent} />
                            <PrivateRoute path='/users' roles={[roles.Administrator, roles.WCCTV]} component={UsersComponent} />
                            <PrivateRoute path='/operations' roles={[roles.WCCTV]} component={OperationsComponent} />
                        </Switch>
                    </Layout>
                </ThemeProvider>
            </AuthProvider>
        </Provider>
    );
}

export default App;
