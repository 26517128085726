import moment from 'moment-timezone';
import { dates } from './dates.js';

const guessedTimezone = moment.tz.guess();
const localDateFormat = (guessedTimezone.indexOf('Europe') > -1) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
const timeformat = 'HH:mm:ss';

export function getLocalDateFormat() {
    return localDateFormat;
}

export function getLocalDateUnix(date) {
    const dbDate = new Date(date);
    return new Date(dbDate.getTime());
}

//takes long as parameter
export function getLocalDateFormatUnix(date) {
    if (date == null) return false;
    return moment.unix(date).tz(guessedTimezone).format(`${localDateFormat} ${timeformat}`);

}

//takes date with T as parameter
export function getLocalDateTime(date) {

    if (date == null) return false;
    return moment.utc(date).tz(guessedTimezone).format(`${localDateFormat} ${timeformat}`);
}

//takes date with T as parameter
export function getLocalDate(date) {
    if (date == null) return false;
    return moment.utc(date).tz(guessedTimezone).format(localDateFormat);
}

//takes date momemt as parameter
export function getUtcStartOfDate(date) {
    if (date == null) return false;
    return moment(date).startOf('day').utc().format();
}

//takes date momemt as parameter
export function getUtcEndOfDate(date) {
    if (date == null) return false;
    return moment(date).endOf('day').utc().format();
}

//returns min date
export function getMinDate() {
    return moment.utc('0001-01-01').format();
}


export function getUtcStartOfDayByDayType(days) {
    let daysToSubtract;

    switch (days) {
        case dates.today.displayName:
            daysToSubtract = 0;
            break;
        case dates.yesterday.displayName:
            daysToSubtract = 1;
            break;
        case dates.last7days.displayName:
            daysToSubtract = 7;
            break;
        case dates.last14days.displayName:
            daysToSubtract = 14;
            break;
        default:

            throw new Error('Invalid value for days: ${ days }');
    }

    return moment().subtract(daysToSubtract, 'days').startOf('day').utc().format();
}

export function getUtcEndOfDayByDayType(days) {
    let momentObject;

    switch (days) {
        case dates.today.displayName:
        case dates.last7days.displayName:
        case dates.last14days.displayName:
            momentObject = moment().endOf('day');
            break;
        case dates.yesterday.displayName:
            momentObject = moment().subtract(1, 'days').endOf('day');
            break;
        default:
            throw new Error('Invalid value for days: ${days}');
    }

    return momentObject.utc().format();
}

