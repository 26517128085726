// React
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function SignoutOidc() {

    const history = useHistory()
    useEffect(() => {
        async function signoutAsync() {
            history.push('/')
        }
        signoutAsync()
    }, [history])

    return (
        <div>
            Redirecting...
        </div>
    )
}

export default SignoutOidc;